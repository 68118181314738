import * as React from 'react';
import { IconProps } from './IconTypes';

export const TraditionellesBestattungspaketIcon = (props: IconProps): JSX.Element => {
    return <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                x="0px" y="0px" viewBox="0 0 205 70" {...props}>
        <g
            transform="translate(119.496789, 32.133832) rotate(-9.000000) translate(-119.496789, -32.133832) translate(98.996789, 11.633832)">
            <path fill="none" d={`M23.3,17.9l5.4-5.4c-3.1-2-7.1-1.6-9.7,1l-2.3,2.3c1.4,2.5,1,5.6-1,7.6s-5.1,2.4-7.6,1l-2.3,2.3
		c-2.6,2.6-3,6.7-1,9.7l7.2-7.2l2.2,2.2l-7.2,7.2c3.1,2,7.1,1.6,9.7-1l2.3-2.3c-1.4-2.5-1-5.6,1-7.6s5.1-2.4,7.6-1l2.3-2.3l0,0
		c2.6-2.6,3-6.6,1-9.7l-5.4,5.4L23.3,17.9z`}/>
            <path strokeWidth="0.5826" strokeLinejoin="round" d={`M38.5,9l2.3-2.3l-4.1-4.1l-2.3,2.3l0.9,0.9l-1.1,1.1L32,9.2l-1,1C26.6,7,20.5,7.4,16.6,11.3
		l-4.4,4.4l1.1,1.1c0.9,1.2,0.8,3-0.3,4c-1.1,1.1-2.8,1.2-4,0.3l-1.1-1.1l-4.4,4.4c-4.3,4.3-4.3,11.2,0,15.5s11.2,4.3,15.5,0
		l4.4-4.4l-1.1-1.1c-1-0.7-1.4-1.9-1.2-3.1c0.3-1.2,1.2-2.1,2.4-2.4c1.2-0.3,2.4,0.2,3.1,1.2l1.1,1.1l4.4-4.4
		c3.8-3.8,4.3-9.9,1.1-14.3l1.1-1.1l2.3-2.3l1.1-1.1L38.5,9z M29.9,24.5l-2.3,2.3c-2.5-1.4-5.6-1-7.6,1s-2.4,5.1-1,7.6l-2.3,2.3
		c-2.6,2.6-6.7,3-9.7,1l3.5-3.3l-2.2-2.2l-3.5,3.3c-2-3.1-1.6-7.1,1-9.7L8,24.5c2.5,1.4,5.6,1,7.6-1s2.4-5.1,1-7.6l2.3-2.3
		c2.6-2.6,6.6-3,9.7-1l-3.2,3.2l2.3,2.3l3.2-3.2C32.9,17.9,32.5,21.9,29.9,24.5z`}/>
        </g>
        <g transform="translate(45.507949, 25.551271)">
            <path strokeWidth="0.8739" d={`M16.8,3c2.6,1.2,4.5,3.4,5.5,6c0.8-0.2,1.7-0.3,2.6-0.3c0.6,0,1.2,0,1.8,0.1
		c1.5,0.2,2.6,1.4,2.8,2.8c0,0.3,0.1,0.7,0.1,1c0,5.7-4.9,10.4-10.9,10.4c-0.6,0-1.2,0-1.8-0.1l-0.2,0l-0.1,18.8l-2.7,0l0-4.3
		c-0.2,0-0.4,0-0.5,0c-4.9,0-9-3.8-9-8.6c0-0.3,0-0.6,0-0.8c0.1-0.9,0.8-1.7,1.8-1.8c0.5-0.1,1-0.1,1.4-0.1c2.5,0,4.7,0.9,6.3,2.5
		l0-5.6c-0.1,0-0.1,0-0.2,0c-0.6,0.1-1.2,0.1-1.8,0.1c-6,0-10.9-4.7-10.9-10.4c0-0.3,0-0.7,0.1-1c0.1-1.4,1.3-2.6,2.8-2.8
		c0.6-0.1,1.2-0.1,1.8-0.1c0.9,0,1.9,0.1,2.7,0.3c0.9-2.6,2.9-4.8,5.5-6C14.8,2.6,15.9,2.6,16.8,3z M7.6,28.7c-0.2,0-0.4,0-0.5,0
		c0,0,0,0.1,0,0.1c0,3.3,2.8,5.9,6.2,5.9c0.2,0,0.4,0,0.5,0c0,0,0-0.1,0-0.1C13.8,31.3,11,28.7,7.6,28.7z M24.9,11.1
		c-0.6,0-1.3,0.1-1.9,0.2l0,0l-1.9,0.9c-1.6,0.7-2.8,1.9-3.5,3.4l-1.1,2.3c0,0.1,0,0.1,0,0.2c0,0.3,0.1,0.7,0.1,1.1c0,0.3,0,0.6,0,1
		c0.1,0.3,0.4,0.5,0.7,0.5c0.4,0.1,0.9,0.1,1.4,0.1c4.7,0,8.4-3.6,8.4-8c0-0.3,0-0.5,0-0.8c0-0.4-0.3-0.7-0.7-0.7
		C25.8,11.1,25.3,11.1,24.9,11.1z M5.6,11.1c-0.5,0-0.9,0-1.4,0.1c-0.4,0.1-0.7,0.4-0.7,0.7c0,0.3,0,0.5,0,0.8c0,4.4,3.8,8,8.4,8
		c0.5,0,0.9,0,1.4-0.1c0.3,0,0.6-0.3,0.7-0.5c0-0.3,0-0.6,0-1c0-0.4,0-0.7,0.1-1.1c0-0.3-0.1-0.6-0.2-0.8l-0.8-1.6
		c-0.7-1.5-2-2.7-3.5-3.4l-1.9-0.9l0,0C7,11.1,6.3,11.1,5.6,11.1z M15,5.1c-2,1-3.6,2.7-4.3,4.7c1.9,1,3.5,2.5,4.6,4.3
		c1.1-1.9,2.7-3.4,4.7-4.4c-0.7-2-2.2-3.7-4.2-4.7C15.5,5,15.2,5,15,5.1z`}/>
        </g>
        <g id="Group-2-Copy-8" transform="translate(139.323883, 8.073010)">
            <path d={`M60.5,29.8c3.5,2.9,5.2,7.7,5.2,14.1c0,3.6-0.3,6.6-0.8,9c-0.8,3.6-4.1,6.2-7.9,6.2
		H38.3c-0.9,0-1.8-0.2-2.6-0.4c1.1-1.2,2.1-2.6,2.9-4.1l18.5,0c1.6,0,3-1.1,3.4-2.7c0.5-2,0.7-4.7,0.7-7.9c0-5.2-1.2-8.7-3.5-10.6
		c-0.1-0.1-0.3-0.2-0.5-0.1l-5.6,1.6c-3.4,1-7.1,0.9-10.5-0.4l-2.9-1.1c-0.8-1.5-1.8-2.8-3-3.9c1.3-0.8,3-1,4.5-0.4l2.9,1.1
		c2.4,0.9,5.1,1,7.6,0.3l5.6-1.6C57.4,28.4,59.2,28.7,60.5,29.8z M47.6,2.9c6.9,0,12.5,5.6,12.5,12.4c0,6.8-5.4,12.3-12.2,12.4
		l-0.3,0c-6.9,0-12.6-5.6-12.5-12.4C35.1,8.4,40.7,2.9,47.6,2.9z M53.9,10.1c-0.8,0.8-1.9,1.4-3.2,1.9c-1.9,0.6-3.2,0.8-5.8,0.8
		c-1,0-1.5,0-2,0.1c-1.1,0.1-2.1,0.4-3.2,0.9c-0.1,0.6-0.2,1.1-0.2,1.6c0,4.5,3.7,8.1,8.2,8.1c4.5,0,8.2-3.7,8.2-8.1
		C55.8,13.4,55.1,11.6,53.9,10.1z M50.7,14.5c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
		C49.1,15.2,49.9,14.5,50.7,14.5z M44.7,14.5c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
		C43.1,15.2,43.8,14.5,44.7,14.5z`}/>
            <path d={`M31.6,29.8c3.5,2.9,5.2,7.7,5.2,14.1c0,3.6-0.3,6.6-0.8,9c-0.8,3.6-4.1,6.2-7.9,6.2
		H9.5c-3.5,0-6.6-2.2-7.7-5.6c-1.1-3.3-1.5-7-1.2-11.2c0.3-5.4,1.9-9.5,4.8-12.2c1.4-1.4,3.5-1.8,5.4-1.1l2.9,1.1
		c2.4,0.9,5.1,1,7.6,0.3l5.6-1.6C28.5,28.4,30.3,28.7,31.6,29.8z M28.2,33.2l-5.6,1.6c-3.4,1-7.1,0.9-10.5-0.4l-2.9-1.1
		c-0.2-0.1-0.4,0-0.6,0.1c-2,1.9-3.2,4.9-3.4,9.2c-0.2,3.6,0.1,6.8,1,9.5c0.5,1.4,1.8,2.4,3.3,2.4h18.7c1.6,0,3-1.1,3.4-2.7
		c0.5-2,0.7-4.7,0.7-7.9c0-5.2-1.2-8.7-3.5-10.6C28.5,33.2,28.3,33.2,28.2,33.2z M17.9,2.5c6.9,0,12.5,5.6,12.5,12.4
		c0,0.1,0,0.1,0,0.2l0,0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0.1,2.1,0.6,4,1.4,5.8c0.3,0.7,0.6,1.3,1,1.8c0.4,0.6,0,1.1-0.8,1.3
		c-0.5,0.2-1.4,0.3-2.4,0.4c-1.5,0.1-3.2,0.2-4.7,0.2c-2.1,1.4-4.6,2.2-7.1,2.2c-2.6,0-5.1-0.8-7.1-2.2c-1.4,0-3.2,0-4.7-0.2
		c-1-0.1-1.8-0.2-2.4-0.4C3,24.3,2.6,23.8,3,23.2c0.4-0.6,0.7-1.1,1-1.8c0.8-1.8,1.3-3.8,1.4-5.8c0-0.1,0-0.2,0-0.4
		c0-0.2,0-0.2,0-0.2l0-0.1C5.4,8.1,11,2.5,17.9,2.5z M24.2,8.7L23.9,9c-0.8,0.8-1.8,1.5-3.2,2c-1.8,0.6-3.1,0.7-5.6,0.7
		c-1.1,0-1.5,0-2.1,0.1c-1.2,0.1-2.3,0.4-3.4,0.9c0,0,0,0,0,0l-0.1,0l0,0l-0.1,0L9.2,13C9.1,13.7,9,14.3,9,14.9c0,4.9,4,8.8,8.9,8.8
		s8.9-4,8.9-8.8c0-2.1-0.8-4.1-2.1-5.7L24.5,9L24.2,8.7z M15,14.1c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6
		c-0.9,0-1.6-0.7-1.6-1.6C13.3,14.9,14.1,14.1,15,14.1z M21,14.1c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6
		C19.4,14.9,20.2,14.1,21,14.1z`}/>
        </g>
        <g transform="translate(15, 0)">
            <path d={`M86.6,0.5c1.1,0,2.1,0.8,2.3,1.8L93.8,22c0.1,0.3,0.1,0.6,0.1,0.9L89,65.7
	c-0.1,1.2-1.2,2.1-2.4,2.1H62.2c-1.2,0-2.3-0.9-2.4-2.1L55,22.8c0-0.3,0-0.6,0.1-0.9l4.9-19.6c0.3-1.1,1.2-1.8,2.3-1.8H86.6z
	 M84.7,5.3H64.1l-4.3,17.4L64.4,63h20.1L89,22.7L84.7,5.3z`}/>
        </g>
        <path d={`M31.6,38.6c3.5,2.9,5.2,7.7,5.2,14.1c0,3.6-0.3,6.6-0.8,9c-0.8,3.6-4.1,6.2-7.9,6.2
	H9.5c-3.5,0-6.6-2.2-7.7-5.6c-1.1-3.3-1.5-7-1.2-11.2c0.3-5.4,1.9-9.5,4.8-12.2c1.4-1.4,3.5-1.8,5.4-1.1l2.9,1.1
	c2.4,0.9,5.1,1,7.6,0.3l5.6-1.6C28.5,37.1,30.3,37.5,31.6,38.6z M28.2,42l-5.6,1.6c-3.4,1-7.1,0.9-10.5-0.4l-2.9-1.1
	c-0.2-0.1-0.4,0-0.6,0.1c-2,1.9-3.2,4.9-3.4,9.2c-0.2,3.6,0.1,6.8,1,9.5c0.5,1.4,1.8,2.4,3.3,2.4h18.7c1.6,0,3-1.1,3.4-2.7
	c0.5-2,0.7-4.7,0.7-7.9c0-5.2-1.2-8.7-3.5-10.6C28.5,42,28.3,41.9,28.2,42z M17.9,11.2c6.9,0,12.5,5.6,12.5,12.4c0,0.1,0,0.1,0,0.2
	l0,0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0.1,2.1,0.6,4,1.4,5.8c0.3,0.7,0.6,1.3,1,1.8c0.4,0.6,0,1.1-0.8,1.3
	c-0.5,0.2-1.4,0.3-2.4,0.4c-1.5,0.1-3.2,0.2-4.7,0.2c-2.1,1.4-4.6,2.2-7.1,2.2c-2.6,0-5.1-0.8-7.1-2.2c-1.4,0-3.2,0-4.7-0.2
	c-1-0.1-1.8-0.2-2.4-0.4C3,33,2.6,32.6,3,32c0.4-0.6,0.7-1.1,1-1.8c0.8-1.8,1.3-3.8,1.4-5.8c0-0.1,0-0.2,0-0.4c0-0.2,0-0.2,0-0.2
	l0-0.1C5.4,16.8,11,11.2,17.9,11.2z M24.2,17.5l-0.3,0.3c-0.8,0.8-1.8,1.5-3.2,2c-1.8,0.6-3.1,0.7-5.6,0.7c-1.1,0-1.5,0-2.1,0.1
	c-1.2,0.1-2.3,0.4-3.4,0.9c0,0,0,0,0,0l-0.1,0l0,0l-0.1,0l-0.2,0.2C9.1,22.4,9,23.1,9,23.7c0,4.9,4,8.8,8.9,8.8s8.9-4,8.9-8.8
	c0-2.1-0.8-4.1-2.1-5.7l-0.2-0.2L24.2,17.5z M15,22.9c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
	C13.3,23.6,14.1,22.9,15,22.9z M21,22.9c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6C19.4,23.6,20.2,22.9,21,22.9z`}/>
    </svg>;
};
