import Button from '@eg/elements/Button';
import { ArrowRightIcon } from '@eg/elements/components/Icons';
import * as React from 'react';
import './SelectableCard.css';

export interface SelectableCardProps {
    heading: string;
    nextText: string;
    disabled?: boolean;
    onClick: () => void;
    icon?: JSX.Element;
    dataComponentId: string;
    children?: React.ReactNode;
}

const SelectableCard = (props: SelectableCardProps): JSX.Element => {
    const rightColumnClassName =  `esc_col esc_col-12 ${props.icon && 'esc_col-m-8'} selectable-card-right-column`;

    return (
        <div className={props.disabled ? 'disabled-selectable-card' : 'selectable-card'}
             onClick={!props.disabled ? props.onClick : undefined}
             data-component-id={props.dataComponentId}>

            <div className="esc_grid__wrapper" style={{margin: 0}}>
                {props.icon && <div className="esc_col esc_col-12 esc_col-m-4 selectable-card-left-column">
                    <div style={{padding: '10px 0'}}>
                        <div className="selectable-card-left-column-header">{props.heading}</div>
                        <div style={{flex: '1'}}>
                            {props.icon}
                        </div>
                    </div>
                </div>
                }
                <div className={rightColumnClassName} data-component-id={`${props.dataComponentId}-next-text`}>
                    {!props.icon && <div className="selectable-card-right-column-header">{props.heading}</div> }
                    {props.children && <div style={{clear: 'both'}}>{props.children}</div>}
                    <div className="selectable-card-right-column-select-line" style={!props.children ? {marginTop: '-6px'} : {}}>
                        {props.disabled && <div className="selectable-card-right-column-message">{props.nextText}</div>}
                        {!props.disabled && <Button width="auto" iconRight={ArrowRightIcon} variant="text-link">
                            {props.nextText}
                        </Button>}
                    </div>
                </div>
            </div>

        </div>);
};

export default SelectableCard;
