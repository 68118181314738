import LoadingSpinner from '@eg/elements/LoadingSpinner';
import Modal from '@eg/elements/Modal';
import React, { useCallback, useEffect } from 'react';
import { SESSION_STORAGE_SUBMIT_LOADING_STEP, Storage } from '../helpers/Storage';
import './SubmitLoadingModal.css';

export interface SubmitLoadingModalData {
    loading: boolean;
}

const DELAY_STEPS = 15000;
const NUMBER_OF_STEPS = 5;

function SubmitLoadingModal(props: SubmitLoadingModalData) {
    const [currentStep, setCurrentStep] = React.useState<number>(Number(Storage.readItem(SESSION_STORAGE_SUBMIT_LOADING_STEP)) || 1);
    // tslint:disable-next-line:no-any
    const [currentTimeout, setCurrentTimeout] = React.useState<any>();

    const updateSteps = useCallback((startStep: number, updateCurrentStep: (newStep: number) => void) => {
        if (startStep >= NUMBER_OF_STEPS) {
            return;
        }
        const newTimeout = setTimeout(() => {
            const newStep = startStep + 1;
            updateCurrentStep(newStep);
            updateSteps(newStep, updateCurrentStep);
        }, DELAY_STEPS);
        setCurrentTimeout(newTimeout);
    }, []);
    useEffect(() => {
        if (props.loading) {
            updateSteps(currentStep, (newStep: number) => {
                Storage.writeItem(SESSION_STORAGE_SUBMIT_LOADING_STEP, String(newStep));
                setCurrentStep(newStep);
            });
        } else {
            if (currentTimeout) {
                clearTimeout(currentTimeout);
                setCurrentTimeout(undefined);
                setCurrentStep(1);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.loading, currentStep, updateSteps]);

    return <div>
        <Modal open={props.loading}>
            <div style={{textAlign: 'center'}}>
                <div
                    style={{
                        position: 'relative',
                        height: '150px',
                        marginBottom: '1em'
                    }}
                >
                    <LoadingSpinner viewport="relative" show={true}/>
                </div>
                <h2>Bitte haben Sie noch einen Moment Geduld</h2>
                <p>
                    Ihr (Angebot / Direktabschluss) wird aktuell verarbeitet.
                    Bitte beachten Sie, dass dies einige Augenblicke in Anspruchen nehmen kann.
                    Bitte warten Sie, bis der Prozess abgeschlossen ist.
                </p>
                <p style={{maxWidth: '600px', margin: '0 auto', marginTop: '50px'}}>
                    {currentStep === 1 && <b className="loading">Tarifdaten werden geprüft</b>}
                    {/* tslint:disable-next-line:no-magic-numbers */}
                    {currentStep === 2 && <b className="loading">Ihre persönliche Daten werden geprüft</b>}
                    {/* tslint:disable-next-line:no-magic-numbers */}
                    {currentStep === 3 && <b className="loading">Persönliche Versicherungsscheinnummer wird generiert</b>}
                    {/* tslint:disable-next-line:no-magic-numbers */}
                    {currentStep === 4 && <b className="loading">Versicherungspolice / Angebot wird erstellt</b>}
                    {/* tslint:disable-next-line:no-magic-numbers */}
                    {currentStep === 5 &&
                    <b className="loading">Leider dauert die Bearbeitung Ihrer Anfrage ungewöhnlich lange. Bitte haben Sie noch einen Augenblick Geduld</b>}
                </p>
            </div>
        </Modal>
    </div>;
}

export default SubmitLoadingModal;
