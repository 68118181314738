import { StepNavigation as EEStepNavigation } from '@eg/elements/components/StepNavigation';
import * as React from 'react';

export const ordersteps: string[] = ['Tarifdaten', 'Beitrag', 'Persönliches', 'Zusammenfassung'];
export const offerSteps: string[] = ['Tarifdaten', 'Beitrag', 'Persönliches'];

export interface StepNavigationProps {
    activeTab: number;
    goToStepByIndex?: (index: number) => void;
    isOfferNavigation: boolean;
}

export enum PageStepIndex {
    TARIFF_DATA = 0,
    FEE = 1,
    PERSONAL_INFORMATION = 2,
    CHECKOUT = 3
}

export const StepNavigation = (props: StepNavigationProps) => (
    <div className="stg-step-navigation" style={{marginTop: '-55px'}}>
        <EEStepNavigation variant="light"
            activeStepIndex={props.activeTab}
            steps={props.isOfferNavigation ? offerSteps : ordersteps}
            goToStepByIndex={props.goToStepByIndex}
        />
    </div>
);
