export const getConfigVariable = (variable: string): string | undefined => {
    const element = document.querySelector('[id^=\'stg-otr\']');
    const params = window.params || {};
    const appId = element ? element.id : '';

    const configVariables = window.aemassets || window.gwtassets || params[appId] ;
    if (configVariables) {
        return configVariables[variable];
    } else {
        return undefined;
    }
};
