import React, { FC, useEffect } from 'react';
import DisclaimerInfoBox from '../components/DisclaimerInfoBox';
import { Footer } from '../components/Footer/Footer';
import { Headline } from '../components/Headline';
import { scrollToTop } from '../helpers/scrolling';
import { NavigationAction } from '../routing/StateMachineTypes';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';

export interface ConfidentialityReleasePageData extends StoreStateUpdater<ConfidentialityReleasePageData> {
    confidentialityRelease?: boolean;
}

export const ConfidentialityReleasePage: FC<PagePropsWithValues<ConfidentialityReleasePageData>> = props => {
    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <>
            <Headline>
                Schweigepflichtentbindungserklärung
            </Headline>
            <div style={{textAlign: 'center', marginBottom: '4em'}}>
                <h4>Wir benötigen noch Ihre Einwilligung:</h4>
                <div>
                    Sie sind mit der Weitergabe Ihrer Daten durch die ERGO Vorsorge Lebensversicherung AG an
                    andere Stellen, Rückversicherungen und den zuständigen selbständigen Vermittler einverstanden
                    und entbinden die Ergo Vorsorge Lebensversicherung AG von ihrer Schweigepflicht.
                    Alle Informationen zu den Datenschutz-Erklärungen erhalten Sie mit Ihren Vertragsunterlagen.
                </div>
            </div>
            <Footer
                handleAction={props.handleAction}
                onNextClick={() => props.handleAction(NavigationAction.NEXT)}
                disableOfferNavigation={true}
                nextButtonText="Ja, ich bin damit einverstanden"
            />
            <DisclaimerInfoBox/>
        </>
    );
};
