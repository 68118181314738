import ControlWithHint from '@eg/elements/ControlWithHint';
import Input from '@eg/elements/Input';
import Slider from '@eg/elements/Slider';
import { Field, FieldProps, FormikProps, FormikValues } from 'formik';
import * as React from 'react';
import { ValueRanges } from 'stg-common';
import { emptyFunction } from '../../helpers/emptyFunction';
import { filterNumberInputField, getErrorMessage } from '../../helpers/validationHelpers';
import { DataIdPaymentPeriodPage } from '../../types/DataId';
import './PaymentPeriodSlider.css';

interface PaymentPeriodSliderProps {
    displayWarningText?: boolean;
    isFeePage: boolean;
    // tslint:disable-next-line:no-any
    form: FormikProps<any>;
    valueRange: ValueRanges;
    // tslint:disable-next-line:no-any
    updateState?: any;
}

export const PaymentPeriodSlider = (props: PaymentPeriodSliderProps): JSX.Element => {
    const update = props.updateState || emptyFunction;
    if (!props.form.touched.paymentPeriod) {
        props.form.setFieldTouched('paymentPeriod');
    }

    return (<Field
        name="paymentPeriod"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue} = props.form;
            const MAX_LENGTH_PERIOD_INPUT = 2;
            return (
                <div className="esc_grid">
                    <div className="esc_grid__wrapper" style={{justifyContent: 'center'}}>
                        <div className="esc_col esc_col-12 esc_col-s-8">
                            <Slider
                                id={field.name}
                                data-component-id={DataIdPaymentPeriodPage.SLIDER_PAYMENTPERIOD}
                                min={props.valueRange.paymentPeriod.min}
                                max={props.valueRange.paymentPeriod.max}
                                step={props.valueRange.paymentPeriod.increment}
                                value={field.value}
                                subTitle={`${props.valueRange.paymentPeriod.min} bis ${props.valueRange.paymentPeriod.max} Jahre`}
                                onChange={value => {
                                    setFieldValue(field.name, value);
                                    update({paymentPeriod: field.value});
                                }}/>
                        </div>
                        <div className="esc_col esc_col-s-2 esc_col-8">
                            <Input
                                id={field.name}
                                name={field.name}
                                aria-hidden={true}
                                maxLength={MAX_LENGTH_PERIOD_INPUT}
                                alignTextRight={true}
                                onKeyDown={filterNumberInputField}
                                data-component-id={DataIdPaymentPeriodPage.INPUT_PAYMENTPERIOD}
                                value={field.value ? String(field.value) : field.value}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    let paymentPeriod;
                                    if (event.target.value) {
                                        paymentPeriod = Number(event.target.value);
                                        paymentPeriod = isNaN(paymentPeriod) ? props.valueRange.paymentPeriod.min : paymentPeriod;
                                        setFieldValue(field.name, paymentPeriod.toString());
                                    } else {
                                        setFieldValue(field.name, undefined);
                                    }
                                    update({paymentPeriod});
                                }}
                            />
                        </div>
                        <div className="esc_col esc_col-s-2 esc_col-4"
                             style={{
                                 lineHeight: '49px',
                                 display: 'block',
                                 textAlign: 'left',
                                 fontSize: '1.7em',
                                 fontWeight: 'bold',
                                 whiteSpace: 'nowrap'
                             }}>
                            Jahre
                        </div>
                        <ControlWithHint error={getErrorMessage(props.form, {name: 'paymentPeriod'})}><></>
                        </ControlWithHint>
                    </div>
                    {props.displayWarningText &&
                    <div className="fancy-text-popup"
                         data-component-id="paymentPeriod-only-basic-tarif-message">
                        <br/>
                        <small><b>Tipp: </b>
                            Entscheiden Sie sich für die vorbelegte oder für eine kürzere Zahlungsdauer. Dann können Sie zwischen allen 3 Varianten
                            wählen: Grundschutz, Komfort oder Premium.
                        </small>
                    </div>}
                    {!props.displayWarningText && !props.isFeePage && <div style={{paddingTop: '5em'}}></div>}
                </div>
            );
        }}/>);
};
