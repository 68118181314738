import { ValueRanges } from 'stg-common';
import { number, object, Schema } from 'yup';
import { STEP_RANGE_SUM_INSURED } from '../constants';
import formatter, { EURO_WITHOUT_CENT } from '../helpers/currencyFormatter';
import { CustomMixedSchema } from './date';

export function createInsuranceSumPageSchema(valueRanges: ValueRanges): Schema<{}> {

    const insuranceSum = valueRanges.insuranceSum;
    const currencyFormatter = formatter(EURO_WITHOUT_CENT);
    const sumInsuredMinMaxError = `Die Versicherungssumme liegt online bei ${currencyFormatter.format(insuranceSum.min)} bis ${currencyFormatter.format(insuranceSum.max)}.
  Bitte korrigieren Sie die Angabe.`;

    return object().shape({
        insuranceSum:  (number() as CustomMixedSchema)
            .required('Bitte geben Sie die Versicherungssumme an.')
            .nullable(false)
            .min(insuranceSum.min, sumInsuredMinMaxError)
            .max(insuranceSum.max, sumInsuredMinMaxError)
            .test('isFullFiveHundredEuro', 'Es können nur ganze fünfhundert Eurobeträge abgesichert werden.', value => value % STEP_RANGE_SUM_INSURED === 0)
    });
}
