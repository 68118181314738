import * as React from 'react';
import { sanitizeWhitespaces } from 'stg-common';
import { isKooperationSales } from '../../helpers/kooperationSales';
import { getOeNumber } from '../../helpers/modeConfig';
import BankingDetailsPage, { BankingDetailsPageData } from '../../pages/BankingDetailsPage';
import { getOfferPageData, updateBankingDetailsPageData } from '../../services/api';
import { writeOfferBackJumpMarker } from '../routeHelper';
import { StateDefinition } from '../StateMachine';
import { CHECKOUT_PAGES, NavigationAction, StateName } from '../StateMachineTypes';

export const bankingDetailsPage: StateDefinition<BankingDetailsPageData> = {
    name: StateName.BANKING_DETAILS_PAGE,
    validInboundStates: [
        ...CHECKOUT_PAGES,
        StateName.ENTITLED_PERSONS_PAGE,
        StateName.INSURANCE_SUMMARY_PAGE,
        StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE,
        StateName.REQUEST_OFFER_CHECKOUT_PAGE,
        StateName.INSURED_PERSON_PAGE
    ],
    transitions: [
        {
            test: (action, data) => {
                return action === NavigationAction.NEXT;
            },
            newState: StateName.INSURANCE_SUMMARY_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            newState: StateName.INSURED_PERSON_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_REQUEST_OFFER,
            newState: StateName.REQUEST_OFFER_CHECKOUT_PAGE
        },
        // BreadCrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'bankingDetails');

        return {
            userInput: {
                vorname: response.vorname,
                nachname: response.nachname,
                iban: response.iban,
                bic: response.bic,
                messages: response.messages
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        writeOfferBackJumpMarker(transitionDetails);

        return {
            payload: await updateBankingDetailsPageData(inputData.businessId, {
                iban: sanitizeWhitespaces(inputData.userInput.iban),
                bic: inputData.userInput.bic
            }, getOeNumber(), isKooperationSales())
        };
    },
    render: (inputData, handleAction, updateApp) => {
        const input = inputData.userInput;

        return <BankingDetailsPage
            storeState={{
                iban: input.iban,
                bic: input.bic,
                vorname: input.vorname,
                nachname: input.nachname,
                update: values => updateApp(values)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};
