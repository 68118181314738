import { CheckIllustratedIcon } from '@eg/elements/components/Icons';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { CalculationMode, IsoDateString } from 'stg-common';
import { PaymentPeriodSlider } from '../components/calculationComponents/PaymentPeriodSlider';
import { Footer } from '../components/Footer/Footer';
import { Headline } from '../components/Headline';
import { calculateAgeAtPaymentPeriodEnd } from '../helpers/dateHelper';
import { isAgeAtPaymentPeriodEndOverEightyfour } from '../helpers/paymentHelper';
import { scrollToTop } from '../helpers/scrolling';
import { NavigationAction } from '../routing/StateMachineTypes';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';
import { createPaymentPeriodPageSchema } from '../validation/PaymentPeriodPage';

export interface PaymentPeriodPageData extends StoreStateUpdater<PaymentPeriodPageData> {
    birthdate: IsoDateString;
    calculationMode: CalculationMode;
    insuranceBegin: IsoDateString;
    paymentPeriod: number;
}

const renderAgeAtPaymentPeriodEndInfo = (userInput: PaymentPeriodPageData, paymentPeriodFromForm: number): JSX.Element => {
    const age: number = calculateAgeAtPaymentPeriodEnd(userInput.birthdate, userInput.insuranceBegin, paymentPeriodFromForm);
    return <div className="esc_col esc_col-10 esc_col-m-11 grid-cell-vertical-centered" data-component-id="payment-end-age-message">
        Sie zahlen, bis die versicherte Person {isNaN(age) ? '--' : age} Jahre alt ist.
    </div>;
};

function PaymentPeriodPage(props: PagePropsWithValues<PaymentPeriodPageData>) {

    React.useEffect(() => {
        scrollToTop();
    }, []);

    const ICON_WIDTH = 32;
    const nextButtonText = props.storeState.calculationMode === CalculationMode.INSURANCE_SUM ? 'Beitrag berechnen' : 'Summe berechnen';
    return (<>
            <Headline>Wie lang wollen Sie Beiträge zahlen?</Headline>
            <Formik
                initialValues={props.storeState}
                onSubmit={values => {
                    const validatedValues = {
                        ...props.storeState,
                        ...createPaymentPeriodPageSchema(props.valueRanges).cast(values)
                    };
                    props.storeState.update(validatedValues);
                    props.handleAction(NavigationAction.NEXT);
                }}
                validationSchema={createPaymentPeriodPageSchema(props.valueRanges)}
            >{form => (
                <Form>
                    <div style={{maxWidth: '400px', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', padding: '10px'}}>
                        <PaymentPeriodSlider
                            isFeePage={false}
                            displayWarningText={(isAgeAtPaymentPeriodEndOverEightyfour(form, props.storeState.birthdate, props.storeState.insuranceBegin))}
                            form={form}
                            valueRange={props.valueRanges}
                            updateState={props.storeState.update}/>
                        <Footer handleAction={props.handleAction} disableOfferNavigation={true}
                                nextButtonText={nextButtonText}/>
                    </div>
                    <br/>
                    <br/>
                    <div className="esc_grid" style={{maxWidth: '500px', margin: '0 auto'}}>
                        <div className="esc_grid__wrapper" style={{textAlign: 'left'}}>
                            <div className="esc_col esc_col-2 esc_col-m-1">
                                <CheckIllustratedIcon height={ICON_WIDTH} width={ICON_WIDTH} style={{margin: 'auto'}}/>
                            </div>
                            {renderAgeAtPaymentPeriodEndInfo(props.storeState, form.values.paymentPeriod)}
                            <div className="esc_col esc_col-2 esc_col-m-1">
                                <CheckIllustratedIcon height={ICON_WIDTH} width={ICON_WIDTH} style={{margin: 'auto'}}/>
                            </div>
                            <div className="esc_col esc_col-10 esc_col-m-11 grid-cell-vertical-centered">Auch wenn Sie danach nicht mehr einzahlen,
                                besteht noch Versicherungsschutz – lebenslang.
                            </div>
                            <div className="esc_col esc_col-2 esc_col-m-1">
                                <CheckIllustratedIcon height={ICON_WIDTH} width={ICON_WIDTH} style={{margin: 'auto'}}/>
                            </div>
                            <div className="esc_col esc_col-10 esc_col-m-11 grid-cell-vertical-centered">Das Alter der versicherten Person und die Dauer der
                                Beitragszahlung beeinflussen die Höhe des Beitrags.
                            </div>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>

        </>
    );
}

export default PaymentPeriodPage;
