import {
  DatalayerProductAttributes,
  DatalayerUserProfileAttributes,
} from '@eg/tracking/datalayer.models';
import {
  Beitragsvariante,
  BurialPackageIdentifier,
  IsoDateString,
  mapToGermanDate,
} from 'stg-common';
import { PAYMENT_METHOD_SINGLE_FEE } from '../constants';
import { mapAddressesToPrintableText } from '../helpers/addressesHelper';
import { getKuerzel, getProduktgruppe } from '../helpers/aemHelper';
import { PHONE_NUMBER_PREFIXES } from './helpers/tracking.const';
import {
  hashData,
  preparePhoneNumberForHashing,
} from './helpers/tracking.helpers';
import {
  ProfileAddress,
  ProfileInfo,
  UpdatedDatalayerWithoutEvent,
} from './helpers/tracking.types';
import { TrackingData } from './TrackingData';

const createBasisTrackingObject = () => {
  return {
    product: [
      {
        attributes: {
          tariffOptions1: `Rechner=${getKuerzel}2019`
        } as DatalayerProductAttributes,
        productInfo: { productName: getProductName() },
        category: { primaryCategory: getProductCategory() }
      }
    ],
    user: [
      {
        profile: {
          attributes: {} as DatalayerUserProfileAttributes,
          address: undefined as ProfileAddress | undefined,
          profileInfo: undefined as ProfileInfo | undefined
        }
      }
    ]
  };
};

const getProductName = () => {
  return getKuerzel;
};

const getProductCategory = () => {
  return getProduktgruppe;
};

export const mapTrackingData = (
  trackingData: TrackingData
): UpdatedDatalayerWithoutEvent => {
  const trackingObject = createBasisTrackingObject();
  if (!trackingData) {
    return trackingObject;
  }

  if (!trackingData.divergingInsuredPerson && trackingData.birthdate) {
    trackingObject.user[0].profile.attributes.birthday = getYear(
      trackingData.birthdate
    );
  }

  if (trackingData.insuranceBegin) {
    trackingObject.product[0].attributes.insuranceStart = mapToGermanDate(
      trackingData.insuranceBegin
    );
  }

  if (trackingData.insuranceSum !== undefined) {
    trackingObject.product[0].attributes.tariffOptions1 += '|BerGru=VersSumGes';
  }

  if (trackingData.insuranceFee !== undefined) {
    trackingObject.product[0].attributes.tariffOptions1 +=
      '|BerGru=Beitragssumme';
  }

  if (trackingData.paymentMethod) {
    trackingObject.product[0].attributes.tariffOptions1 += `|Zahlung=${mapPaymentMethod(
      trackingData.paymentMethod
    )}`;
  }

  if (
    trackingData.paymentPeriod &&
    trackingData.paymentMethod !== PAYMENT_METHOD_SINGLE_FEE
  ) {
    trackingObject.product[0].attributes.tariffOptions1 += `|BeitLZ=${trackingData.paymentPeriod}`;
  }

  if (trackingData.paymentMethod === PAYMENT_METHOD_SINGLE_FEE) {
    trackingObject.product[0].attributes.tariffOptions1 += `|BeitLZ=NV|Dynamik=NV|FlexOpt=NV`;
  }

  if (trackingData.insuranceSum !== undefined) {
    trackingObject.product[0].attributes.tariffOptions1 += `|VersSumme=${trackingData.insuranceSum}`;
  } else if (trackingData.selectedVariant) {
    trackingObject.product[0].attributes.tariffOptions1 += `|VersSumme=${trackingData.selectedVariant.versicherungssumme}`;
  }

  if (trackingData.variante) {
    trackingObject.product[0].attributes.tariffOptions1 += `|Tarif=${mapVariant(
      trackingData.variante
    )}`;
    // tslint:disable-next-line
    trackingObject.product[0].attributes["productCombination"] = mapVariant(
      trackingData.variante
    );
  }

  if (trackingData.burialPackage) {
    trackingObject.product[0].attributes.tariffOptions1 += `|Bestatt=${mapBurialPackage(
      trackingData.burialPackage
    )}`;
  }

  if (
    trackingData.flexOption !== undefined &&
    trackingData.paymentMethod !== PAYMENT_METHOD_SINGLE_FEE
  ) {
    trackingObject.product[0].attributes.tariffOptions1 += `|FlexOpt=${mapJaNein(
      trackingData.flexOption
    )}`;
  }

  if (
    trackingData.feeDynamic !== undefined &&
    trackingData.paymentMethod !== PAYMENT_METHOD_SINGLE_FEE
  ) {
    trackingObject.product[0].attributes.tariffOptions1 += `|Dynamik=${mapJaNein(
      trackingData.feeDynamic
    )}`;
  }

  if (trackingData.divergingInsuredPerson !== undefined) {
    trackingObject.user[0].profile.attributes.insuredIsInsuredPerson =
      !trackingData.divergingInsuredPerson;
  }

  if (trackingData.divergingInsuredPerson && trackingData.birthdate) {
    trackingObject.user[0].profile.attributes.birthdayInsuredPerson = getYear(
      trackingData.birthdate
    );
  }

  if (trackingData.policyHolder) {
    const { birthdate, adresse, email, rufnummer, vorname, nachname, vorwahl } =
      trackingData.policyHolder;
    trackingObject.user[0].profile.attributes.birthday = getYear(birthdate);
    trackingObject.user[0].profile.address = {
      postalCode: adresse.plz,
      city: hashData(adresse.ort),
      line1: hashData(`${adresse.strasse} ${adresse.hausnummer}`),
      ...(adresse.land && { country: hashData(adresse.land) })
    };

    const fullPhoneNumber = `${vorwahl}${rufnummer}`;
    trackingObject.user[0].profile.profileInfo = {
      ...(email && { email: hashData(email) }),
      ...(rufnummer && {
        telephone: hashData(
          `${PHONE_NUMBER_PREFIXES.german}${preparePhoneNumberForHashing(
            fullPhoneNumber
          )}`
        ),
        telephoneE164: hashData(
          `${PHONE_NUMBER_PREFIXES.e164}${preparePhoneNumberForHashing(
            fullPhoneNumber
          )}`
        )
      }),

      firstName: hashData(vorname),
      lastName: hashData(nachname)
    };
    trackingObject.user[0].profile.attributes.gender =
      mapAddressesToPrintableText(trackingData.policyHolder.anrede);
  }

  if (trackingData.entitledPersons) {
    trackingObject.product[0].attributes.tariffOptions1 += `|BezugsrechtAend=${trackingData.entitledPersons.length}`;
  }

  return trackingObject;
};

const getYear = (date: IsoDateString) => {
  return new Date(date).getFullYear().toString();
};

const mapPaymentMethod = (paymentMethod: string) => {
  switch (paymentMethod) {
    case 'MONATLICH':
      return '1/12';
    case 'VIERTELJAEHRLICH':
      return '1/4';
    case 'HALBJAEHRLICH':
      return '1/2';
    case 'JAEHRLICH':
      return '1/1';
    case PAYMENT_METHOD_SINGLE_FEE:
      return 'Einmalig';
    default:
      return undefined;
  }
};

const mapVariant = (paymentMethod: string) => {
  switch (paymentMethod) {
    case Beitragsvariante.BASIS:
      return 'Grundschutz';
    case Beitragsvariante.KOMFORT:
      return 'Komfort';
    case Beitragsvariante.PREMIUM:
      return 'Premium';
    default:
      return undefined;
  }
};

const mapBurialPackage = (burialPackage: BurialPackageIdentifier) => {
  switch (burialPackage) {
    case BurialPackageIdentifier.KEIN:
      return 'NV';
    case BurialPackageIdentifier.KLASSISCH:
      return 'Klass';
    case BurialPackageIdentifier.TRADITIONELL:
      return 'Trad';
    case BurialPackageIdentifier.EXKLUSIV:
      return 'Exkl';
    default:
      return undefined;
  }
};

const mapJaNein = (bool: boolean) => {
  return bool ? 'J' : 'N';
};
