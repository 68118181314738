import React from 'react';
import { getAgentEmail, getAgentPhone, getIsMakler, getIsTiedAgent } from '../helpers/modeConfig';

const modalTitle = 'Bitte wenden Sie sich an Ihren Ansprechpartner: ';
export const AgentContactMethods = () => {
    const phone = getAgentPhone();
    const email = getAgentEmail();
    const makler = getIsMakler();
    const tiedAgent = getIsTiedAgent();
    return (
        <>
            {makler && (phone || email) && (
                <>
                    <b>{modalTitle}</b>
                    <br />
                    <ul>
                        {phone && <li><span><b data-component-id="contact-method-telephone">telefonisch: {phone}</b></span></li>}
                        {email && <li><span><b data-component-id="contact-method-email">per E-Mail: {email}</b></span></li>}
                    </ul>
                </>
            )}
            {tiedAgent && phone &&
                <>
                    <b>
                        {modalTitle}{phone}
                    </b>
                    <br /><br />
                </>
            }
        </>
    );
};
