import { isValidIsoDateFormat } from 'stg-common';
import { addMethod, mixed, object, Ref, ref, Schema, string, StringSchema } from 'yup';
import { CustomMixedSchema, DateErrorMessages, isDateOlderThan, isDateYoungerThan } from './date';
import { gwgSchema } from './Gwg';

export const streetErrorMessage = 'Bitte geben Sie eine gültige Strasse ein.';
export const housenumberErrorMessage = 'Bitte geben Sie eine gültige Hausnummer ein.';
export const postalCodeErrorMessage = 'Bitte geben Sie eine gültige Postleitzahl ein.';
export const cityErrorMessage = 'Bitte geben Sie einen gültigen Ort ein.';

export const anredeErrorMessage = 'Bitte wählen Sie eine Anrede aus.';
export const vklmErrorMessage = 'Vermittler-Kunden-Leitmerkmal darf nur 20 Stellen haben und muss alphanumerisch sein.';
export const firstNameErrorMessage = 'Bitte geben Sie einen gültigen Vornamen ein.';
export const surnameErrorMessage = 'Bitte geben Sie einen gültigen Nachnamen ein.';
export const birthdateErrorMessage = 'Bitte geben Sie ein gültiges Geburtsdatum ein.';
export const lowAgeErrorMessage = 'Wir können Ihnen diese Versicherung nur anbieten, wenn Sie bei Vertragsabschluss mindestens 18 Jahre alt sind.';
export const highAgeErrorMessage = 'Wir können Ihnen diese Versicherung derzeit online nicht anbieten. Bitte wenden Sie sich für ein persönliches Angebot an unseren gebührenfreien Beratungs-Service.';
export const minAgeVN = 18;
export const maxAgeVn = 100;

export const birthplaceErrorMessage = 'Bitte geben Sie einen gültigen Geburtsort ein.';
export const nationalityErrorMessage = 'Bitte geben Sie ein gültige Staatsangehörigkeit ein.';
export const emailErrorMessage = 'Bitte geben Sie ein gültige E-Mail-Adresse ein.';
export const prefixErrorMessage = 'Bitte geben Sie eine gültige Vorwahl ein.';
export const prefixErrorMessageTwo = 'Bitte geben Sie die Vorwahl mit führender "0" ein.';
export const prefixErrorMessageThree = 'Bitte geben Sie nur Ziffern ein.'; // PX-15035
export const phonenumberErrorMessage = 'Bitte geben Sie ein gültige Rufnummer ein.';
export const phonenumberErrorMessageTwo = 'Bitte geben Sie eine Vorwahl für Ihre Rufnummer ein.';

export const emailPattern = /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-ZäöüÄOÜ0-9-]+\.)+[a-zA-Z]{2,6}$/;

export function createPersonalDataSchema(isDetailedPersonalData: boolean, useGwg: boolean, disableGwgValidationForOffer: boolean,
                                        isNationalityRequired?: boolean): Schema<{}> {

    const address = {
        strasse: string().trim().nullable()
            .required(streetErrorMessage)
            .test('streetPattern', streetErrorMessage, value => {
                const streetPattern = /^[0-9A-Za-z\u00c0-\u01ff'. -]+$/;
                return streetPattern.test(value);
            }),
        hausnummer: string().trim().nullable()
            .required(housenumberErrorMessage)
            .test('housenumberPattern', housenumberErrorMessage, value => {
                const housenumberPattern = /^\d+[a-zA-Z\d _.\-/]*$/;
                return housenumberPattern.test(value);
            }),
        plz: string().trim().nullable()
            .required(postalCodeErrorMessage)
            .test('postalCodePattern', postalCodeErrorMessage, value => {
                const zipPattern = /^\d+$/;
                return zipPattern.test(value);
            }),
        ort: string().trim().nullable()
            .required(cityErrorMessage)
            .test('cityPattern', cityErrorMessage, value => {
                const cityPattern = /^[0-9A-Za-zäüöÄÜÖ\u00c0-\u01ff ./)(-]+$/;
                return cityPattern.test(value);
            })
    };

    let personalDataSchema = object().shape({
        anrede: string().trim().nullable().required(anredeErrorMessage),
        vklm: string().nullable()
            .test('vklmPattern', vklmErrorMessage, value => {
                const vklmPattern = /^[a-zA-Z0-9]+$/;
                return vklmPattern.test(value);
            }),
        vorname: string().trim().nullable()
            .required(firstNameErrorMessage)
            .test('firstNamePattern', firstNameErrorMessage, value => {
                const firstNamePattern = /^[a-zA-Z\u00c0-\u01ff -.]+$/;
                return firstNamePattern.test(value);
            }),
        nachname: string().trim().nullable()
            .required(surnameErrorMessage)
            .test('surnamePattern', surnameErrorMessage, value => {
                const surnamePattern = /^[a-zA-Z\u00c0-\u01ff -.]+$/;
                return surnamePattern.test(value);
            }),
        birthdate: (mixed() as CustomMixedSchema)
            .required(birthdateErrorMessage)
            .isValidIsoDateFormat()
            .test('notTooYoung', lowAgeErrorMessage, value => {
               return isDateOlderThan(new Date(), value, minAgeVN);
            })
            .test('notTooOld', highAgeErrorMessage, value => {
               return isDateYoungerThan(new Date(), value, maxAgeVn);
            }),
        adresse: object(address)
    });

    if (isDetailedPersonalData || isNationalityRequired) {
        personalDataSchema = personalDataSchema.concat(object().shape({
            staatsangehoerigkeit: string().trim().nullable().required(nationalityErrorMessage)
        }));
    }

    if (isDetailedPersonalData) {
        personalDataSchema = personalDataSchema.concat(object().shape({
            geburtsort: string().trim().nullable().required(birthplaceErrorMessage)
                .test('birthplacePattern', birthplaceErrorMessage, value => {
                    const birthplacePattern = /^[0-9A-Za-zäüöÄÜÖ\u00c0-\u01ff ./)(-]+$/;
                    return birthplacePattern.test(value);
                }),
            vorwahl: (string() as CustomMixedSchemaPersonalData)
                .isFullPhoneNumber(ref('rufnummer'), prefixErrorMessage)
                .trim().nullable().notRequired()
                .test('prefixFirstZero', prefixErrorMessageTwo, (value: string | undefined) => {
                    return value ? (value.startsWith('0') || value === '') : true;
                })
                .test('phonenumberPattern', prefixErrorMessageThree, value => {
                    const phonenumberPattern = /^\d+$/;
                    return (phonenumberPattern.test(value) || (value === '' || value === undefined));
                }),
            rufnummer: (string() as CustomMixedSchemaPersonalData)
                .isFullPhoneNumber(ref('vorwahl'), phonenumberErrorMessageTwo)
                .trim().nullable().notRequired()
                .test('phonenumberPattern', phonenumberErrorMessage, value => {
                    const phonenumberPattern = /^\d+$/;
                    return (phonenumberPattern.test(value) || (value === '' || value === undefined));
                }),
            email: string().trim().required(emailErrorMessage)
                .test('emailPattern', emailErrorMessage, value => emailPattern.test(value))

        }));
    }

    if (useGwg && !disableGwgValidationForOffer) {
        personalDataSchema = personalDataSchema.concat(gwgSchema);
    }

    return personalDataSchema;
}

interface CustomMixedSchemaPersonalData extends StringSchema {
    isFullPhoneNumber(prefix: Ref, message: string): this;
    isValidIsoDateFormat(message?: string): this;
}

addMethod(string, 'isFullPhoneNumber', function(phonennumber: Ref, message: string) {
    // tslint:disable-next-line:no-invalid-this
    return this.test({
        name: 'isValidPrefix',
        exclusive: true,
        message,
        // tslint:disable-next-line:object-literal-shorthand
        test: function(prefix: string) { // NOSONAR
            // tslint:disable-next-line:no-invalid-this no-any
            return (this as any).resolve(phonennumber) ? !!prefix : true;
        }
    });
});

addMethod(mixed, 'isValidIsoDateFormat', function(message?: string) {
    // tslint:disable-next-line:no-invalid-this
    return this.test({
        name: 'isValidIsoDateFormat',
        exclusive: true,
        message: message || DateErrorMessages.FORMAT,
        test: isValidIsoDateFormat
    });
});
