import * as React from 'react';
import EntitledPersonsPage, { EntitledPersonsPageData } from '../../pages/EntitledPersonsPage';
import { getOfferPageData, updateEntitledPersonsPageData } from '../../services/api';
import { writeOfferBackJumpMarker } from '../routeHelper';
import { StateDefinition } from '../StateMachine';
import { CHECKOUT_PAGES, NavigationAction, StateName } from '../StateMachineTypes';

export const entitledPersonsPage: StateDefinition<EntitledPersonsPageData> = {
    name: StateName.ENTITLED_PERSONS_PAGE,
    validInboundStates: [
        ...CHECKOUT_PAGES,
        StateName.INSURED_PERSON_PAGE,
        StateName.BANKING_DETAILS_PAGE,
        StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE,
        StateName.REQUEST_OFFER_CHECKOUT_PAGE
    ],
    transitions: [
        {
            test: (action, data) => action === NavigationAction.NEXT,
            newState: StateName.BANKING_DETAILS_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            newState: StateName.INSURED_PERSON_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_REQUEST_OFFER,
            newState: StateName.REQUEST_OFFER_CHECKOUT_PAGE
        },
        // BreadCrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'entitledPersons');

        return {
            valueRanges: response.valueRanges,
            userInput: {
                entitledPersons: response.entitledPersons,
                divergingInsuredPerson: response.divergingInsuredPerson,
                insuredPersonId: response.insuredPersonId,
                policyHolderId: response.policyHolderId,
                nationalities: response.nationalities,
                burialPackage: response.burialPackage,
                messages: response.messages,
                isEntitledPersonAlsoInsuredPerson: !!response.isEntitledPersonAlsoInsuredPerson
            }
        };
    },
    onExit: async (transitionDetails, inputData) => {
        writeOfferBackJumpMarker(transitionDetails);
        return {
            payload: await updateEntitledPersonsPageData(inputData.businessId, {
                entitledPersons: inputData.userInput.entitledPersons
            })
        };
    },
    render: (inputData, handleAction, updateApp) => {
        return <EntitledPersonsPage
            storeState={{
                businessId: inputData.businessId,
                entitledPersons: inputData.userInput.entitledPersons,
                insuredPersonId: inputData.userInput.insuredPersonId,
                policyHolderId: inputData.userInput.policyHolderId,
                nationalities: inputData.userInput.nationalities,
                burialPackage: inputData.userInput.burialPackage,
                divergingInsuredPerson: inputData.userInput.divergingInsuredPerson,
                isEntitledPersonAlsoInsuredPerson: inputData.userInput.isEntitledPersonAlsoInsuredPerson,
                update: values => updateApp(values)
            }}
            valueRanges={{...inputData.valueRanges}}
            handleAction={handleAction}
        />;
    }
};
