import * as React from 'react';
import DivergingInsuredPersonPage, { DivergingInsuredPersonPageData } from '../../../pages/DivergingInsuredPersonPage';
import { getOfferPageData, updateDivergingInsuredPersonPageData } from '../../../services/api';
import { createOfferNavigation } from '../../routeHelper';
import { StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

const validInboundStates = [
    StateName.BANKING_DETAILS_PAGE,
    StateName.ENTITLED_PERSONS_PAGE,
    StateName.FEE_DYNAMIC_PAGE,
    StateName.FEE_PAGE,
    StateName.INSURANCE_DOCUMENTS_PAGE,
    StateName.INSURANCE_SUMMARY_PAGE,
    StateName.INSURED_PERSON_PAGE,
    StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE,
    StateName.REQUEST_OFFER_INSURED_PERSON_PAGE
];

export const requestOfferDivergingInsuredPersonPage: StateDefinition<DivergingInsuredPersonPageData> = {
    name: StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE,
    validInboundStates,
    transitions: [
        // Breadcrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        },
        // Navigation
        {
            test: (action, data) => action === NavigationAction.NEXT && data.userInput.divergingInsuredPerson,
            newState: StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.NEXT && !data.userInput.divergingInsuredPerson,
            newState: StateName.REQUEST_OFFER_INSURED_PERSON_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            resolveNewState: createOfferNavigation(StateName.BURIAL_PACKAGE_PAGE, validInboundStates),
            newState: StateName.BURIAL_PACKAGE_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'divergingInsuredPerson');
        return {
            userInput: {
                paymentMethod: response.paymentMethod,
                divergingInsuredPerson: response.divergingInsuredPerson
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        return {payload: await updateDivergingInsuredPersonPageData(inputData.businessId, inputData.userInput)};
    },
    render: (inputData, handleAction, updateApp, onError) => {
        return <DivergingInsuredPersonPage
            storeState={{
                paymentMethod: inputData.userInput.paymentMethod,
                divergingInsuredPerson: inputData.userInput.divergingInsuredPerson,
                update: (values, callback) => updateApp(values, callback)
            }}
            onError={onError}
            businessId={inputData.businessId}
            handleAction={handleAction}
            valueRanges={inputData.valueRanges}
            isOfferPage={true}
        />;
    }
};
