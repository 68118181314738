import { Button } from '@eg/elements/Button';
import { DownloadIcon, EmailIcon, MailIcon } from '@eg/elements/components/Icons';
import { InputRow } from '@eg/elements/InputRow';
import { LoadingSpinner } from '@eg/elements/LoadingSpinner';
import { MessageBox } from '@eg/elements/MessageBox';
import { Modal } from '@eg/elements/Modal';
import * as React from 'react';
import DisclaimerInfoBox from '../../components/DisclaimerInfoBox';
import { Footer } from '../../components/Footer/Footer';
import { Headline } from '../../components/Headline';
import { maxLengthEmail } from '../../components/PersonalData';
import { scrollToTop } from '../../helpers/scrolling';
import { NavigationAction } from '../../routing/StateMachineTypes';
import { getPerson, patchPerson } from '../../services/api';
import { trackElementClicked } from '../../tracking/tracker';
import { TrackingElement } from '../../tracking/trackingConstants';
import { PagePropsWithValues, StoreStateUpdater } from '../../types/PageProps';
import { emailPattern } from '../../validation/PersonalDataSchema';

export interface RequestOfferCheckoutPageData extends StoreStateUpdater<RequestOfferCheckoutPageData> {
    policyHolderId: string;
    showMeldung: boolean;
}

interface RequestOfferCheckoutPageProps extends PagePropsWithValues<RequestOfferCheckoutPageData> {
    businessId: string;
    onError: (e: Error) => void;
}

interface RequestOfferCheckoutPageState {
    isLoading: boolean;
    requiresEmail?: boolean;
    showEmailModal: boolean;
    email?: string;
    showEmailError: boolean;
}

class RequestOfferCheckoutPage extends React.Component<RequestOfferCheckoutPageProps, RequestOfferCheckoutPageState> {
    constructor(props: RequestOfferCheckoutPageProps) {
        super(props);
        this.state = {
            isLoading: false,
            showEmailModal: false,
            showEmailError: false
        };

        // tslint:disable-next-line:no-floating-promises
        getPerson(this.props.businessId, this.props.storeState.policyHolderId).then(person => {
            this.setState({
                requiresEmail: !person.email
            });
        });
    }

    public onError = (e: Error) => {
        this.props.onError(e);
    };

    public async componentDidMount() {
        scrollToTop();
    }

    public render() {
        return (<>
            <LoadingSpinner show={this.state.isLoading}/>
            <Headline>Wie möchten Sie Ihr Angebot erhalten?</Headline>
            <div className="personal-data-page" data-component-id="RequestOfferCheckoutPage"
                 style={{maxWidth: '400px', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
                {this.props.storeState.showMeldung &&
                <MessageBox type="error">
                    Online können wir Ihnen den Tarif leider nicht anbieten. Bitte rufen Sie uns an  -  natürlich gebührenfrei!
                </MessageBox>}
                <Button variant="primary" style={{marginTop: '3em', width: '220px'}}
                        data-component-id="angebot-sofort-downloaden"
                        iconLeft={DownloadIcon}
                        onClick={() => {
                            trackElementClicked(TrackingElement.Button_AngebotPDFHerunterladen);
                            this.props.handleAction(NavigationAction.OFFER_DIRECT_ONLINE);
                        }}
                        disabled={this.props.storeState.showMeldung}
                        size="large">
                    Sofort Download
                </Button>
                <br/>
                <Button variant="primary" style={{marginTop: '3em', width: '220px'}}
                        data-component-id="angebot-per-mail"
                        iconLeft={EmailIcon}
                        onClick={() => {
                            trackElementClicked(TrackingElement.Button_AngebotPerEmail);
                            if (this.state.requiresEmail) {
                                this.setState({showEmailModal: true});
                            } else {
                                this.props.handleAction(NavigationAction.OFFER_WRITTEN_EMAIL);
                            }
                        }}
                        disabled={this.props.storeState.showMeldung}
                        size="large">
                    Angebot per E-Mail
                </Button>
                <br/>
                <Button variant="primary" style={{marginTop: '3em', width: '220px'}}
                        data-component-id="angebot-per-post"
                        iconLeft={MailIcon}
                        onClick={() => {
                            trackElementClicked(TrackingElement.Button_AngebotPerPost);
                            this.props.handleAction(NavigationAction.OFFER_WRITTEN_POSTAL);
                        }}
                        disabled={this.props.storeState.showMeldung}
                        size="large">
                    Angebot per Post
                </Button>
            </div>
            <Footer
                disableOfferNavigation={true}
                hideNextButton={true}
                handleAction={this.props.handleAction}
            />
            <DisclaimerInfoBox/>
            <Modal open={this.state.showEmailModal && !this.props.storeState.showMeldung} data-component-id="request-offer-modal-email-required">
                <p style={{marginBottom: '3em'}}>
                    Damit wir das Angebot per E&#8209;Mail zuschicken können, brauchen wir die E&#8209;Mail&#8209;Adresse des Versicherungsnehmer.
                </p>
                <InputRow
                    label="E-Mail-Adresse:" id="email" name="email" maxLength={maxLengthEmail}
                    data-component-id="request-offer-checkout-email"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        this.setState({email: event.target.value});
                    }}
                    error={this.state.showEmailError ? 'Bitte geben Sie eine gültige E-Mail Adresse ein.' : ''}
                />
                <br/><br/>
                <Button variant="tertiary" data-component-id="request-offer-checkout-email-cancel"
                        onClick={() => this.setState({showEmailModal: false, showEmailError: false})}>Abbrechen</Button>
                <Button variant="primary" data-component-id="request-offer-checkout-email-submit"
                        onClick={() => {
                            if (this.isEmailValid()) {
                                // tslint:disable-next-line:no-floating-promises
                                patchPerson(this.props.businessId, {email: this.state.email}, this.props.storeState.policyHolderId)
                                    .then(() => {
                                        this.props.handleAction(NavigationAction.OFFER_WRITTEN_EMAIL);
                                    });
                            } else {
                                this.setState({showEmailError: true});
                            }
                        }}
                        style={{float: 'right'}}>Antrag anfordern</Button>
            </Modal>

        </>);
    }

    private isEmailValid() {
        return this.state.email && emailPattern.test(this.state.email);
    }
}

export default RequestOfferCheckoutPage;
