import * as React from 'react';
import { isRequestOffer } from '../../components/GwgQuestions/GwgQuestions';
import PolicyHolderPage, { PolicyHolderPageData } from '../../pages/PolicyHolderPage';
import { getOfferPageData, getPerson, updatePolicyHolderPageData } from '../../services/api';
import { writeOfferBackJumpMarker } from '../routeHelper';
import { StateDefinition } from '../StateMachine';
import { NavigationAction, StateName } from '../StateMachineTypes';

export const policyHolderPage: StateDefinition<PolicyHolderPageData> = {
    name: StateName.POLICY_HOLDER_PAGE,
    validInboundStates: [
        StateName.DIVERGING_INSURED_PERSON_PAGE,
        StateName.INSURED_PERSON_PAGE,
        StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE,
        StateName.REQUEST_OFFER_INSURED_PERSON_PAGE
    ],
    transitions: [
        {
            test: (action, data) => action === NavigationAction.NEXT && !isRequestOffer(data.userInput),
            newState: StateName.INSURED_PERSON_PAGE
        },
        {
            test: (action, data) => (action === NavigationAction.NEXT && !!isRequestOffer(data.userInput)),
            newState: StateName.REQUEST_OFFER_INSURED_PERSON_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            newState: StateName.INSURED_PERSON_PAGE
        },
        // BreadCrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_REQUEST_OFFER,
            newState: StateName.REQUEST_OFFER_INSURED_PERSON_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'policyHolder').then(offerPageData => {
            if (offerPageData.policyHolderId) {
                return getPerson(inputData.businessId, offerPageData.policyHolderId).then(person => ({ ...offerPageData, policyHolder: person }));
            }
            return offerPageData;
        });

        return {
            userInput: {
                ...response,
                showMeldung: false
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        writeOfferBackJumpMarker(transitionDetails);
        return {payload: await updatePolicyHolderPageData(inputData.businessId, {
                policyHolder: inputData.userInput.policyHolder,
                politicallyExposedPerson: inputData.userInput.politicallyExposedPerson,
                custodian: inputData.userInput.custodian,
                accountThirdParty: inputData.userInput.accountThirdParty
            })};
    },
    render: (inputData, handleAction, updateApp, onError) => {
        return <PolicyHolderPage
            storeState={{
                policyHolder: inputData.userInput.policyHolder,

                nationalities: inputData.userInput.nationalities,
                divergingInsuredPerson: inputData.userInput.divergingInsuredPerson,
                custodian: inputData.userInput.custodian,
                accountThirdParty: inputData.userInput.accountThirdParty,
                messages: inputData.userInput.messages,
                showMeldung: inputData.userInput.showMeldung,
                update: values => updateApp(values)
            }}
            businessId={inputData.businessId}
            handleAction={handleAction}
            valueRanges={inputData.valueRanges}
        />;
    }
};
