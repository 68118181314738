import * as React from 'react';
import { Nationality, Person } from 'stg-common';
import { GwgOptions } from '../components/GwgQuestions/GwgQuestions';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';
import AbstractPersonPage from './AbstractPersonPage';

export interface PolicyHolderPageData extends GwgOptions, StoreStateUpdater<PolicyHolderPageData> {
    divergingInsuredPerson: boolean;
    policyHolder: Person;
    nationalities: Nationality[];
    showMeldung: boolean;
}

interface PolicyHolderPageProps extends PagePropsWithValues<PolicyHolderPageData> {
    businessId: string;
}

function PolicyHolderPage(props: PolicyHolderPageProps) {
    return <AbstractPersonPage
        {...props}
        storeState={{
            ...props.storeState.policyHolder,
            personId: props.storeState.policyHolder.personId!,
            divergingInsuredPerson: props.storeState.divergingInsuredPerson,
            politicallyExposedPerson: props.storeState.politicallyExposedPerson,
            custodian: props.storeState.custodian,
            vklm: props.storeState.vklm,
            accountThirdParty: props.storeState.accountThirdParty,
            nationalities: props.storeState.nationalities,
            showMeldung: props.storeState.showMeldung,
            update: props.storeState.update
        }}
        headline="Bitte geben Sie die Daten des Versicherungsnehmers ein:"
        headlineTooltip={<>Der Versicherungsnehmer ist Vertragspartner. Er
            kann, muss aber nicht selbst versichert sein. Die versicherte Person kann z. B. auch der Lebenspartner sein.
            Im Todesfall der versicherten Person wird die vereinbarte Leistung gezahlt.</>}
        componentPrefix="policy-holder"
        showDetailedMode={true}
        disableBirthday={false}
        isGwgRequired={true}
        trackPersonData={true}
        disableOfferNavigation={false}
    />;
}

export default PolicyHolderPage;
