import { Addresses } from 'stg-common';

export function mapAddressesToPrintableText(addresses?: Addresses): string | undefined {
    if (addresses === Addresses.MR) {
        return  'Herr';
    } else if (addresses === Addresses.MRS) {
        return 'Frau';
    }
    return undefined;
}

export function getAddressesFromStringValue(stringValue: string): Addresses | undefined {
    if (stringValue === Addresses.MR) {
        return Addresses.MR;
    } else if (stringValue === Addresses.MRS) {
        return Addresses.MRS;
    }
    return undefined;
}
