import { Person } from 'stg-common';
import { PersonInformations } from '../components/PersonalData';

export const mapPersonInformationsToPerson = (personInput: PersonInformations): Person => {
    return {
        anrede: personInput.anrede,
        vorname: personInput.vorname || '',
        nachname: personInput.nachname || '',
        birthdate: personInput.birthdate || '',
        adresse: {
            strasse: personInput.adresse ? personInput.adresse.strasse : '',
            hausnummer: personInput.adresse ? personInput.adresse.hausnummer : '',
            plz: personInput.adresse ? personInput.adresse.plz : '',
            ort: personInput.adresse ? personInput.adresse.ort : '',
            land: personInput.adresse ? personInput.adresse.land : 'Deutschland'
        },
        geburtsort: personInput.geburtsort,
        vklm: personInput.vklm || '',
        staatsangehoerigkeit: personInput.staatsangehoerigkeit,
        email: personInput.email,
        vorwahl: personInput.vorwahl,
        rufnummer: personInput.rufnummer
    };
};
