import { FormikErrors } from 'formik';
import { ValidationResponse, ValidationResponseType } from 'stg-common';
import { PersonInformations } from '../components/PersonalData';
import { updatePerson } from '../services/api';
import { cityErrorMessage, housenumberErrorMessage, postalCodeErrorMessage, streetErrorMessage } from '../validation/PersonalDataSchema';
import { mapPersonInformationsToPerson } from './personHelper';

type UpdatePersonInformationsCallback = (values: Partial<PersonInformations>, callback?: () => void) => void;

export const handleAddressValidation = (validationResponse: ValidationResponse[], validatedValues: PersonInformations,
                                        updateCallback: UpdatePersonInformationsCallback, businessId: string, personId: string,
                                        parentSetStateCallback: (values: object) => void,
                                        setFormikErrorsCallback: (value: FormikErrors<PersonInformations>) => void) => {
    if (validationResponse.length > 0 && validationResponse[0].responseType === ValidationResponseType.ADRESSE_UNGUELTIG) {
        handleIncorrectAddress(validationResponse[0], setFormikErrorsCallback);
        parentSetStateCallback({
            isCorrectedAddress: false,
            isAddressValid: false
        });

    } else if (validationResponse.length > 0 && validationResponse[0].responseType === ValidationResponseType.ADRESSE_KORRIGIERT) {
        if (validationResponse[0].correctedStreet && validationResponse[0].correctedHousenumber &&
            validationResponse[0].correctedPostal && validationResponse[0].correctedCity && validatedValues.adresse) {

            validatedValues.adresse.strasse = validationResponse[0].correctedStreet;
            validatedValues.adresse.hausnummer = validationResponse[0].correctedHousenumber;
            validatedValues.adresse.plz = validationResponse[0].correctedPostal;
            validatedValues.adresse.ort = validationResponse[0].correctedCity;
            updateCallback({
                adresse: {
                    strasse: validationResponse[0].correctedStreet,
                    hausnummer: validationResponse[0].correctedHousenumber,
                    plz: validationResponse[0].correctedPostal,
                    ort: validationResponse[0].correctedCity
                }
            });
            scrollToCorrectedAddress();
            updatePerson(businessId, mapPersonInformationsToPerson(validatedValues), personId).then().catch();
            parentSetStateCallback({
                isCorrectedAddress: true,
                isAddressValid: true
            });
        }
    } else if (validationResponse.length === 0) {
        parentSetStateCallback({
            isCorrectedAddress: false,
            isAddressValid: true
        });
    }
};

export const scrollToCorrectedAddress = () => {
    const addressElement = document.querySelector('[id="adresse.strasse"]') as HTMLElement;
    if (addressElement) {
        addressElement.scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
    }
};

export const handleIncorrectAddress = (validationResponse: ValidationResponse, setFormikErrorsCallback: (value: FormikErrors<PersonInformations>) => void) => {
    if (validationResponse.errorStreet === true) {
        setFormikErrorsCallback({adresse: {strasse: streetErrorMessage}});
    }
    if (validationResponse.errorHousenumber === true) {
        setFormikErrorsCallback({adresse: {hausnummer: housenumberErrorMessage}});
    }
    if (validationResponse.errorPostalCity === true) {
        setFormikErrorsCallback({adresse: {plz: postalCodeErrorMessage, ort: cityErrorMessage}});
    }
};
