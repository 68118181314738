import * as React from 'react';
import { IconProps } from './IconTypes';

export const SeebestattungIcon = (props: IconProps): JSX.Element => {
  return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
          d={`M5.79171546,11.9862849 C5.94310846,11.7813711 6.09706913,11.5534049 6.25327602,11.3017572 C6.55298965,10.8189216 
                                        7.27243176,10.8189216 7.57214538,11.3017572 C8.47075473,12.7494077 9.29502879,13.4133563 9.9837739,13.4133563 
                                        C10.672519,13.4133563 11.4967931,12.7494077 12.3954024,11.3017572 C12.695116,10.8189216 13.4145581,10.8189216 
                                        13.7142718,11.3017572 C13.8957464,11.594111 14.0741894,11.8545024 14.2490967,12.0839175 C15.0234986,10.3046413 
                                        15.4095171,8.95498987 15.4095171,8.05694119 C15.4095171,7.31690826 15.2418556,6.47067037 14.903772,5.52095975 
                                        L5.17374975,5.52095975 C4.80563432,6.52728997 4.62293506,7.37461094 4.62293506,8.05694119 C4.62293506,8.85302293 
                                        5.01246613,10.1724625 5.79171546,11.9862849 Z M4.62787088,13.1543366 C3.5973743,10.8567045 3.08199476,9.17111763 
                                        3.08199476,8.05694119 C3.08199476,6.47348974 3.80280301,4.39414171 5.22623277,1.77885783 C5.35980154,1.5334504 
                                        5.62174901,1.37998133 5.90705197,1.37998133 L7.96163904,1.37998133 L7.96163904,1 C7.96163904,0.44771525 
                                        8.40935429,2.39909626e-13 8.96163904,2.39808173e-13 L11.0708132,2.39808173e-13 C11.6230979,2.39484676e-13 
                                        12.0708132,0.44771525 12.0708132,1 L12.0708132,1.37998133 L14.1254002,1.37998133 C14.4027153,1.37998133 
                                        14.6586359,1.52505205 14.7954997,1.75983277 C16.2252036,4.21239584 16.9504574,6.30456837 16.9504574,8.05694119 
                                        C16.9504574,9.27690712 16.4421492,10.9837315 15.4287769,13.207566 C15.673574,13.346048 15.9066068,13.4133563 
                                        16.1259003,13.4133563 C16.8146454,13.4133563 17.6389195,12.7494077 18.5375288,11.3017572 C18.757592,10.9472376 
                                        19.2312275,10.8334994 19.5954232,11.0477159 C19.9596189,11.2619324 20.0764614,11.7229842 19.8563982,12.0775038 
                                        C18.7076321,13.9281543 17.4845306,14.9133563 16.1259003,14.9133563 C15.0239378,14.9133563 14.0111339,14.2652351 
                                        13.0548371,13.0328902 C12.0985403,14.2652351 11.0857364,14.9133563 9.9837739,14.9133563 C8.88181143,14.9133563 
                                        7.86900753,14.2652351 6.9127107,13.0328902 C5.95641388,14.2652351 4.94360997,14.9133563 3.84164751,14.9133563 
                                        C2.48301715,14.9133563 1.25991575,13.9281543 0.111149629,12.0775038 C-0.108913599,11.7229842 0.00792886947,11.2619324 
                                        0.372124588,11.0477159 C0.736320306,10.8334994 1.20995576,10.9472376 1.43001899,11.3017572 C2.32862834,12.7494077 
                                        3.1529024,13.4133563 3.84164751,13.4133563 C4.08739446,13.4133563 4.35039548,13.3288297 4.62787088,13.1543366 Z 
                                        M5.71218293,4.22095975 L14.364611,4.22095975 C14.1632811,3.79137575 13.9326323,3.34431863 13.6724695,2.87998133 
                                        L6.3742083,2.87998133 C6.12723229,3.34876469 5.90660486,3.7958625 5.71218293,4.22095975 Z M10.0162261,20.0038369 
                                        C8.91426364,20.0038369 7.90145973,19.3557157 6.9451629,18.1233708 C5.98886608,19.3557157 4.97606217,20.0038369 
                                        3.87409971,20.0038369 C2.51546935,20.0038369 1.29236795,19.018635 0.143601831,17.1679844 C-0.0764613976,16.8134648 
                                        0.0403810711,16.3524131 0.404576789,16.1381966 C0.768772508,15.9239801 1.24240797,16.0377182 1.46247119,16.3922378 
                                        C2.36108054,17.8398884 3.1853546,18.5038369 3.87409971,18.5038369 C4.56284482,18.5038369 5.38711888,17.8398884 
                                        6.28572822,16.3922378 C6.58544185,15.9094022 7.30488396,15.9094022 7.60459759,16.3922378 C8.50320693,17.8398884 
                                        9.32748099,18.5038369 10.0162261,18.5038369 C10.7049712,18.5038369 11.5292453,17.8398884 12.4278546,16.3922378 
                                        C12.7275682,15.9094022 13.4470103,15.9094022 13.746724,16.3922378 C14.6453333,17.8398884 15.4696074,18.5038369 
                                        16.1583525,18.5038369 C16.8470976,18.5038369 17.6713717,17.8398884 18.569981,16.3922378 C18.7900442,16.0377182 
                                        19.2636797,15.9239801 19.6278754,16.1381966 C19.9920711,16.3524131 20.1089136,16.8134648 19.8888504,17.1679844 
                                        C18.7400843,19.018635 17.5169828,20.0038369 16.1583525,20.0038369 C15.05639,20.0038369 14.0435861,19.3557157 
                                        13.0872893,18.1233708 C12.1309925,19.3557157 11.1181886,20.0038369 10.0162261,20.0038369 Z`}
          transform="translate(2.25 2)"/>
  </svg>);
};
