import * as React from 'react';
import { IconProps } from './IconTypes';

export const ErdbestattungIcon = (props: IconProps): JSX.Element => {
    return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <path
            d={`M17.4877449,17.1147491 L19.2146597,17.1147491 C19.6483912,17.1147491 20,17.4635555 20,17.8938301 L20,21.220919 
                                        C20,21.6511936 19.6483912,22 19.2146597,22 L0.785340314,22 C0.351608835,22 -4.61852778e-14,21.6511936 
                                        -4.61852778e-14,21.220919 L-4.61852778e-14,17.8938301 C-4.61852778e-14,17.4635555 0.351608835,17.1147491 
                                        0.785340314,17.1147491 L2.51225515,17.1147491 L2.51225515,7.70487588 C2.51225515,6.15981565 3.59172367,
                                        4.86486934 5.04330612,4.52175377 C5.25452404,1.98966033 7.39304019,3.73034936e-14 10,3.73034936e-14 
                                        C12.6069598,3.73034936e-14 14.745476,1.98966033 14.9566939,4.52175377 C16.4082763,4.86486934 
                                        17.4877449,6.15981565 17.4877449,7.70487588 L17.4877449,17.1147491 Z M16.7024045,18.6729111 L3.29759546,18.6729111 
                                        L1.57068063,18.6729111 L1.57068063,20.441838 L18.4293194,20.441838 L18.4293194,18.6729111 L16.7024045,18.6729111 
                                        Z M15.9170642,17.1147491 L15.9170642,7.70487588 C15.9170642,6.75827185 15.1435248,5.99089767 14.1893155,5.99089767 
                                        L14.1792797,5.99089767 C13.7252037,5.99089767 13.3657516,5.61001066 13.3956429,5.1605308 C13.4006335,5.08548503 
                                        13.4031414,5.01001151 13.4031414,4.9341797 C13.4031414,3.06965661 11.8795031,1.55816201 10,1.55816201 
                                        C8.12049692,1.55816201 6.59685864,3.06965661 6.59685864,4.9341797 C6.59685864,5.01001151 6.59936647,5.08548503 
                                        6.60435715,5.1605308 C6.63424837,5.61001066 6.27479629,5.99089767 5.82072028,5.99089767 L5.81068447,5.99089767 
                                        C4.85647521,5.99089767 4.08293578,6.75827185 4.08293578,7.70487588 L4.08293578,17.1147491 L15.9170642,17.1147491 Z`}
            transform="translate(2 .84)"/>
    </svg>);
};
