const decodeJWT = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return false;
  }
};

const getDecodeJWT = (token: string): string => (decodeJWT(token) ? token : '');

export const validateMakler = (token: string): boolean => !!getDecodeJWT(token);
