import * as React from 'react';
import { PAYMENT_METHOD_SINGLE_FEE } from '../../constants';
import BurialPackagePage, { BurialPackagePageData } from '../../pages/BurialPackagePage';
import { getOfferPageData, updateBurialPackagePageData } from '../../services/api';
import { mapBasketFields, mapBasketFieldsFormResponse, writeOfferBackJumpMarker } from '../routeHelper';
import { StateDefinition } from '../StateMachine';
import { CHECKOUT_PAGES, NavigationAction, PERSONAL_INFORMATION_PAGES, StateName } from '../StateMachineTypes';

export const burialPackagePage: StateDefinition<BurialPackagePageData> = {
    name: StateName.BURIAL_PACKAGE_PAGE,
    validInboundStates: [
        ...PERSONAL_INFORMATION_PAGES,
        ...CHECKOUT_PAGES,
        StateName.FEE_PAGE,
        StateName.FLEX_OPTION_PAGE,
        StateName.FEE_DYNAMIC_PAGE,
        StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE
    ],
    transitions: [
        {
            test: (action, data) => action === NavigationAction.NEXT && data.userInput.paymentMethod !== PAYMENT_METHOD_SINGLE_FEE,
            newState: StateName.FLEX_OPTION_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.NEXT && data.userInput.paymentMethod === PAYMENT_METHOD_SINGLE_FEE,
            newState: StateName.DIVERGING_INSURED_PERSON_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            newState: StateName.FEE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_FEE_PAGE,
            newState: StateName.FEE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_REQUEST_OFFER,
            newState: StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE
        },
        // BreadCrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'burialPackage');

        return {
            userInput: {
                ...mapBasketFieldsFormResponse(response),
                messages: response.messages
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        writeOfferBackJumpMarker(transitionDetails);
        await updateBurialPackagePageData(inputData.businessId, {
                burialPackage: inputData.userInput.burialPackage
            }
        );
        return {};
    },
    render: (inputData, handleAction, updateApp) => {
        return <BurialPackagePage
            storeState={{
                ...mapBasketFields(inputData.userInput),
                update: (values, callback) => updateApp(values, callback)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};
