import * as React from 'react';
import { BasketStoreItems, default as Basket } from '../components/Basket';
import DisclaimerInfoBox from '../components/DisclaimerInfoBox';
import { Footer } from '../components/Footer/Footer';
import { Headline } from '../components/Headline';
import SelectableCard from '../components/SelectableCard';
import { scrollToTop } from '../helpers/scrolling';
import { NavigationAction } from '../routing/StateMachineTypes';
import { trackElementClicked } from '../tracking/tracker';
import { TrackingElement } from '../tracking/trackingConstants';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';

export interface FeeDynamicPageData extends StoreStateUpdater<FeeDynamicPageData>, BasketStoreItems {
    feeDynamic?: boolean;
}

function FeeDynamicPage(props: PagePropsWithValues<FeeDynamicPageData>) {

    React.useEffect(() => {
        scrollToTop();
    }, []);

return (<>
        <Basket basketStore={props.storeState}
                valueRanges={props.valueRanges}
                handleAction={props.handleAction}
                hideFeeDynamicEdit={true}
                hideFeeDynamic={true}
                hideBurialPackage={true}
                hideFlexOption={true}
        />
        <Headline subLine="Wählen Sie eine Dynamik:">
            Soll sich die Absicherung der Zukunft anpassen?
        </Headline>
        <SelectableCard heading="Beitragsdynamik"
                        nextText="auswählen und weiter"
                        onClick={() => {
                            trackElementClicked(TrackingElement.Link_DynamikAuswaehlen);
                            props.storeState.update({feeDynamic: true}, () => props.handleAction(NavigationAction.NEXT));
                        }}
                        dataComponentId="select-fee-dynamic-button">
            <div>
                Die Lebenshaltungskosten steigen stetig. So verliert das Geld über die Zeit an Wert.
                Mit einer freiwilligen jährlichen Beitragserhöhung um 5 % wirken Sie diesem Effekt entgegen.
                Die Versicherungssumme steigt. So erhalten die Angehörigen zum Zeitpunkt der Auszahlung ein der Zeit
                angepasstes finanzielles Polster.
            </div>
            <br/>
            <div style={{fontWeight: 'bold'}}>
                Jede Erhöhung wird rechtzeitig angekündigt. Auf die Erhöhung kann auch verzichtet werden.
            </div>
        </SelectableCard>
        <SelectableCard heading="Ich möchte keine Beitragsdynamik."
                        nextText="weiter"
                        onClick={() => {
                            trackElementClicked(TrackingElement.Link_WeiterOhneDynamik);
                            props.storeState.update({feeDynamic: false}, () => props.handleAction(NavigationAction.NEXT));
                        }}
                        dataComponentId="no-fee-dynamic-button"/>

        <Footer
            handleAction={props.handleAction}
            hideNextButton={true}
        />
        <DisclaimerInfoBox/>
    </>);
}

export default FeeDynamicPage;
