import Input, { InputProps } from '@eg/elements/Input';
import * as React from 'react';
import { Key } from '../../helpers/Key';

class CurrencySliderInput extends React.Component<InputProps> {

    private readonly onChangeDecorator = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.keepCursorPosition(event);
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    };

    private readonly keepCursorPosition = (event: React.ChangeEvent<HTMLInputElement>) => {
        const cursor = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
            element.selectionStart = cursor;
            element.selectionEnd = cursor;
        });
    };

    public readonly keyDownCapture = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const isLeftCharDot = target.selectionStart && target.value.charAt(target.selectionStart - 1) === '.';
        const isRightCharDot = target.selectionStart && target.value.charAt(target.selectionStart) === '.';

        if (event.keyCode === Key.BACKSPACE && isLeftCharDot && target.selectionStart) {
            target.setSelectionRange(target.selectionStart - 1, target.selectionStart - 1);
        } else if (event.keyCode === Key.DELETE && isRightCharDot && target.selectionStart) {
            target.setSelectionRange(target.selectionStart + 1, target.selectionStart + 1);
        }
    };

    public render() {
        return (
            <Input
                {...this.props}
                onChange={this.onChangeDecorator}
                onKeyDownCapture={this.keyDownCapture}
            />);
    }
}

export default CurrencySliderInput;
