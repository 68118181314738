import { TextValue, ValueRanges } from 'stg-common';
import { number, object, Schema, string } from 'yup';
import { STEP_RANGE_SUM_INSURED } from '../constants';
import formatter, { EURO_WITHOUT_CENT } from '../helpers/currencyFormatter';
import { CustomMixedSchema } from './date';

export const getCommonFeePageParameter = (valueRanges: ValueRanges, includePaymentPeriod: boolean): Schema<{}> => {
    const paymentPeriodMinMaxError = `Die Beitragszahlungsdauer liegt online bei ${valueRanges.paymentPeriod.min} bis ${valueRanges.paymentPeriod.max}.
  Bitte korrigieren Sie die Angabe.`;
    let schema = object().shape({
        paymentMethod:
            string()
                .test('isValid', 'Diese Angabe ist leider nicht möglich. Bitte wählen Sie eine andere Option.',
                    value =>
                        !!valueRanges.paymentMethod.possibleValues.find((entry: TextValue) =>
                            entry.key === value)
                )
    });
    if (includePaymentPeriod) {
        schema = schema.concat(object().shape({
            paymentPeriod: (number() as CustomMixedSchema)
                .required('Bitte geben Sie die Beitragszahlungsdauer an.')
                .nullable(false)
                .min(valueRanges.paymentPeriod.min, paymentPeriodMinMaxError)
                .max(valueRanges.paymentPeriod.max, paymentPeriodMinMaxError)
        }));
    }
    return schema;
};

export function createFeePageSchemaWithSum(valueRanges: ValueRanges, includePaymentPeriod: boolean): Schema<{}> {
    const insuranceSum = valueRanges.insuranceSum;
    const currencyFormatter = formatter(EURO_WITHOUT_CENT);

    const sumInsuredMinMaxError = `Die Versicherungssumme liegt online bei ${currencyFormatter.format(insuranceSum.min)} bis ${currencyFormatter.format(insuranceSum.max)}.
  Bitte korrigieren Sie die Angabe.`;

    return getCommonFeePageParameter(valueRanges, includePaymentPeriod).concat(object().shape({
        insuranceSum: (number() as CustomMixedSchema)
            .required('Bitte geben Sie die Versicherungssumme an.')
            .nullable(false)
            .min(insuranceSum.min, sumInsuredMinMaxError)
            .max(insuranceSum.max, sumInsuredMinMaxError)
            .test('isFullFiveHundredEuro', 'Es können nur ganze fünfhundert Eurobeträge abgesichert werden.', value => value % STEP_RANGE_SUM_INSURED === 0)

    }));
}

export function createFeePageSchemaWithoutSum(valueRanges: ValueRanges, includePaymentPeriod: boolean): Schema<{}> {
    const insuranceFee = valueRanges.insuranceFee;
    const currencyFormatter = formatter(EURO_WITHOUT_CENT);

    const feeMinMaxError = `Der Versicherungsbeitrag liegt online bei ${currencyFormatter.format(insuranceFee.min)} bis ${currencyFormatter.format(insuranceFee.max)}.
  Bitte korrigieren Sie die Angabe.`;

    return getCommonFeePageParameter(valueRanges, includePaymentPeriod).concat(object().shape({
        insuranceFee: (number() as CustomMixedSchema)
            .required('Bitte geben Sie einen Beitrag an.')
            .nullable(false)
            .min(insuranceFee.min, feeMinMaxError)
            .max(insuranceFee.max, feeMinMaxError)
            .test('isMatchingIncrement', `Es können nur ganze ${valueRanges.insuranceFee.increment} Eurobeträge abgesichert werden.`,
                value => value % valueRanges.insuranceFee.increment! === 0)
    }));
}
