import Select from '@eg/elements/components/Select';
import Input from '@eg/elements/Input';
import React from 'react';
import './Dropdown.css';

export interface DropdownOption<T = string> {
  label: string;
  value: T;
}

export interface DropdownProps<T = string> {
  id: string;
  className?: string;
  value?: string;
  options: Array<DropdownOption<T>>;
  label: string;
  display?: 'inline' | 'col';
  onChange: (value: string | undefined) => void;
  placeholder?: string;
  readonly?: boolean;
  selectOptionLabel?: string;
  errors?: string[];
  children?: React.ReactNode;
  dataTestId?: string;
}

const DropDown = ({
  children,
  id,
  value,
  className = '',
  options,
  label,
  display = 'col',
  onChange,
  selectOptionLabel = 'bitte auswählen',
  placeholder,
  readonly = false,
  errors,
  dataTestId = 'dropdown'
}: DropdownProps): JSX.Element => {
  const hasErrors = errors && errors.length;
  const displayClassName = `dropdown--${display}`;

  const handleOnChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    let newValue: string | undefined = event.target.value;

    if (newValue === 'default') {
        newValue = undefined;
    }

    onChange(newValue);
  };

  return (
    <div
      className={`dropdown ${displayClassName} ${className}`}
      data-testid={dataTestId}
    >
      <div className="dropdown__labelWrapper">
        <label
          className="dropdown__label"
          aria-label={`${id}-select`}
          htmlFor={id}
          data-testid={`${dataTestId}-label`}
        >
          {label}
        </label>

        {!!children && (
          <div className="dropdown__labelTooltip">
            {children}
          </div>
        )}
      </div>

      <div className="dropdown__selectWrapper">
        {readonly && (
          <Input
            className="dropdown__input"
            id={id}
            readOnly={true}
            value={value}
            placeholder={placeholder}
            type="text"
            data-testid={`${dataTestId}-textbox`}
          />
        )}

        {!readonly && (
          <Select
            className="dropdown__select"
            id={id}
            defaultValue={value}
            onChange={handleOnChange}
            error={hasErrors}
            placeholder={placeholder}
            data-testid={`${dataTestId}-select`}
          >
            {
              <>
                <option hidden value="default">
                  {selectOptionLabel}
                </option>
                {options && options.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </>
            }
          </Select>
        )}

        {errors && errors.length && (
          <ul className="dropdown__errors" data-testid={`${dataTestId}-errors`}>
            {errors.map(error => (
              <li key={error} className="dropdown__error">
                {error}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default DropDown;
