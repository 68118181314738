import { Button } from '@eg/elements/Button';
import { Card } from '@eg/elements/Card';
import { ComparisonTable } from '@eg/elements/ComparisonTable';
import { CheckIllustratedIcon, TimesThinIcon } from '@eg/elements/components/Icons';
import { Modal } from '@eg/elements/Modal';
import * as React from 'react';
import { BurialPackageIdentifier } from 'stg-common';
import { BasketStoreItems, default as Basket } from '../components/Basket';
import BurialPackage from '../components/BurialPackage';
import DisclaimerInfoBox from '../components/DisclaimerInfoBox';
import { Footer } from '../components/Footer/Footer';
import { Headline } from '../components/Headline';
import {
    ErdbestattungIcon,
    ExklusivesBestattungspaketIcon,
    FeuerbestattungIcon,
    KlassischesBestattungspaketIcon,
    SeebestattungIcon,
    TraditionellesBestattungspaketIcon,
    WaldbestattungIcon
} from '../components/icons';
import SelectableCard from '../components/SelectableCard';
import { scrollToTop } from '../helpers/scrolling';
import { extractSelectedVariant } from '../helpers/variants';
import { NavigationAction } from '../routing/StateMachineTypes';
import { trackElementClicked, trackElementClickImmediate } from '../tracking/tracker';
import { TrackingElement } from '../tracking/trackingConstants';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';

export interface BurialPackagePageData extends StoreStateUpdater<BurialPackagePageData>, BasketStoreItems {
}

interface BurialPackagePageProps extends PagePropsWithValues<BurialPackagePageData> {
}

interface BurialPackagePageState {
    showDetailsModal: boolean;
}

class BurialPackagePage extends React.Component<BurialPackagePageProps, BurialPackagePageState> {

    constructor(props: Readonly<BurialPackagePageProps>) {
        super(props);
        this.state = {showDetailsModal: false};
    }

    public componentDidMount() {
        scrollToTop();
    }

    public render() {
        const imageSizeHeight = 40;
        const costGraveSimple = 1000;
        const costGraveTraditional = 1500;
        const costGraveFancy = 2700;
        const burialTypeIconSize = 32;

        const variant = extractSelectedVariant(this.props.storeState.variants);

        return (<>
            <Basket basketStore={this.props.storeState}
                    valueRanges={this.props.valueRanges}
                    handleAction={this.props.handleAction}
                    hideFeeDynamicEdit={true}
                    hideFlexOptionEdit={true}
                    hideBurialOptionEdit={true}
                    hideFlexOption={true}
                    hideFeeDynamic={true}
                    hideBurialPackage={true}
            />
            <Headline subLine={'Dann wählen Sie ein Bestattungspaket:'}>
                Möchten Sie schon heute die Bestattung regeln?
            </Headline>
            <BurialPackage burialPackageType="Klassischer"
                           ceremony="Ohne Trauerfeier"
                           arrangement="Abschied im kleinen Kreis"
                           casketType="Einfacher"
                           cost={costGraveSimple}
                           icon={<KlassischesBestattungspaketIcon height={imageSizeHeight}/>}
                           onClick={() => {
                               trackElementClicked(TrackingElement.Link_BestattKlassAuswaehlen);
                               this.props.storeState.update(
                                   {burialPackage: BurialPackageIdentifier.KLASSISCH},
                                   () => this.props.handleAction(NavigationAction.NEXT)
                               );
                           }}
                           variant={variant}
                           minimumInsuranceSum={this.getRequiredSumInsuredForBurialPackage(BurialPackageIdentifier.KLASSISCH)}
                           dataComponentId="classic-burial-package-button"/>
            <BurialPackage burialPackageType="Traditioneller"
                           ceremony="Trauerfeier im Familien- und Freundeskreis"
                           arrangement="Passende Ausgestaltung, z. B. mit Redner, Musik und Blumen"
                           casketType="Normaler"
                           cost={costGraveTraditional}
                           icon={<TraditionellesBestattungspaketIcon height={imageSizeHeight}/>}
                           onClick={() => {
                               trackElementClicked(TrackingElement.Link_BestattTradAuswaehlen);
                               this.props.storeState.update(
                                   {burialPackage: BurialPackageIdentifier.TRADITIONELL},
                                   () => this.props.handleAction(NavigationAction.NEXT)
                               );
                           }}
                           variant={variant}
                           minimumInsuranceSum={this.getRequiredSumInsuredForBurialPackage(BurialPackageIdentifier.TRADITIONELL)}
                           dataComponentId="traditionell-burial-package-button"/>
            <BurialPackage burialPackageType="Exklusiver"
                           ceremony="Trauerfeier im großen Rahmen"
                           arrangement="Hochwertige Ausgestaltung, z. B. mit Redner, Musik und Blumen"
                           casketType="Hochwertiger"
                           cost={costGraveFancy}
                           icon={<ExklusivesBestattungspaketIcon height={imageSizeHeight}/>}
                           onClick={() => {
                               trackElementClicked(TrackingElement.Link_BestattExklAuswaehlen);
                               this.props.storeState.update(
                                   {burialPackage: BurialPackageIdentifier.EXKLUSIV},
                                   () => this.props.handleAction(NavigationAction.NEXT)
                               );
                           }}
                           variant={variant}
                           minimumInsuranceSum={this.getRequiredSumInsuredForBurialPackage(BurialPackageIdentifier.EXKLUSIV)}
                           dataComponentId="exclusiv-burial-package-button"/>
            <SelectableCard heading="Ich möchte kein Bestattungspaket"
                            nextText="weiter"
                            onClick={() => {
                                trackElementClicked(TrackingElement.Link_WeiterOhneBestatt);
                                this.props.storeState.update(
                                    {burialPackage: BurialPackageIdentifier.KEIN},
                                    () => this.props.handleAction(NavigationAction.NEXT)
                                );
                            }}
                            dataComponentId="no-burial-package-button">
                <div>
                    Die Angehörigen sollen die volle Versicherungssumme bekommen.
                </div>
            </SelectableCard>

            <div className="esc_grid__wrapper" style={{textAlign: 'center', margin: '2em 0'}}>
                <div className="esc_col esc_col-12 esc_col-m-12">
                    <Button width="auto" variant="tertiary" size="large"
                            onClick={() => {
                                trackElementClickImmediate(TrackingElement.Link_BestattungLaAuswaehlen);
                                this.setState({showDetailsModal: true});
                            }}
                            data-component-id="open-modal-dialog-burial-package">
                        Alle Leistungen anzeigen und vergleichen
                    </Button>
                    {this.renderBurialPackageDetailsModal()}
                </div>
            </div>

            <div className="esc_grid__wrapper">
                <div className="esc_col esc_col-12 esc_col-m-12">
                    <Card label="Tipp:">
                        <b>Mit diesen Kosten müssen Sie ungefähr rechnen:</b>
                        <div className="esc_grid__wrapper" style={{marginTop: '0', fontSize: '14px'}}>
                            <div className="esc_col esc_col-12 esc_col-l-3 esc_col-m-6">
                                <ErdbestattungIcon height={burialTypeIconSize} width={burialTypeIconSize} style={{float: 'left'}}/>

                                <div style={{lineHeight: '20px', marginLeft: '40px'}}>
                                    <b>Erdbestattung</b>
                                    <br/>
                                    8.000 €
                                </div>
                            </div>
                            <div className="esc_col esc_col-12 esc_col-l-3 esc_col-m-6">
                                <FeuerbestattungIcon height={burialTypeIconSize} width={burialTypeIconSize} style={{float: 'left'}}/>
                                <div style={{lineHeight: '20px', marginLeft: '40px'}}>
                                    <b>Feuerbestattung</b>
                                    <br/>
                                    6.000 €
                                </div>
                            </div>
                            <div className="esc_col esc_col-12 esc_col-l-3 esc_col-m-6">
                                <SeebestattungIcon height={burialTypeIconSize} width={burialTypeIconSize} style={{float: 'left'}}/>
                                <div style={{lineHeight: '20px', marginLeft: '40px'}}>
                                    <b>Seebestattung</b>
                                    <br/>
                                    4.000 €
                                </div>
                            </div>
                            <div className="esc_col esc_col-12 esc_col-l-3 esc_col-m-6">
                                <WaldbestattungIcon height={burialTypeIconSize} width={burialTypeIconSize} style={{float: 'left'}}/>
                                <div style={{lineHeight: '20px', marginLeft: '40px'}}>
                                    <b>Waldbestattung</b>
                                    <br/>
                                    4.000 €
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                {this.createBurialPackageReason(`Den Angehörigen werden mit einem Bestattungspaket schwere Entscheidungen abgenommen. 
                    Denn dadurch ist alles geregelt.`)}
                {this.createBurialPackageReason(`Das Bezugsrecht wird auf einen ausgewählten Bestatter übertragen, 
                    der dann direkt von der Versicherung bezahlt wird.`)}
                {this.createBurialPackageReason(`Die restliche Versicherungssumme bekommen die Angehörigen.`)}
                {this.createBurialPackageReason(`Als Paket sind die Leistungen 20 % günstiger als einzeln.`)}
                {this.createBurialPackageReason(`Bei der Wahl eines Bestattungspakets ist die Zahlung der gebuchten Leistungen automatisch geregelt. 
                Hierzu geht das Bezugsrecht automatisch an unseren Partner Anternia GmbH.`)}

            </div>
            <Footer handleAction={this.props.handleAction} hideNextButton={true}/>
            <DisclaimerInfoBox/>

        </>);
    }

    private readonly createBurialPackageReason = (reason: string) => {
        const logoSize = 30;
        return (<>
            <div className="esc_col esc_col-2 esc_col-m-1" style={{textAlign: 'right'}}>
                <CheckIllustratedIcon width={logoSize} height={logoSize}/>
            </div>
            <div className="esc_col esc_col-10 esc_col-m-11 grid-cell-vertical-centered" style={{textAlign: 'left'}}>
                {reason}
            </div>
        </>);
    };

    private readonly getRequiredSumInsuredForBurialPackage = (burialPackageIdentifier: BurialPackageIdentifier) => {
        if (this.props.valueRanges.burialPackages) {
            const burialPackage = this.props.valueRanges.burialPackages.find(
                bp => bp.identifier === burialPackageIdentifier
            );
            if (burialPackage) {
                return burialPackage.requiredSumInsured;
            }
        }
        return 0;
    };

    private renderBurialPackageDetailsModal() {
        const NUMBER_OF_COLUMNS = 3;
        return (
            <Modal
                open={this.state.showDetailsModal}
                extendedWidth={true}
                dismissible={true}
                onDismiss={() => {
                    trackElementClickImmediate(TrackingElement.Button_SchliessenX);
                    this.setState({showDetailsModal: false});
                }}
            >
                <h1>Bestattungspakete</h1>
                <ComparisonTable>
                    <thead>
                    <tr>
                        <th/>
                        <th>
                            <strong>Klassisch</strong>
                        </th>
                        <th>
                            <strong>Traditionell</strong>
                        </th>
                        <th>
                            <strong>Exklusiv</strong>
                        </th>
                    </tr>

                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">
                            Versicherungssumme min.
                        </th>
                        <td>
                            <div className="ee_comparison-table__highlight">5.000 €</div>
                        </td>
                        <td>
                            <div className="ee_comparison-table__highlight">7.500 €</div>
                        </td>
                        <td>
                            <div className="ee_comparison-table__highlight">10.000 €</div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Ausgestaltung der Trauerfeier
                        </th>
                        <td valign="top">
                            <div style={{textAlign: 'left'}}>
                                <ul>
                                    <li>Ohne Trauerfeier</li>
                                    <li>Abschied im kleinen Kreis</li>
                                </ul>
                            </div>
                        </td>
                        <td valign="top">
                            <div style={{textAlign: 'left'}}>
                                <ul>
                                    <li>Trauerfeier im Familien- und Freundeskreis</li>
                                    <li>Passende Ausgestaltung, z. B. mit Redner, Musik und Blumen</li>
                                    <li>Bis zu 100 Trauerkarten</li>
                                </ul>
                            </div>
                        </td>
                        <td valign="top">
                            <div style={{textAlign: 'left'}}>
                                <ul>
                                    <li>Trauerfeier im großen Rahmen</li>
                                    <li>Hochwertige Ausgestaltung, z. B. mit Redner und Musik</li>
                                    <li>Hochwertiger Blumenschmuck und Trauerkranz</li>
                                    <li>Bis zu 100 Trauerkarten</li>
                                    <li>Regionale Traueranzeige</li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Erdbestattung
                        </th>
                        <td valign="top">
                            <div style={{textAlign: 'left'}}>
                                <ul>
                                    <li>Einfacher Sarg</li>
                                    <li>Überführung innerhalb des Stadtgebietes</li>
                                    <li>1.000 € für weitere Kosten wie z. B. Grabgebühren</li>
                                </ul>
                            </div>
                        </td>
                        <td valign="top">
                            <div style={{textAlign: 'left'}}>
                                <ul>
                                    <li>Normaler Sarg</li>
                                    <li>Überführung innerhalb der Region</li>
                                    <li>1.500 € für weitere Kosten wie z. B. Grabgebühren</li>
                                </ul>
                            </div>
                        </td>
                        <td valign="top">
                            <div style={{textAlign: 'left'}}>
                                <ul>
                                    <li>Hochwertiger Sarg</li>
                                    <li>Überführung innerhalb Deutschlands</li>
                                    <li>2.700 € für weitere Kosten wie z. B. Grabgebühren</li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Feuerbestattung
                        </th>
                        <td valign="top">
                            <div style={{textAlign: 'left'}}>
                                <ul>
                                    <li>Einfache Urne</li>
                                    <li>1.000 € für z. B. Grabgebühren</li>
                                </ul>
                            </div>
                        </td>
                        <td valign="top">
                            <div style={{textAlign: 'left'}}>
                                <ul>
                                    <li>Normale Urne</li>
                                    <li>1.500 € für z. B. Grabgebühren</li>
                                </ul>
                            </div>
                        </td>
                        <td valign="top">
                            <div style={{textAlign: 'left'}}>
                                <ul>
                                    <li>Hochwertige Urne</li>
                                    <li>2.700 € für z. B. Grabgebühren</li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Waldbestattung
                        </th>
                        <td valign="top">
                            <div style={{textAlign: 'left'}}>
                                <ul>
                                    <li>Standard Natururne</li>
                                    <li>Inkl. Basis-Baumgrabplatz</li>
                                </ul>
                            </div>
                        </td>
                        <td valign="top">
                            <div style={{textAlign: 'left'}}>
                                <ul>
                                    <li>Hochwertige Natururne</li>
                                    <li>Inkl. Wahl-Baumgrabplatz</li>
                                </ul>
                            </div>
                        </td>
                        <td valign="top">
                            <div style={{textAlign: 'left'}}>
                                <ul>
                                    <li>Premium Natururne</li>
                                    <li>Inkl. Partnerbaum bis zu 2.700 €</li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Seebestattung (Nord- oder Ostsee)
                        </th>
                        <td valign="top">
                            <div style={{textAlign: 'left'}}>
                                <ul>
                                    <li>Einfache Seeurne</li>
                                    <li>Trauerfahrt mit bis zu 14 Personen</li>
                                </ul>
                            </div>
                        </td>
                        <td valign="top">
                            <div style={{textAlign: 'left'}}>
                                <ul>
                                    <li>Normale Seeurne</li>
                                    <li>Trauerfahrt mit bis zu 40 Personen</li>
                                </ul>
                            </div>
                        </td>
                        <td valign="top">
                            <div style={{textAlign: 'left'}}>
                                <ul>
                                    <li>Hochwertige Seeurne</li>
                                    <li>Trauerfahrt mit bis zu 60 Personen</li>
                                    <li>Verköstigung an Bord</li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Diese Leistungen sind deutschlandweit im Preis enthalten
                        </th>
                        <td colSpan={NUMBER_OF_COLUMNS}>
                            <div style={{textAlign: 'left'}}>
                                Abholung und Versorgung der/des Verstorbenen, Kühlung ab Abholung, Sargträger, Sarg, Sterbegewand, Sarginnenausstattung
                                (inkl. Decke und Kissen), Gebühren für das Krematorium (nicht bei Erdbestattung), 2. Leichenschau durch einen Amtsarzt
                                (nicht bei Erdbestattung), Abmeldung bei der Renten- und Krankenkasse, Sterbeurkunde, Unterstützung Beantragung
                                Hinterbliebenenrente und Rentenvorschuss, Verwaltungsformalitäten (Krankenhaus, Meldeamt, Standesamt)
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </ComparisonTable>
                <Button
                    style={{float: 'right'}}
                    onClick={() => {
                        trackElementClickImmediate(TrackingElement.Button_Schliessen);
                        this.setState({showDetailsModal: false});
                    }}
                    iconLeft={TimesThinIcon}
                    data-component-id="close-modal-dialog-burial-package"
                    size="large"
                >
                    schließen
                </Button>
            </Modal>
        );
    }
}

export default BurialPackagePage;
