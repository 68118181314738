export const EURO_WITHOUT_CENT: number = 0;
export const EURO_WITH_CENT: number = 2;
const formatter = (fractionDigits: number) => new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
});

export const getFormattedValueWithoutEuroSign = (value?: number) => {
    if (value !== undefined) {
        const formattedValue = formatter(EURO_WITHOUT_CENT).format(value);
        return formattedValue.replace('€', '').trim();
    }
    return value;
};

export default formatter;
