import * as React from 'react';
import RequestOfferPolicyHolderPage, { RequestOfferPolicyHolderPageData } from '../../../pages/RequestOfferPages/RequestOfferPolicyHolderPage';
import { getOfferPageData, getPerson, updatePolicyHolderPageData } from '../../../services/api';
import { createOfferNavigation } from '../../routeHelper';
import { StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

const validInboundStates = [
    StateName.REQUEST_OFFER_INSURED_PERSON_PAGE,
    StateName.DIVERGING_INSURED_PERSON_PAGE
];

export const requestOfferPolicyHolderPage: StateDefinition<RequestOfferPolicyHolderPageData> = {
    name: StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE,
    validInboundStates,
    transitions: [
        // Breadcrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        },
        // Navigation
        {
            test: (action, data) => action === NavigationAction.NEXT,
            newState: StateName.REQUEST_OFFER_INSURED_PERSON_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            resolveNewState: createOfferNavigation(StateName.REQUEST_OFFER_INSURED_PERSON_PAGE, validInboundStates),
            newState: StateName.REQUEST_OFFER_INSURED_PERSON_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'policyHolder').then(offerPageData => {
            if (offerPageData.policyHolderId) {
                return getPerson(inputData.businessId, offerPageData.policyHolderId)
                    .then(person => ({ ...offerPageData, policyHolder: person }));
            }
            return offerPageData;
        });

        return {
            userInput: {
                ...response,
                showMeldung: false
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        return {payload: await updatePolicyHolderPageData(inputData.businessId, inputData.userInput)};
    },
    render: (inputData, handleAction, updateApp, onError) => {
        return <RequestOfferPolicyHolderPage
            storeState={{
                policyHolder: inputData.userInput.policyHolder,
                nationalities: inputData.userInput.nationalities,
                divergingInsuredPerson: inputData.userInput.divergingInsuredPerson,
                custodian: inputData.userInput.custodian,
                accountThirdParty: inputData.userInput.accountThirdParty,
                messages: inputData.userInput.messages,
                showMeldung: inputData.userInput.showMeldung,
                update: values => updateApp(values)
            }}
            businessId={inputData.businessId}
            handleAction={handleAction}
            valueRanges={inputData.valueRanges}
        />;
    }
};
