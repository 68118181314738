import { AngebotResponse } from 'stg-common';
import { BasketStoreItems } from '../components/Basket';
import { SESSION_STORAGE_OFFER_BACK_STATE, Storage } from '../helpers/Storage';
import { StateDefinition, TransitionDetails } from './StateMachine';
import { NavigationAction, StateName } from './StateMachineTypes';

export const mapBasketFields = (userInput: BasketStoreItems) => {
    return {
        paymentMethod: userInput.paymentMethod,
        variants: userInput.variants,
        burialPackage: userInput.burialPackage,
        paymentPeriod: userInput.paymentPeriod,
        flexOption: userInput.flexOption,
        feeDynamic: userInput.feeDynamic,
        insuranceSum: userInput.insuranceSum
    };
};

export const mapBasketFieldsFormResponse = (response: AngebotResponse) => {
    return {
        paymentMethod: response.paymentMethod,
        variants: response.varianten,
        paymentPeriod: response.paymentPeriod,
        insuranceSum: response.insuranceSum,
        flexOption: response.flexOption,
        feeDynamic: response.feeDynamic,
        burialPackage: response.burialPackage
    };
};

export const writeOfferBackJumpMarker = (transitionDetails: TransitionDetails) => {
    if (transitionDetails.targetStateName === StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE
        || transitionDetails.targetStateName === StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE
        || transitionDetails.targetStateName === StateName.REQUEST_OFFER_INSURED_PERSON_PAGE
        || transitionDetails.targetStateName === StateName.REQUEST_OFFER_CHECKOUT_PAGE) {
        Storage.writeItem(SESSION_STORAGE_OFFER_BACK_STATE, transitionDetails.sourceStateName);
    }
};

export const createOfferNavigation = (defaultState: StateName, validInboundStates: StateName[]) => {
    // tslint:disable-next-line:no-any
    return (action: NavigationAction, inputData: any): StateName => {
        const navigation = Storage.readItem(SESSION_STORAGE_OFFER_BACK_STATE);
        if (navigation && validInboundStates.find(s => s === navigation)) {
            return navigation as StateName;
        }
        return defaultState;
    };
};

// tslint:disable-next-line:no-any
export const resolveNewStateName = (currentState: StateDefinition<any>, action: NavigationAction, inputData: any): StateName | undefined => {
    for (const transition of currentState.transitions) {
        if (transition.test(action, inputData)) {
            return transition.resolveNewState ? transition.resolveNewState(action, inputData.userInput) : transition.newState;
        }
    }
    return undefined;
};
