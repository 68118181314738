import { Provider as EEProvider, THEME } from '@eg/elements/Provider';
import '@eg/elements/styles/ergo-one/core.css';
import '@eg/elements/styles/ergo-one/styles.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './components/App';
import trackJsConfig from './configuration/trackjs';
import { getConfigVariable } from './helpers/configurableVariables';
import { Configuration } from './helpers/Configuration';
import { trackJs } from './helpers/externalScripts';

const rootElement: HTMLElement = document.getElementById('stg-root') as HTMLElement;

// Disable AEM Spinner
const spinner = document.getElementById('ed_tarifspinner');
if (spinner && spinner.parentNode) {
    spinner.parentNode.removeChild(spinner);
}

// needed e.g. in the personal data page, this could be handled by the context api
export const configuration: Configuration = {
    theme: getConfigVariable('theme') as THEME || THEME.ergoone
};

if (trackJsConfig) {
    // tslint:disable-next-line:no-any
    (window as any)._trackJs = trackJsConfig;
    // tslint:disable-next-line:no-empty
    trackJs.catch(() => {});
}

ReactDOM.render(
    <EEProvider theme={configuration.theme}>
        <App />
    </EEProvider>,
    rootElement
);
