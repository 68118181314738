import {
    getAbschlussFeedback,
    getAbschlussPruefen,
    getAngebotFeedback,
    getBasisdaten,
    getBasisdatenBeitragFestlegen,
    getBasisdatenBeitragsLZ,
    getBasisdatenVersBeginn,
    getBasisdatenVersSumFestlegen,
    getBeratung,
    getBerechnungsergebnis,
    getBerechnungsergebnisBeitragsDynamik,
    getBerechnungsergebnisBestattungAuswahl,
    getBerechnungsergebnisFlexOption,
    getBrokerModus,
    getPersonendaten,
    getPersonendatenBankdaten,
    getPersonendatenBezugsrechtAuswahl,
    getPersonendatenVN,
    getPersonendatenVNAngebot,
    getPersonendatenVpAuswahl,
    getPersonendatenVpAuswahlAngebot,
    getPruefenUndSenden,
    getPruefenUndSendenAngebot,
    getPruefenUndSendenIDD,
    getPruefenUndSendenSchweigepflicht,
} from '../helpers/aemHelper';
import { getConfigVariable } from '../helpers/configurableVariables';

import { getQueryValue } from '../helpers/QueryParameterExtractor';
import { TRACKING_VERSION } from '../releaseVersion';
import { StateName } from '../routing/StateMachineTypes';

export const mapPageData = (
  statename?: StateName,
  isMakler?: boolean,
  isTiedAgent?: boolean,
  oenrNumber?: string,
  pnrNumber?: string
) => {
  const miscellaneous = isMakler || isTiedAgent
    ? {
        agency: {
          oenrNumber,
          pnrNumber
        }
      }
    : {};

  return {
    page: {
      attributes: { displayedBrand: 'ERGO', brokerModus: getBrokerModus },
      pageInfo: {
        sysEnv: getSysEnv(),
        issueDate: getBuildTime(),
        pageName: getTrackingPageName(statename)
      },
      category: {
        pagePurpose: 'Sale',
        primaryCategory: 'sterbe2019'
      }
    },
    miscellaneous
  };
};

const getBuildTime = (): string => {
  return `OTRsterbe2019|${TRACKING_VERSION}`;
};

const getSysEnv = () => {
  return getQueryValue('adh_internet') || window.location.host;
};

export const getTrackingPageName = (statename?: StateName) => {
  const pageName = mapPageName(statename);
  const trackingKuerzel = getConfigVariable('trackingKuerzel');
  const trackingName = getConfigVariable('trackingName' + pageName);

  if (trackingKuerzel && trackingName && statename) {
    return `${trackingKuerzel}:${trackingName}`;
  } else {
    return `sterbe:${pageName}`;
  }
};

const mapPageName = (statename?: StateName) => {
  switch (statename) {
    case StateName.INTRODUCTION_PAGE:
      return getBeratung;
    case StateName.BIRTHDATE_PAGE:
      return getBasisdaten;
    case StateName.INSURANCE_START_PAGE:
      return getBasisdatenVersBeginn;
    case StateName.INSURANCE_SUM_PAGE:
      return getBasisdatenVersSumFestlegen;
    case StateName.INSURANCE_FEE_PAGE:
      return getBasisdatenBeitragFestlegen;
    case StateName.PAYMENT_PERIOD_PAGE:
      return getBasisdatenBeitragsLZ;
    case StateName.FEE_PAGE:
      return getBerechnungsergebnis;
    case StateName.FEE_PAGE_INVALID:
      // TODO: Review field in AEM, doesn't look that exists
      return 'BerechnungsergebnisFehler';
    case StateName.BURIAL_PACKAGE_PAGE:
      return getBerechnungsergebnisBestattungAuswahl;
    case StateName.FLEX_OPTION_PAGE:
      return getBerechnungsergebnisFlexOption;
    case StateName.FEE_DYNAMIC_PAGE:
      return getBerechnungsergebnisBeitragsDynamik;
    case StateName.DIVERGING_INSURED_PERSON_PAGE:
      return getPersonendatenVpAuswahl;
    case StateName.POLICY_HOLDER_PAGE:
      return getPersonendatenVN;
    case StateName.INSURED_PERSON_PAGE:
      return getPersonendaten;
    case StateName.ENTITLED_PERSONS_PAGE:
      return getPersonendatenBezugsrechtAuswahl;
    case StateName.BANKING_DETAILS_PAGE:
      return getPersonendatenBankdaten;
    case StateName.INSURANCE_SUMMARY_PAGE:
      return getPruefenUndSenden;
    case StateName.CONFIDENTIALITY_RELEASE_PAGE:
      return getPruefenUndSendenSchweigepflicht;
    case StateName.INSURANCE_DOCUMENTS_PAGE:
      return getPruefenUndSendenIDD;
    case StateName.FEEDBACK_PAGE:
      return getAbschlussFeedback;
    case StateName.FAKE_PRUEFE_SENDEN_PAGE:
      return getAbschlussPruefen;
    case StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE:
      return getPersonendatenVpAuswahlAngebot;
    case StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE:
      return getPersonendatenVNAngebot;
    case StateName.REQUEST_OFFER_INSURED_PERSON_PAGE:
    case StateName.REQUEST_OFFER_CHECKOUT_PAGE:
      return getPruefenUndSendenAngebot;
    case StateName.REQUEST_OFFER_FEEDBACK_PAGE:
      return getAngebotFeedback;
    default:
      return undefined;
  }
};
