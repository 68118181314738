import { Button } from '@eg/elements/Button';
import { Card } from '@eg/elements/Card';
import { EditIcon } from '@eg/elements/components/Icons';
import { MessageBox } from '@eg/elements/MessageBox';
import * as React from 'react';
import { BurialPackageIdentifier, EntitledPerson, IsoDateString, mapToGermanDate, Person, Variante } from 'stg-common';
import Basket, { BasketStoreItems } from '../components/Basket';
import DisclaimerInfoBox from '../components/DisclaimerInfoBox';
import { Footer } from '../components/Footer/Footer';
import { Headline } from '../components/Headline';
import { mapAddressesToPrintableText } from '../helpers/addressesHelper';
import { scrollToTop } from '../helpers/scrolling';
import { extractSelectedVariant } from '../helpers/variants';
import { NavigationAction } from '../routing/StateMachineTypes';
import { trackElementClicked } from '../tracking/tracker';
import { TrackingElement } from '../tracking/trackingConstants';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';

export interface InsuranceSummaryPageData extends StoreStateUpdater<InsuranceSummaryPageData>, BasketStoreItems {
    insuranceBegin?: IsoDateString;
    insuranceFee?: number;
    feeDynamic?: boolean;
    flexOption?: boolean;
    variants: Variante[];
    iban?: string;
    bic?: string;
    entitledPersons?: EntitledPerson[];
    paymentPeriod: number;
    burialPackage: BurialPackageIdentifier;
    divergingInsuredPerson: boolean;
    insuredPerson: Person;
    policyHolder?: Person;
}

interface InsuranceSummaryPageProps extends PagePropsWithValues<InsuranceSummaryPageData> {
}

interface InsuranceSummaryPageState {
    isOpenInsuranceDetails: boolean;
    isOpenPersonalData: boolean;
    isOpenEntitlement: boolean;
    isOpenBankData: boolean;
}

class InsuranceSummaryPage extends React.Component<InsuranceSummaryPageProps, InsuranceSummaryPageState> {

    constructor(props: Readonly<InsuranceSummaryPageProps>) {
        super(props);
        this.state = {
            isOpenInsuranceDetails: false,
            isOpenPersonalData: false,
            isOpenEntitlement: false,
            isOpenBankData: false
        };
    }

    public componentDidMount() {
        scrollToTop();
    }

    public render() {
        return (<>
            {this.renderPageHeading()}
            <hr style={{margin: '3em 0', borderTop: '1px solid #D9D9D9'}}/>
            {this.renderSterbevorsorge()}
            <hr style={{margin: '3em 0', borderTop: '1px solid #D9D9D9'}}/>
            {this.renderPersonalData()}
            <hr style={{margin: '3em 0', borderTop: '1px solid #D9D9D9'}}/>
            {this.renderBankData()}
            <hr style={{margin: '3em 0', borderTop: '1px solid #D9D9D9'}}/>
            <Footer handleAction={this.props.handleAction}
                    onNextClick={() => this.props.handleAction(NavigationAction.NEXT)}
                    disableOfferNavigation={true}/>
            <DisclaimerInfoBox/>
        </>);
    }

    private renderPageHeading(): JSX.Element {
        const person = this.props.storeState.policyHolder || this.props.storeState.insuredPerson;

        return (<Headline>
                {mapAddressesToPrintableText(person.anrede) || person.vorname} {person.nachname},
                bitte prüfen Sie, ob Ihre Angaben richtig und vollständig sind.
            </Headline>
        );
    }

    private renderSterbevorsorge(): JSX.Element {
        const variant = extractSelectedVariant(this.props.storeState.variants);
        if (!variant) {
            return <MessageBox type="error">Keine Variante vorhanden</MessageBox>;
        }

        return (<div style={{textAlign: 'center'}}>
                <h3 style={{margin: '0 0 3em 0'}}>Ihre Angaben</h3>
                <Card padding="normal" id="personal-data" style={{textAlign: 'left'}}>
                    <Basket
                        basketStore={this.props.storeState}
                        valueRanges={this.props.valueRanges}
                        handleAction={this.props.handleAction}
                        alwaysExpanded
                        hideFlexOption
                        hideBurialPackage
                    />
                </Card>
                <Button width="auto" iconLeft={EditIcon} variant="text-link" size="large" style={{fontWeight: 'bold'}}
                        onClick={() => {
                            trackElementClicked(TrackingElement.Link_PersonendatenAendern);
                            this.props.handleAction(NavigationAction.DIRECT_JUMP_FEE_PAGE);
                        }}
                        data-component-id="edit-personal-data-button">
                    ändern
                </Button>
            </div>

        );

    }

    private renderPersonalData(): JSX.Element {
        return (<div style={{textAlign: 'center'}}>
            <h3 style={{margin: '0 0 3em 0'}}>Persönliche Daten</h3>
            <Card padding="normal" id="personal-data">
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        {this.renderInsuredPerson()}
                        {this.props.storeState.divergingInsuredPerson && this.renderPolicyHolder()}
                    </div>
                </div>

            </Card>
            <Button width="auto" iconLeft={EditIcon} variant="text-link" size="large" style={{fontWeight: 'bold'}}
                    onClick={() => {
                        trackElementClicked(TrackingElement.Link_PersonendatenAendern);
                        this.props.handleAction(NavigationAction.BREADCRUMB_PERSONAL_INFORMATION);
                    }}
                    data-component-id="edit-personal-data-button">
                ändern
            </Button>
        </div>);
    }

    private renderInsuredPerson(): JSX.Element {
        const insuredPerson: Person = this.props.storeState.insuredPerson;
        return (
            <div className="esc_col esc_col-12 esc_col-s-12 esc_col-m-6" style={{textAlign: 'left'}}>
                <div style={{fontSize: '18px', borderBottom: '1px solid #D9D9D9'}}>
                    {this.props.storeState.divergingInsuredPerson && 'Versicherte Person'}
                    {!this.props.storeState.divergingInsuredPerson && 'Versicherte Person und Versicherungsnehmer'}
                </div>
                <div style={{borderTopWidth: '10px', borderBottomWidth: '0px', margin: '1em 0'}}>
                    <b> {mapAddressesToPrintableText(insuredPerson.anrede)}&nbsp;
                        {insuredPerson.vorname}&nbsp;
                        {insuredPerson.nachname}</b>
                    <br/>
                    <small>{mapToGermanDate(insuredPerson.birthdate)}</small>
                </div>

                {insuredPerson.adresse && insuredPerson.adresse.strasse &&
                <div style={{borderTopWidth: '0px', borderBottomWidth: '0px', lineHeight: '20px'}}>
                    <small>
                        <b>Straße: </b>
                        {insuredPerson.adresse.strasse}
                        {insuredPerson.adresse.hausnummer}
                    </small>
                </div>}
                {insuredPerson.adresse && insuredPerson.adresse.ort &&
                <div style={{borderTopWidth: '0px', borderBottomWidth: '0px', lineHeight: '20px'}}>
                    <small>
                        <b>Ort: </b>
                        {insuredPerson.adresse.plz} {insuredPerson.adresse.ort}
                    </small>
                </div>}
                {insuredPerson.adresse && insuredPerson.geburtsort &&
                <div style={{borderTopWidth: '0px', borderBottomWidth: '0px', lineHeight: '20px'}}>
                    <small>
                        <b>Geburtsort: </b>
                        {insuredPerson.geburtsort}
                    </small>
                </div>}
            </div>
        );
    }

    private renderPolicyHolder(): JSX.Element {
        const policyHolder: Person | undefined = this.props.storeState.policyHolder;
        if (!policyHolder) {
            return <></>;
        }

        return (<div className="esc_col esc_col-12 esc_col-s-12 esc_col-m-6" style={{textAlign: 'left'}}>
            <div style={{fontSize: '18px', borderBottom: '1px solid #D9D9D9'}}>
                Versicherungsnehmer
            </div>
            <div style={{borderTopWidth: '10px', borderBottomWidth: '0px', margin: '1em 0'}}>
                <b> {mapAddressesToPrintableText(policyHolder.anrede)}&nbsp;
                    {policyHolder.vorname}&nbsp;
                    {policyHolder.nachname}</b>
                <br/>
                <small>{mapToGermanDate(policyHolder.birthdate)}</small>
            </div>
            {policyHolder.adresse && policyHolder.adresse.strasse &&
            <div style={{borderTopWidth: '0px', borderBottomWidth: '0px', lineHeight: '20px'}}>
                <small>
                    <b>Straße: </b>
                    {policyHolder.adresse.strasse} {policyHolder.adresse.hausnummer}
                </small>
            </div>}
            {policyHolder.adresse && policyHolder.adresse.ort &&
            <div style={{borderTopWidth: '0px', borderBottomWidth: '0px', lineHeight: '20px'}}>
                <small>
                    <b>Ort: </b>
                    {policyHolder.adresse.plz} {policyHolder.adresse.ort}
                </small>
            </div>}
            {policyHolder.adresse && policyHolder.geburtsort &&
            <div style={{borderTopWidth: '0px', borderBottomWidth: '0px'}}>
                <small>
                    <b>Geburtsort: </b>
                    {policyHolder.geburtsort}
                </small>
            </div>}
            {policyHolder.email && <div style={{borderTopWidth: '0px', borderBottomWidth: '0px', lineHeight: '20px'}}>
                <small>
                    <b>E-Mail: </b>
                    {policyHolder.email}
                </small>
            </div>}
            {policyHolder.vorwahl && <div style={{borderTopWidth: '0px', borderBottomWidth: '0px', lineHeight: '20px'}}>
                <small>
                    <b>Telefonnummer: </b>
                    {`${policyHolder.vorwahl} / ${policyHolder.rufnummer}`}
                </small>
            </div>}
        </div>);
    }

    private renderBankData(): JSX.Element {
        const person: Person = this.props.storeState.policyHolder || this.props.storeState.insuredPerson;

        return (<div style={{textAlign: 'center'}}>
                <h3 style={{margin: '0 0 3em 0'}}>Bankverbindung</h3>
                <Card padding="normal" id="bank-data" style={{textAlign: 'left'}}>
                    <div>
                        <div style={{borderTopWidth: '10px', borderBottomWidth: '0px', lineHeight: '20px', margin: '1em 0'}}>
                            <b> {mapAddressesToPrintableText(person.anrede)}&nbsp;
                                {person.vorname}&nbsp;
                                {person.nachname}</b>&nbsp;
                        </div>
                        <div style={{borderTopWidth: '0px', borderBottomWidth: '0px', lineHeight: '20px'}}>
                            {this.props.storeState.iban &&
                            <small>
                                <b>IBAN: </b>
                                {this.props.storeState.iban}
                            </small>}
                        </div>
                        <div style={{borderTopWidth: '0px', borderBottomWidth: '0px', lineHeight: '20px'}}>
                            {this.props.storeState.bic &&
                            <small>
                                <b>BIC: </b>
                                {this.props.storeState.bic}
                            </small>}
                        </div>
                        <div style={{borderTopWidth: '0px', borderBottomWidth: '0px', lineHeight: '20px'}}>
                            {this.props.storeState.insuranceBegin &&
                            <small>
                                <b>Mandatsdatum: </b>
                                {mapToGermanDate(this.props.storeState.insuranceBegin)}
                            </small>}
                        </div>
                    </div>
                </Card>
                <Button width="auto" iconLeft={EditIcon} variant="text-link" size="large" style={{fontWeight: 'bold'}}
                        onClick={() => {
                            trackElementClicked(TrackingElement.Link_BankverbindungAendern);
                            this.props.handleAction(NavigationAction.DIRECT_JUMP_BANKING_DETAILS_PAGE);
                        }}
                        data-component-id="edit-banking-details-button">
                    ändern
                </Button>
            </div>
        );
    }
}

export default InsuranceSummaryPage;
