import * as React from 'react';
import { CalculationMode } from 'stg-common';
import { NumericRange } from '../../../../common/src/types';
import PaymentPeriodPage, { PaymentPeriodPageData } from '../../pages/PaymentPeriodPage';
import { getOfferPageData, updatePaymentPeriodPageData } from '../../services/api';
import { StateDefinition } from '../StateMachine';
import {
    CHECKOUT_PAGES,
    FEE_PAGES,
    NavigationAction,
    PERSONAL_INFORMATION_PAGES,
    StateName
} from '../StateMachineTypes';

export const paymentPeriodPage: StateDefinition<PaymentPeriodPageData> = {
    name: StateName.PAYMENT_PERIOD_PAGE,

    validInboundStates: [
        ...CHECKOUT_PAGES,
        ...PERSONAL_INFORMATION_PAGES,
        ...FEE_PAGES,
        StateName.INSURANCE_SUM_PAGE,
        StateName.INSURANCE_FEE_PAGE,
        StateName.FEE_PAGE
    ],
    transitions: [
        {
            test: (action, data) => (action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK)
                && data.userInput.calculationMode === CalculationMode.INSURANCE_SUM,
            newState: StateName.INSURANCE_SUM_PAGE
        },
        {
            test: (action, data) => (action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK)
                && data.userInput.calculationMode === CalculationMode.INSURANCE_FEE,
            newState: StateName.INSURANCE_FEE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.NEXT,
            newState: StateName.FEE_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'paymentPeriod');
        const paymentPeriod = resolveDefault(response.paymentPeriod, response.valueRanges!.paymentPeriod);

        return {
            userInput: {
                paymentPeriod,
                calculationMode: response.calculationMode,
                birthdate: response.birthdate,
                insuranceBegin: response.insuranceBegin,
                messages: response.messages
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        await updatePaymentPeriodPageData(inputData.businessId, inputData.userInput);
        return {};
    },
    render: (inputData, handleAction, updateApp) => {
        return <PaymentPeriodPage
            storeState={{
                paymentPeriod: inputData.userInput.paymentPeriod,
                birthdate: inputData.userInput.birthdate,
                insuranceBegin: inputData.userInput.insuranceBegin,
                calculationMode: inputData.userInput.calculationMode,
                update: values => updateApp(values)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};

const resolveDefault = (paymentPeriod: number | undefined, paymentPeriodRange: NumericRange) => {
    if (paymentPeriod && paymentPeriod >= paymentPeriodRange.min && paymentPeriod <= paymentPeriodRange.max) {
        return paymentPeriod;
    }
    return paymentPeriodRange.defaultValue;
};
