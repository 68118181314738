import { GlobalTracking, LinkType } from '@eg/tracking';
import { NODE_ENV } from '../config';
import { StateName } from '../routing/StateMachineTypes';
import { TrackingElement, TrackingErrorType } from './trackingConstants';
import { TrackingData } from './TrackingData';
import { mapTrackingData } from './trackingDataMapper';
import { getTrackingPageName, mapPageData } from './trackingPageMapper';
import { mapTransactionData } from './trackingTransactionMapper';

const tracker = new GlobalTracking();
let trackingData: TrackingData;
let currentPage: StateName | undefined;

export const trackPageTransition = (
  targetPage: StateName,
  isMakler: boolean,
  isTiedAgent: boolean,
  oenrNumber: string,
  pnrNumber: string
) => {
  try {
    currentPage = targetPage;
    tracker.datalayerTrackPageView(createTrackingObject(isMakler, isTiedAgent, oenrNumber, pnrNumber));
    debug();
  } catch (e) {
    // Eigentlich interessiert es uns nicht wenn tracking nicht funktioniert
  }
};

export const trackElementClicked = (
  clickedElement: TrackingElement,
  trackLater = true
) => {
  try {
    tracker.datalayerTrackGeneralClick(
      createClickTrackingObject(clickedElement),
      trackLater
    );
  } catch (e) {
    console.warn((e as Error).message);
  }
};

export const trackElementClickImmediate = (
  clickedElement: TrackingElement,
  fakePageSwitch?: StateName,
  trackAsPageLoad = false
) => {
  try {
    const clickTrackingObjekt = createClickTrackingObject(clickedElement);
    if (fakePageSwitch) {
      currentPage = fakePageSwitch;
    }
    const trackingObjekt = {
      ...createTrackingObject(),
      ...clickTrackingObjekt
    };
    if (trackAsPageLoad) {
      tracker.datalayerTrackPageView(trackingObjekt);
    } else {
      tracker.datalayerTrackGeneralClick(trackingObjekt);
    }
    debug();
  } catch (e) {
    // Eigentlich interessiert es uns nicht wenn tracking nicht funktioniert
  }
};

export const trackError = (
  clickedElement: TrackingElement,
  errorType: TrackingErrorType,
  fakePageSwitch?: StateName,
  trackAsPageLoad = false
) => {
  try {
    if (fakePageSwitch) {
      currentPage = fakePageSwitch;
    }
    const trackingObjekt = {
      ...createTrackingObject(),
      ...createClickTrackingObject(clickedElement),
      miscellaneous: {
        errors: {
          errorType,
          errorFlag: 'true'
        }
      }
    };
    if (trackAsPageLoad) {
      tracker.datalayerTrackPageView(trackingObjekt);
    } else {
      tracker.datalayerTrackGeneralClick(trackingObjekt);
    }
    debug();
  } catch (e) {
    // Eigentlich interessiert es uns nicht wenn tracking nicht funktioniert
  }
};

const createTrackingObject = (isMakler?: boolean, isTiedAgent?: boolean, oenrNumber?: string, pnrNumber?: string) => {
  return {
    ...mapPageData(currentPage, isMakler, isTiedAgent, oenrNumber, pnrNumber),
    ...mapTrackingData(trackingData),
    ...mapTransactionData(trackingData, currentPage)
  };
};

const createClickTrackingObject = (clickedElement: TrackingElement) => {
  return {
    eventdetails: {
      clickedElement,
      clickedElementPage: getTrackingPageName(currentPage),
      clickType: LinkType.CustomLink
    }
  };
};

export const addTrackingData = (data: TrackingData) => {
  if (
    trackingData &&
    (data.insuranceSum !== undefined || data.insuranceFee !== undefined)
  ) {
    trackingData.insuranceSum = undefined;
    trackingData.insuranceFee = undefined;
  }
  trackingData = { ...trackingData, ...data };
};

const debug = () => {
  if (NODE_ENV === 'development') {
    console.log(
      // eslint-disable-line
      // tslint:disable-next-line
      `Tracking data being sent: \n%c${JSON.stringify(window.appEventData.slice(-1).pop(), null, 2)}`,
      'color: white; font-weight: bold; font-family:courier;font-size: 10px'
    );
  }
};
