import { Feature, FEATURE_FLAG_LIST, FFlagList } from './FeatureFlag.types';

// eslint-disable-next-line
function mergeArraysWithoutDuplicates(array1: Feature[], array2: Feature[] = []): Feature[] {
  const arrayMerge = array1.concat(array2);
  return arrayMerge.filter(
    (obj, index) => index === arrayMerge.findIndex(o => obj.name === o.name)
  );
}

const validateFFlags = (featList: Feature[] = []): void => {
  const notFound =
    featList.filter(feat => !FEATURE_FLAG_LIST.includes((feat.name || '').toString())) || [];

  if (notFound.length > 0) {
    console.log(`Not allowed flags! ${JSON.stringify(notFound)}`);
  }
};

const getFlagsFromQueryString = (): Feature[] => {
  const parsedQuery: FFlagList[] =
    (window.location.search.substring(1).split('&') as unknown as FFlagList[]) || [];

    return parsedQuery
    .filter(p => p.toString().toLocaleLowerCase().startsWith('ff'))
    .map(feature => {
      const parsedValue = feature.toString().split('=');
      let name = FFlagList[parsedValue[0]];
      if (name === undefined) {
        name = parsedValue[0];
      }
      return {
        name,
        active: JSON.parse(parsedValue[1] || '')
      };
    });
};

const mergeWithUrlParams = (featureList: Feature[]): Feature[] =>
  mergeArraysWithoutDuplicates(getFlagsFromQueryString(), featureList);

export const featureFlag = (featureList: Feature[], isProduction: boolean) => {
  // Merge querystring params with given FF list only in test / dev mode
  const features = isProduction ? featureList : mergeWithUrlParams(featureList);

  if (!isProduction) {
    validateFFlags(features);
  }

  return {
    isActive: (name: FFlagList): boolean => {
      const foundFeature = features.find(feat => feat.name === name);
      return (foundFeature && foundFeature.active) || false;
    }
  };
};
