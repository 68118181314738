import * as React from 'react';
import { OfferType, OrderResponse, ResponseCodes } from 'stg-common';
import { isKooperationSales } from '../../../helpers/kooperationSales';
import { getOeNumber } from '../../../helpers/modeConfig';
import RequestOfferInsuredPersonPage, { RequestOfferInsuredPersonPageData } from '../../../pages/RequestOfferPages/RequestOfferInsuredPersonPage';
import { getOfferPageData, getPerson, makeOffer, updateInsuredPersonPageData } from '../../../services/api';
import { trackError } from '../../../tracking/tracker';
import { TrackingElement, TrackingErrorType } from '../../../tracking/trackingConstants';
import { createOfferNavigation } from '../../routeHelper';
import { StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

const validInboundStates = [
    StateName.POLICY_HOLDER_PAGE,
    StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE,
    StateName.FEE_DYNAMIC_PAGE
];

export const requestOfferInsuredPersonPage: StateDefinition<RequestOfferInsuredPersonPageData> = {
    name: StateName.REQUEST_OFFER_INSURED_PERSON_PAGE,
    validInboundStates,
    transitions: [
        // Breadcrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        },
        // Navigation
        {
            test: (action, data) => action === NavigationAction.OFFER_DIRECT_ONLINE,
            newState: StateName.REQUEST_OFFER_FEEDBACK_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.OFFER_WRITTEN_POSTAL,
            newState: StateName.REQUEST_OFFER_FEEDBACK_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.OFFER_WRITTEN_EMAIL,
            newState: StateName.REQUEST_OFFER_FEEDBACK_PAGE
        },
        {
            test: (action, data) => (action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK) && data.userInput.divergingInsuredPerson,
            newState: StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE
        },
        {
            test: (action, data) => (action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK) && !data.userInput.divergingInsuredPerson,
            resolveNewState: createOfferNavigation(StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE, validInboundStates),
            newState: StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'insuredPerson').then(offerPageData => {
            if (offerPageData.insuredPersonId) {
                return getPerson(inputData.businessId, offerPageData.insuredPersonId)
                    .then(person => ({ ...offerPageData, insuredPerson: person }));
            }
            return offerPageData;
        });

        return {
            userInput: {
                ...response,
                showMeldung: false
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        await updateInsuredPersonPageData(inputData.businessId, inputData.userInput);

        if (transitionDetails.targetStateName === StateName.REQUEST_OFFER_FEEDBACK_PAGE) {
            let offerType;
            let trackingElement;
            switch (transitionDetails.action) {
                case NavigationAction.OFFER_WRITTEN_POSTAL:
                    offerType = OfferType.WRITTEN_POSTAL;
                    trackingElement = TrackingElement.Button_AngebotPerPost;
                    break;
                case NavigationAction.OFFER_WRITTEN_EMAIL:
                    offerType = OfferType.WRITTEN_EMAIL;
                    trackingElement = TrackingElement.Button_AngebotPerEmail;
                    break;
                default:
                    offerType = OfferType.DIRECT_ONLINE;
                    trackingElement = TrackingElement.Button_AngebotPDFHerunterladen;
                    break;
            }
            const response: OrderResponse =
                await makeOffer(inputData.businessId, offerType, getOeNumber(), isKooperationSales());
            if (response.status === ResponseCodes.PRECONDITION_FAILED && response.showMeldung) {
                trackError(trackingElement, TrackingErrorType.Sonstige);
                return {skipTransition: true, payload: {showMeldung: response.showMeldung}};
            }
            if (response.status !== ResponseCodes.SUCCESS) {
                throw new Error('Internal server error');
            }
        }
        return {};
    },
    render: (inputData, handleAction, updateApp, onError) => {
        return <RequestOfferInsuredPersonPage
            valueRanges={inputData.valueRanges}
            businessId={inputData.businessId}
            storeState={{
                insuredPerson: inputData.userInput.insuredPerson,

                policyHolderId: inputData.userInput.policyHolderId,
                nationalities: inputData.userInput.nationalities,
                divergingInsuredPerson: inputData.userInput.divergingInsuredPerson,
                vklm: inputData.userInput.vklm,
                custodian: inputData.userInput.custodian,
                accountThirdParty: inputData.userInput.accountThirdParty,
                messages: inputData.userInput.messages,
                showMeldung: inputData.userInput.showMeldung,
                update: values => updateApp(values)
            }}
            handleAction={handleAction}
        />;
    }
};
