import { Betreuungsweiche } from '@eg/betreuungsweiche/betreuungsweiche';
import { CheckColorIcon } from '@eg/elements/components/Icons';
import * as React from 'react';
import { Addresses } from 'stg-common';
import { CONFIG_BERATUNGS_BETREUUNGS_BASE_URL } from '../config';
import { getQueryValue } from '../helpers/QueryParameterExtractor';
import { scrollToTop } from '../helpers/scrolling';
import { Storage } from '../helpers/Storage';
import { PagePropsWithoutCallbacks, StoreStateUpdater } from '../types/PageProps';

export interface FeedbackPageData extends StoreStateUpdater<FeedbackPageData> {
    anrede: Addresses;
    vorname: string;
    nachname: string;
    businessId: string;
    policyHolderId: string;
}

function FeedbackPage(props: PagePropsWithoutCallbacks<FeedbackPageData>) {
    const input = props.storeState;
    const feedbackPageText = {
        headline: input.vorname && input.nachname ? `Vielen Dank, ${input.vorname} ${input.nachname}!` : 'Vielen Dank!',
        subtitle: 'Ihr Antrag wurde erfolgreich gesendet. Sie erhalten umgehend eine Bestätigung an Ihre E-Mail-Adresse.',
        paragraph: 'Sie müssen nichts tun. ERGO kümmert sich um alles Weitere, Sie ',
        paragraphBold: 'erhalten in wenigen Tagen Ihre Vertragsunterlagen per Post.'

    };
    React.useEffect(() => {
        scrollToTop();
        Storage.clear();
    }, []);

    return (
        <div className="feedback-page" data-component-id="FeedbackPage">
        <div className="stg-feedback-page__hero">
            <div className="stg-feedback-page__hero-title">
                <CheckColorIcon />
                    <br />
                <span>
                    {feedbackPageText.headline}
                </span>
            </div>
            <h3 className="stg-feedback-page__hero-subtitle">{feedbackPageText.subtitle}</h3>
        </div>
        <div className="stg-feedback-page__body">
           <p className="stg-feedback-page__paragraph1">{feedbackPageText.paragraph}<b>{feedbackPageText.paragraphBold}</b></p>
        </div>
        {getQueryValue('betreuungsweiche') && !getQueryValue('adh_internet') && <Betreuungsweiche
            businessId={input.businessId}
            versicherungsnehmerPdsId={input.policyHolderId}
            sparte="LEBEN"
            url={CONFIG_BERATUNGS_BETREUUNGS_BASE_URL}
        />}
        </div>
    );
}

export default FeedbackPage;
