import Button from '@eg/elements/Button';
import { CopyLinkIcon } from '@eg/elements/components/Icons';
import ControlWithHint from '@eg/elements/ControlWithHint';
import { DateInputValue } from '@eg/elements/DateInput';
import DateInputRow from '@eg/elements/DateInputRow';
import FormRow from '@eg/elements/FormRow';
import Input from '@eg/elements/Input';
import InputRow from '@eg/elements/InputRow';
import MessageBox from '@eg/elements/MessageBox';
import Radio from '@eg/elements/Radio';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import Select from '@eg/elements/Select';
import { Field, FieldProps, FormikProps, FormikValues } from 'formik';
import * as React from 'react';
import {
    Addresses,
    Adresse,
    isValidIsoDateFormat,
    mapDateInputToIsoDateString,
    mapIsoDateStringToDateInput,
    Nationality,
    Person,
} from 'stg-common';
import { getAddressesFromStringValue } from '../helpers/addressesHelper';
import { getErrorMessage } from '../helpers/validationHelpers';
import { trackElementClickImmediate } from '../tracking/tracker';
import { TrackingElement } from '../tracking/trackingConstants';
import { DataIdInsuredPersonPage } from '../types/DataId';
import './PersonalData.css';
import TooltipComponent from './Tooltip';

const DREI = 3;
const ZWEI = 2;
const DREI_ZU_ZWEI_COLSPAN = [DREI, ZWEI];
const ZWEI_ZU_DREI_COLSPAN = [ZWEI, DREI];

export interface PersonInformations extends Person {
    personId: string;
    nationalities: Nationality[];
}

type UpdatePersonInformationsCallback = (values: Partial<PersonInformations>, callback?: () => void) => void;

export interface PersonalDataProps {
    form: FormikProps<PersonInformations>;
    inputData: PersonInformations;
    updateCallback: UpdatePersonInformationsCallback;
    detailedMode: boolean;
    disableBirthdate: boolean;
    disableInfoBoxEudsgvo?: boolean;
    isCorrectedAddress?: boolean;
    isAddressValid?: boolean;
    policyHolderPerson?: Person;
    insuredPersonAddress?: Adresse;
    showUsePolicyHolderData?: boolean;
    showCopyInsuredPersonAddress?: boolean;
    showCountry?: boolean;
    isNationalityRequired?: boolean;
}

const PersonalData = (props: PersonalDataProps): JSX.Element => {
    return (<div className="PersonalData">
            {!props.disableInfoBoxEudsgvo && <InfoBoxEudsgvo/>}
            {props.showUsePolicyHolderData && renderUsePolicyHolderDataLink(props)}
            {renderSalutation(props)}
            {renderFirstname(props)}
            {renderSurname(props)}
            <br/><br/>
            {props.showCopyInsuredPersonAddress && renderCopyAddressLink(props)}
            {renderStreetHousenumber(props)}
            {renderPostalCity(props)}
            {props.showCountry && renderCountry(props)}
            {props.isCorrectedAddress && <div className="ee_form-row ee_form-row--wide">
                <div className="ee_form-row__descriptor ee_form-row__descriptor--wide">&nbsp;</div>
                <div style={{
                    textAlign: 'left',
                    margin: 'auto',
                    color: '#BF1528'
                }} data-component-id="personal-data-address-corrected-message">
                    Ihre Adresse wurde korrigiert.
                </div>
            </div>}
            {(props.isAddressValid === false && props.isCorrectedAddress === false) && <div className="ee_form-row ee_form-row--wide">
                <div className="ee_form-row__descriptor ee_form-row__descriptor--wide">&nbsp;</div>
                <div style={{
                    textAlign: 'left',
                    margin: 'auto',
                    color: '#BF1528'
                }} data-component-id="personal-data-address-corrected-message">
                    Es können nur Adressen innerhalb Deutschlands akzeptiert werden
                </div>
            </div>}
            <br/><br/>
            {renderBirthdate(props)}
            {props.detailedMode && renderBirthplace(props)}
            {(props.detailedMode || props.isNationalityRequired) && renderNationality(props)}
            <br/><br/>
            {props.detailedMode && renderEmail(props)}
            {props.detailedMode && renderTelephoneNumber(props)}
        </div>
    );
};

const InfoBoxEudsgvo = () => {
    return (
        <MessageBox
            data-test-id="info_eudsgvo"
            children={
                <div>
                    Bei der Verarbeitung von personenbezogenen Daten beachtet ERGO die Vorschriften der
                    EU-Datenschutz-Grundverordnung. Ausführliche Informationen finden Sie in unserem Datenschutzbereich.
                    Bei Anforderung eines Angebots erhalten Sie die Informationen in Ihren Vertragsunterlagen. Ihre Daten
                    speichern wir und der Versicherer für mindestens 30 Tage, bei einer Angebotserstellung für 6 Monate.
                    Der Vorteil für Sie: Wir können Sie besser betreuen, wenn Fragen auftreten.
                </div>
            }
        />
    );
};

const renderUsePolicyHolderDataLink = (props: PersonalDataProps): JSX.Element => {
    return (
        <div style={{display: 'flex', alignItems: 'center', lineHeight: '24px', cursor: 'pointer'}}>
            <Button
                type="button"
                data-component-id="use-policyholder-data"
                variant="text-link"
                iconLeft={CopyLinkIcon}
                onClick={() => {
                    trackElementClickImmediate(TrackingElement.Link_DatenVersicherungsnehmer);
                    if (props.policyHolderPerson) {
                        if (props.policyHolderPerson.anrede) {
                            props.form.setFieldValue('anrede', props.policyHolderPerson.anrede);
                            props.form.setFieldTouched('anrede');
                        }
                        if (props.policyHolderPerson.vorname) {
                            props.form.setFieldValue('vorname', props.policyHolderPerson.vorname);
                            props.form.setFieldTouched('vorname');
                        }
                        if (props.policyHolderPerson.nachname) {
                            props.form.setFieldValue('nachname', props.policyHolderPerson.nachname);
                            props.form.setFieldTouched('nachname');
                        }
                        if (props.policyHolderPerson.birthdate) {
                            props.form.setFieldValue('birthdate', props.policyHolderPerson.birthdate);
                            props.form.setFieldValue('birthdateField', mapIsoDateStringToDateInput(props.policyHolderPerson.birthdate));
                            props.form.setFieldTouched('birthdate');
                            props.form.setFieldTouched('birthdateField');
                        }
                        if (props.policyHolderPerson.adresse.strasse) {
                            props.form.setFieldValue('adresse.strasse', props.policyHolderPerson.adresse.strasse);
                            props.form.setFieldTouched('adresse.strasse');
                        }
                        if (props.policyHolderPerson.adresse.hausnummer) {
                            props.form.setFieldValue('adresse.hausnummer', props.policyHolderPerson.adresse.hausnummer);
                            props.form.setFieldTouched('adresse.hausnummer');
                        }
                        if (props.policyHolderPerson.adresse.plz) {
                            props.form.setFieldValue('adresse.plz', props.policyHolderPerson.adresse.plz);
                            props.form.setFieldTouched('adresse.plz');
                        }
                        if (props.policyHolderPerson.adresse.ort) {
                            props.form.setFieldValue('adresse.ort', props.policyHolderPerson.adresse.ort);
                            props.form.setFieldTouched('adresse.ort');
                        }
                    }
                }}>
                Daten des Versicherungsnehmers übernehmen
            </Button>
        </div>);
};

const renderSalutation = (props: PersonalDataProps): JSX.Element => {
    return (<Field
        name="anrede"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<RadioGroupRow
                label="Anrede"
                id={field.name}
                name={field.name}
                onBlur={() => {
                    setFieldTouched(field.name);
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(field.name, event.target.value);
                    props.updateCallback({
                        anrede: getAddressesFromStringValue(event.target.value)
                    });
                }}
                error={getErrorMessage(props.form, field)}
                value={field.value}
            >
                <Radio
                    value={Addresses.MR}
                    label="Herr"
                    data-component-id={DataIdInsuredPersonPage.INPUT_SALUTATION_MALE}
                />
                <Radio
                    value={Addresses.MRS}
                    label="Frau"
                    data-component-id={DataIdInsuredPersonPage.INPUT_SALUTATION_FEMALE}
                />
            </RadioGroupRow>);
        }}>
    </Field>);
};

const renderFirstname = (props: PersonalDataProps): JSX.Element => {
    const maxLengthFirstname = 31;
    return (<Field
        name="vorname"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<InputRow
                label="Vorname"
                id={field.name}
                name={field.name}
                data-component-id={DataIdInsuredPersonPage.INPUT_FIRSTNAME}
                maxLength={maxLengthFirstname}
                placeholder="Vorname"
                onBlur={() => {
                    setFieldTouched(field.name);
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(field.name, event.target.value);
                    props.updateCallback({
                        vorname: event.target.value
                    });
                }}
                value={field.value || ''}
                error={getErrorMessage(props.form, field)}
            />);
        }}>
    </Field>);
};

const renderSurname = (props: PersonalDataProps): JSX.Element => {
    const maxLengthSurname = 36;
    return (<Field
        name="nachname"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<InputRow
                label="Nachname"
                id={field.name}
                name={field.name}
                data-component-id={DataIdInsuredPersonPage.INPUT_SURNAME}
                maxLength={maxLengthSurname}
                placeholder="Nachname"
                onBlur={() => {
                    setFieldTouched(field.name);
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(field.name, event.target.value);
                    props.updateCallback({
                        nachname: event.target.value
                    });
                }}
                value={field.value || ''}
                error={getErrorMessage(props.form, field)}
            />);
        }}>
    </Field>);
};

const renderBirthdate = (props: PersonalDataProps): JSX.Element => {
    const fullYearHelper = 100;
    const minDate = new Date();
    minDate.setFullYear(new Date().getFullYear() - fullYearHelper);
    const maxDate = new Date();
    return (<Field
            name="birthdateField"
            render={({field}: FieldProps<FormikValues>) => {
                const {setFieldValue, setFieldTouched} = props.form;
                return (<DateInputRow
                    label="Geburtsdatum"
                    id={field.name}
                    data-component-id={DataIdInsuredPersonPage.INPUT_BIRTHDATE}
                    minDate={minDate}
                    maxDate={maxDate}
                    onBlur={() => {
                        setFieldTouched('birthdate');
                        setFieldTouched(field.name);
                    }}
                    disabled={props.disableBirthdate}
                    onChange={(dateObject: DateInputValue) => {
                        setFieldValue('birthdate', mapDateInputToIsoDateString(dateObject));
                        setFieldValue(field.name, dateObject);
                        props.updateCallback({
                            birthdate: mapDateInputToIsoDateString(dateObject)
                        });
                    }}
                    value={(field.value && !isValidIsoDateFormat(field.value)) ? field.value : mapIsoDateStringToDateInput(props.inputData.birthdate)}
                    error={getErrorMessage(props.form, {name: 'birthdate'})}
                    autoTab={true}
                />);
            }}/>
    );
};

const renderStreetHousenumber = (props: PersonalDataProps): JSX.Element => {
    return (
        <FormRow label="Straße / Nr." colspans={DREI_ZU_ZWEI_COLSPAN}>
            {renderStreet(props)}
            {renderHousenumber(props)}
        </FormRow>);
};

const renderStreet = (props: PersonalDataProps): JSX.Element => {
    const maxLengthStreet = 51;
    return (<Field
        name="adresse.strasse"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<ControlWithHint error={getErrorMessage(props.form, field)}>
                <Input
                    id={field.name}
                    name={field.name}
                    data-component-id={DataIdInsuredPersonPage.INPUT_STREET}
                    maxLength={maxLengthStreet}
                    placeholder="Straße"
                    onBlur={() => {
                        setFieldTouched(field.name);
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(field.name, event.target.value);
                        props.updateCallback({
                            adresse: {
                                strasse: event.target.value,
                                hausnummer: (props.inputData.adresse && props.inputData.adresse.hausnummer) ? props.inputData.adresse.hausnummer : '',
                                plz: (props.inputData.adresse && props.inputData.adresse.plz) ? props.inputData.adresse.plz : '',
                                ort: (props.inputData.adresse && props.inputData.adresse.ort) ? props.inputData.adresse.ort : '',
                                land: (props.inputData.adresse && props.inputData.adresse.land) ? props.inputData.adresse.land : 'Deutschland'
                            }
                        });
                    }}
                    value={field.value}
                />
            </ControlWithHint>);
        }}>
    </Field>);
};

const renderHousenumber = (props: PersonalDataProps): JSX.Element => {
    const maxLengthHousenumber = 10;
    return (<Field
        name="adresse.hausnummer"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<ControlWithHint error={getErrorMessage(props.form, field)}>
                <Input
                    id={field.name}
                    name={field.name}
                    data-component-id={DataIdInsuredPersonPage.INPUT_HOUSENUMBER}
                    maxLength={maxLengthHousenumber}
                    placeholder="Hausnummer"
                    onBlur={() => {
                        setFieldTouched(field.name);
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(field.name, event.target.value);
                        props.updateCallback({
                            adresse: {
                                strasse: props.inputData.adresse ? props.inputData.adresse.strasse : '',
                                hausnummer: event.target.value,
                                plz: props.inputData.adresse ? props.inputData.adresse.plz : '',
                                ort: props.inputData.adresse ? props.inputData.adresse.ort : '',
                                land: props.inputData.adresse ? props.inputData.adresse.land : 'Deutschland'
                            }
                        });
                    }}
                    value={field.value}
                />
            </ControlWithHint>);
        }}>
    </Field>);
};

const renderPostalCity = (props: PersonalDataProps): JSX.Element => {
    return (
        <FormRow label="PLZ / Ort" colspans={ZWEI_ZU_DREI_COLSPAN}>
            {renderPostalCode(props)}
            {renderCity(props)}
        </FormRow>);
};

const renderPostalCode = (props: PersonalDataProps): JSX.Element => {
    const maxLengthPostalCode = 5;
    return (<Field
        name="adresse.plz"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<ControlWithHint error={getErrorMessage(props.form, field)}>
                <Input
                    id={field.name}
                    name={field.name}
                    data-component-id={DataIdInsuredPersonPage.INPUT_POSTALCODE}
                    maxLength={maxLengthPostalCode}
                    placeholder="PLZ"
                    onBlur={() => {
                        setFieldTouched(field.name);
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(field.name, event.target.value);
                        props.updateCallback({
                            adresse: {
                                strasse: props.inputData.adresse ? props.inputData.adresse.strasse : '',
                                hausnummer: props.inputData.adresse ? props.inputData.adresse.hausnummer : '',
                                plz: event.target.value,
                                ort: props.inputData.adresse ? props.inputData.adresse.ort : '',
                                land: props.inputData.adresse ? props.inputData.adresse.land : 'Deutschland'
                            }
                        });
                    }}
                    value={field.value}
                />
            </ControlWithHint>);
        }}>
    </Field>);
};

const renderCity = (props: PersonalDataProps): JSX.Element => {
    const maxLengthCity = 30;
    return (<Field
        name="adresse.ort"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<ControlWithHint error={getErrorMessage(props.form, field)}>
                <Input
                    id={field.name}
                    name={field.name}
                    data-component-id={DataIdInsuredPersonPage.INPUT_CITY}
                    maxLength={maxLengthCity}
                    placeholder="Ort"
                    onBlur={() => {
                        setFieldTouched(field.name);
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(field.name, event.target.value);
                        props.updateCallback({
                            adresse: {
                                strasse: props.inputData.adresse ? props.inputData.adresse.strasse : '',
                                hausnummer: props.inputData.adresse ? props.inputData.adresse.hausnummer : '',
                                plz: props.inputData.adresse ? props.inputData.adresse.plz : '',
                                ort: event.target.value,
                                land: props.inputData.adresse ? props.inputData.adresse.land : 'Deutschland'
                            }
                        });
                    }}
                    value={field.value}
                />
            </ControlWithHint>);
        }}>
    </Field>);
};

const renderCountry = (props: PersonalDataProps): JSX.Element => {
    return (
        <Field
            name="adresse.land"
            render={({field}: FieldProps<FormikValues>) => {
                const {setFieldValue, setFieldTouched} = props.form;
                return (
                    <InputRow id={field.name}
                              name={field.name}
                              label="Land"
                              disabled={true}
                              tooltip="Bezugsberechtigte müssen in Deutschland wohnen. Ansonsten bitte an den Außendienstmitarbeiter wenden."
                              value="Deutschland"
                              onBlur={() => {
                                  setFieldTouched(field.name);
                              }}
                              onChange={() => {
                                  setFieldValue(field.name, 'Deutschland');
                              }}
                              error={getErrorMessage(props.form, field)}/>
                );
            }}
        />);
};

const renderCopyAddressLink = (props: PersonalDataProps): JSX.Element => {
    return (
        <div style={{display: 'flex', alignItems: 'center', lineHeight: '24px', cursor: 'pointer'}}>
            <Button
                type="button"
                data-component-id="person-copy-address"
                variant="text-link"
                iconLeft={CopyLinkIcon}
                onClick={() => {
                    trackElementClickImmediate(TrackingElement.Link_AnschriftUebernahme);
                    if (props.insuredPersonAddress) {
                        if (props.insuredPersonAddress.strasse) {
                            props.form.setFieldValue('adresse.strasse', props.insuredPersonAddress.strasse);
                            props.form.setFieldTouched('adresse.strasse');
                        }
                        if (props.insuredPersonAddress.hausnummer) {
                            props.form.setFieldValue('adresse.hausnummer', props.insuredPersonAddress.hausnummer);
                            props.form.setFieldTouched('adresse.hausnummer');
                        }
                        if (props.insuredPersonAddress.plz) {
                            props.form.setFieldValue('adresse.plz', props.insuredPersonAddress.plz);
                            props.form.setFieldTouched('adresse.plz');
                        }
                        if (props.insuredPersonAddress.ort) {
                            props.form.setFieldValue('adresse.ort', props.insuredPersonAddress.ort);
                            props.form.setFieldTouched('adresse.ort');
                        }
                    }
                }}>
                Anschrift aus der versicherten Person übernehmen
            </Button>
        </div>);
};

const renderBirthplace = (props: PersonalDataProps): JSX.Element => {
    const maxLengthBirthplace = 36;
    return (<Field
        name="geburtsort"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<InputRow
                label="Geburtsort"
                id={field.name}
                name={field.name}
                data-component-id={DataIdInsuredPersonPage.INPUT_BIRTHPLACE}
                maxLength={maxLengthBirthplace}
                placeholder="Geburtsort"
                onBlur={() => {
                    setFieldTouched(field.name);
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(field.name, event.target.value);
                    props.updateCallback({
                        geburtsort: event.target.value
                    });
                }}
                value={field.value}
                error={getErrorMessage(props.form, field)}
            />);
        }}>
    </Field>);
};

const renderNationality = (props: PersonalDataProps): JSX.Element => {
    return (<Field
        name="staatsangehoerigkeit"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;

            return (<FormRow label="Staatsangehörigkeit"
                             error={getErrorMessage(props.form, field)}
            >
                <Select
                    id={field.name}
                    name={field.name}
                    data-component-id={DataIdInsuredPersonPage.INPUT_NATIONALITY}
                    onBlur={() => {
                        setFieldTouched(field.name);
                    }}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        setFieldValue(field.name, event.target.value);
                        props.updateCallback({
                            staatsangehoerigkeit: event.target.value
                        });
                    }}
                    defaultValue={props.inputData.staatsangehoerigkeit}
                >
                    {getNationalityOptions(props.inputData.nationalities)}
                </Select>
            </FormRow>);
        }}>
    </Field>);
};

const getNationalityOptions = (nationalities: Nationality[]): JSX.Element[] => {
    const favouriteNationalityKeys = ['DE', 'CH', 'AT', 'PL'];
    const favouriteNationalities: Nationality[] = nationalities.filter((n: Nationality) => favouriteNationalityKeys.find(k => k === n.key));
    const options = [<option key="pleaseChoose" value={''}>Bitte wählen</option>];
    favouriteNationalities.forEach((nation: Nationality) => {
        const uniqueNationKey = `favorites${nation.key}`;
        options.push(<option key={uniqueNationKey} value={nation.key}>{nation.text}</option>);
    });
    options.push(<option disabled={true} key={'seperator'} value={'---------'}>----------</option>);
    nationalities.forEach((nation: Nationality) => (options.push(<option key={nation.key} value={nation.key}>{nation.text}</option>)));
    return options;
};

export const maxLengthEmail = 60;

const renderEmail = (props: PersonalDataProps): JSX.Element => {
    return (<Field
        name="email"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<InputRow
                label="E-Mail-Adresse"
                id={field.name}
                name={field.name}
                data-component-id={DataIdInsuredPersonPage.INPUT_EMAIL}
                maxLength={maxLengthEmail}
                placeholder="E-Mail"
                tooltip={<TooltipComponent heading={'Warum benötigt ERGO Ihre E-Mail-Adresse?'}
                desc={'Ihre E-Mail-Adresse wird benötigt, um Ihnen die Anforderung per E-Mail-Adresse zu bestätigen. Diese Angabe unterliegt natürlich strengen Datenschutzbestimmungen.'}/>}
                onBlur={() => {
                    setFieldTouched(field.name);
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(field.name, event.target.value);
                    props.updateCallback({
                        email: event.target.value
                    });
                }}
                value={field.value}
                error={getErrorMessage(props.form, field)}
            />);
        }}>
    </Field>);
};

const renderTelephoneNumber = (props: PersonalDataProps): JSX.Element => {
    return (
        <FormRow label="Telefon" colspans={ZWEI_ZU_DREI_COLSPAN}>
            {renderPrefix(props)}
            {renderPhonenumber(props)}
        </FormRow>);
};

const renderPrefix = (props: PersonalDataProps): JSX.Element => {
    const maxLengthPrefix = 6;
    return (<Field
        name="vorwahl"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<ControlWithHint error={getErrorMessage(props.form, field)}>
                <Input
                    id={field.name}
                    name={field.name}
                    data-component-id={DataIdInsuredPersonPage.INPUT_PREFIX}
                    maxLength={maxLengthPrefix}
                    placeholder="Vorwahl"
                    onBlur={() => {
                        setFieldTouched(field.name);
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(field.name, event.target.value);
                        props.updateCallback({
                            vorwahl: event.target.value
                        });
                    }}
                    value={field.value}
                />
            </ControlWithHint>);
        }}>
    </Field>);
};

const renderPhonenumber = (props: PersonalDataProps): JSX.Element => {
    const maxLengthPhonenumber = 12;
    return (<Field
        name="rufnummer"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue, setFieldTouched} = props.form;
            return (<ControlWithHint error={getErrorMessage(props.form, field)}>
                <Input
                    id={field.name}
                    name={field.name}
                    data-component-id={DataIdInsuredPersonPage.INPUT_PHONENUMBER}
                    maxLength={maxLengthPhonenumber}
                    placeholder="Rufnummer"
                    onBlur={() => {
                        setFieldTouched(field.name);
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(field.name, event.target.value);
                        props.updateCallback({
                            rufnummer: event.target.value
                        });
                    }}
                    value={field.value}
                />
            </ControlWithHint>);
        }}>
    </Field>);
};

export default PersonalData;
