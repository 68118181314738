import * as React from 'react';
import './DisclaimerInfoBox.css';

const DisclaimerInfoBox = () => {
    return <div className="esc_grid disclaimer-info-box">
        <div className="esc_grid__wrapper" style={{textAlign: 'center', float: 'left', marginBottom: '2em'}}>
            <div className="esc_col esc_col-12 esc_col-m-2"/>
            <div className="esc_col esc_col-12 esc_col-m-2">
                <div className="disclaimer-info-box-circle">
                    30 Tage<br/>Widerruf
                </div>
            </div>
            <div className="esc_col esc_col-12 esc_col-m-6 " style={{
                fontSize: '14px',
                textAlign: 'left',
                margin: 'auto'}}>
                Sie können <b>bis zu 30 Tage</b> nach Erhalt der Versicherungsunterlagen Ihren Vertrag ohne
                Nennung von Gründen widerrufen.
            </div>
            <div className="esc_col esc_col-12 esc_col-m-2"/>
        </div>
    </div>;
};

export default DisclaimerInfoBox;
