const AKTIONSNUMMER_COOKIENAME: string = 'aktionNr=';
const OENUMBER_COOKIENAME: string = 'oenrCookie=';

export const extractAktionsNummerFromCookie = (): string => {
    const decodedCookieString = decodeURIComponent(document.cookie);
    const cookieList: string[] = decodedCookieString.split(';');
    const aktionsNummerCookie: string | undefined = cookieList.find(element => element.includes(AKTIONSNUMMER_COOKIENAME));
    if (aktionsNummerCookie) {
        const aktionsNummernListe: string | undefined = aktionsNummerCookie.split('=')[1];
        if (aktionsNummernListe) {
            return aktionsNummernListe.split(',')[0];
        }
    }
    return '';
};

export const extractOeNumberFromCookie = (): string | undefined => {
    const decodedCookieString = decodeURIComponent(document.cookie);
    const cookieList: string[] = decodedCookieString.split(';');
    const oeNumberCookie: string | undefined = cookieList.find(element => element.includes(OENUMBER_COOKIENAME));
    if (oeNumberCookie) {
        return oeNumberCookie.split('=')[1];
    }
    return undefined;
};
