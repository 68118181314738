import { Message } from 'stg-common';

// Unfortunately fields coming from the backend for errors
// doesn't use the same texts than the variants labels in the front end
const VARIANT_BASED_FIELDS = {
  basis: 0,
  komfort: 1,
  premium: 2
};

const SHOWN_ERRORS_CODES = [ 'INVALID_TODESFALLLEISTUNGZUBEGINN' ];

export const errorMessageFunction = (messages: Message[] | undefined, selectedVariant?: number): string[] => {
  // Use a Map to store unique error messages associated with their codes
  const uniqueErrors = new Map<string, string>();

  if (messages === undefined) {
    return [];
  }
  for (const message of messages) {
    const specificVariantError = message.fields.find(
      (field: string) => VARIANT_BASED_FIELDS[field] !== undefined
    );
    const forSelectedVariant =
      specificVariantError !== undefined &&
      VARIANT_BASED_FIELDS[specificVariantError] === selectedVariant;

    // if the error is specific to a variant, add it only if it's the selected one
    if (
      selectedVariant === undefined ||
      specificVariantError === undefined ||
      forSelectedVariant
    ) {
      // Check if the code is not already in the Map before adding the message
      if (
        !uniqueErrors.has(message.code) &&
        message.level === 'ERROR' &&
        SHOWN_ERRORS_CODES.includes(message.code)
      ) {
        uniqueErrors.set(message.code, message.message);
      }
    }
  }

  // Convert the Map values (unique error messages) into an array
  const uniqueErrorMessages = Array.from(uniqueErrors.values());

  return uniqueErrorMessages;
};
