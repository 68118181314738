import * as React from 'react';
import FeedbackPage, { FeedbackPageData } from '../../pages/FeedbackPage';
import { getOfferPageData } from '../../services/api';
import { StateDefinition } from '../StateMachine';
import { StateName } from '../StateMachineTypes';

export const feedbackPage: StateDefinition<FeedbackPageData> = {
    name: StateName.FEEDBACK_PAGE,

    validInboundStates: [
        StateName.INSURANCE_DOCUMENTS_PAGE
    ],
    transitions: [],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'feedback');

        return {
            userInput: {
                anrede: response.anrede,
                vorname: response.vorname,
                nachname: response.nachname,
                messages: response.messages,
                businessId: response.businessId,
                policyHolderId: response.policyHolderId
            }
        };
    },
    onExit: async (transitionDetails, inputData) => {
        return {};
    },
    render: (inputData, handleAction, updateApp) => {
        return <FeedbackPage
            storeState={{
                anrede: inputData.userInput.anrede,
                vorname: inputData.userInput.vorname,
                nachname: inputData.userInput.nachname,
                businessId: inputData.userInput.businessId,
                policyHolderId: inputData.userInput.policyHolderId,
                update: values => updateApp(values)
            }}
        />;
    }
};
