import TooltipIcon from '@eg/elements/TooltipIcon';
import * as React from 'react';

export interface TooltipProps {
  heading?: string;
  desc?: string;
}

const TooltipComponent = (props: TooltipProps) => {
  return (
    <TooltipIcon>
    {props.heading && <b style={{display: 'block', marginBottom: '10px'}}>{props.heading}</b>}
      {props.desc}
    </TooltipIcon>
  );
};

export default TooltipComponent;
