import { FormikProps } from 'formik';
import { IsoDateString } from 'stg-common';
import { PAYMENT_METHOD_SINGLE_FEE } from '../constants';
import { FeePageData } from '../pages/FeePage';
import { PaymentPeriodPageData } from '../pages/PaymentPeriodPage';
import formatter, { EURO_WITH_CENT } from './currencyFormatter';
import { calculateAgeAtPaymentPeriodEnd } from './dateHelper';

export const isAgeAtPaymentPeriodEndOverEightyfour = (form: FormikProps<FeePageData | PaymentPeriodPageData>, birthdate: IsoDateString,
                                                      insuranceBegin: IsoDateString) => {
    const maxAgeForAllTarifOptions = 84;
    return calculateAgeAtPaymentPeriodEnd(birthdate, insuranceBegin, form.values.paymentPeriod || 0) > maxAgeForAllTarifOptions;
};

export const feePerPaymentMethod = (fee?: number, paymentMethod?: string) => {
    return `${formatter(EURO_WITH_CENT).format(fee || 0)} / ${mapPaymentMethod(paymentMethod)}`;
};

const mapPaymentMethod = (paymentMethod?: string): string | undefined => {
    switch (paymentMethod) {
        case 'MONATLICH':
            return 'Monat';
        case 'VIERTELJAEHRLICH':
            return 'Quartal';
        case 'HALBJAEHRLICH':
            return 'Halbjahr';
        case 'JAEHRLICH':
            return 'Jahr';
        case PAYMENT_METHOD_SINGLE_FEE:
            return 'Einmalzahlung';
        default:
            return undefined;
    }
};

export const mapPaymentMethodToText = (paymentMethod: string | undefined): string => {
    switch (paymentMethod) {
        case 'MONATLICH':
            return 'monatlich';
        case 'VIERTELJAEHRLICH':
            return 'vierteljährlich';
        case 'HALBJAEHRLICH':
            return 'halbjährlich';
        case 'JAEHRLICH':
            return 'jährlich';
        case PAYMENT_METHOD_SINGLE_FEE:
            return 'Einmalzahlung';
        default:
            return 'n./a.';
    }
};

export const mapPaymentMethodToAdjective = (paymentMethod: string | undefined): string => {
    switch (paymentMethod) {
        case PAYMENT_METHOD_SINGLE_FEE:
            return 'einmalig';
        case 'MONATLICH':
        case 'VIERTELJAEHRLICH':
        case 'HALBJAEHRLICH':
        case 'JAEHRLICH':
            return `${mapPaymentMethodToText(paymentMethod)}`;
        default:
            return `n./a.`;
    }
};
