export const SESSION_STORAGE_BUSINESS_ID_KEY = 'STGBusinessId';
export const SESSION_STORAGE_CURRENT_STATE_KEY = 'STGCurrentState';
export const SESSION_STORAGE_APP_DATA = 'AppData';
export const SESSION_STORAGE_OFFER_BACK_STATE = 'STGOfferBackState';
export const SESSION_STORAGE_SUBMIT_LOADING_STEP = 'STGSubmitLoadingStep';

export class Storage {
  public static writeItem = (key: string, value?: string) => {
    try {
      if (value === undefined || value === null) {
        return;
      }

      window.sessionStorage.setItem(key, value);
    } catch (e) {
      console.error('failed to write to storage', (e as Error).message);
    }
  };

  public static readItem = (key: string): string | undefined => {
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? item : undefined;
    } catch (e) {
      console.error('Failed to read from storage:', (e as Error).message);
      return undefined;
    }
  };

  public static clear = (): void => {
    try {
      window.sessionStorage.clear();
    } catch (e) {
      console.error('failed to clear storage', (e as Error).message);
    }
  };
}
