import { IsoDateString, parseDate, ValueRanges } from 'stg-common';
import { mixed, object, Schema } from 'yup';
import { CustomMixedSchema, isOlderThanInYear, isYoungerThanInYear } from './date';

export function createBirthdatePageSchema(valueRanges: ValueRanges): Schema<{}> {
    const insuranceBegin = valueRanges.insuranceStart.possibleValues;
    insuranceBegin.sort();
    const lowestInsuranceBegin = insuranceBegin[0];
    const highestInsuranceBegin = insuranceBegin[insuranceBegin.length - 1];
    return object().shape({
        birthdate:
            (mixed() as CustomMixedSchema)
                .required('Bitte geben Sie das Geburtsdatum der zu versichernden Person an.')
                .isValidIsoDateFormat()
                .test('olderThan', `Die zu versichernden Person muss im Jahr des Versicherungsbeginns mindestens ${valueRanges.ageAtEntry.min} Jahre alt sein, 
                damit Sie diese Versicherung abschließen können.`,
                    (birthday: IsoDateString) => {
                        const insuranceBeginDate = parseDate(highestInsuranceBegin) || new Date();
                        return isOlderThanInYear(insuranceBeginDate, birthday, valueRanges.ageAtEntry.min);
                    })
                .test('youngerThan', value => {
                        return `Diese Versicherung kann nur bis zu einem Alter von 85 Jahren abgeschlossen werden. Bei Fragen rufen Sie an.`;
                    },
                    (birthday: IsoDateString) => {
                        const insuranceBeginDate = parseDate(lowestInsuranceBegin) || new Date();
                        return isYoungerThanInYear(insuranceBeginDate, birthday, valueRanges.ageAtEntry.max + 1);
                    })
    });
}
