import * as React from 'react';
import { IconProps } from './IconTypes';

export const ExklusivesBestattungspaketIcon = (props: IconProps): JSX.Element => {
    return <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                x="0px" y="0px" viewBox="0 0 232 71" {...props}>
        <g
            transform="translate(146.687907, 32.618295) rotate(-9.000000) translate(-146.687907, -32.618295) translate(125.687907, 11.618295)">
            <path fill="none" d={`M22.5,18.6l5.4-5.4c-3.1-2-7.2-1.6-9.9,1l-2.4,2.4c1.5,2.5,1.1,5.7-1,7.7s-5.2,2.5-7.7,1l-2.4,2.4
                c-2.6,2.6-3.1,6.8-1,9.9l7.3-7.3l2.3,2.3l-7.3,7.3c3.1,2,7.2,1.6,9.9-1l2.4-2.4c-1.5-2.5-1.1-5.7,1-7.7c2-2.1,5.2-2.5,7.7-1
                l2.4-2.4l0,0c2.6-2.6,3.1-6.7,1-9.9l-5.4,5.4L22.5,18.6z`}/>
            <path strokeWidth="0.5913" strokeLinejoin="round" d={`M39,9.7l2.3-2.3l-4.1-4.1l-2.3,2.3l0.9,0.9l-1.1,1.1l-2.3,2.3L31.3,11c-4.4-3.3-10.6-2.8-14.5,1.1
                l-4.5,4.5l1.1,1.1c0.9,1.2,0.8,3-0.3,4.1C12,22.9,10.3,23,9,22.1L7.9,21l-4.5,4.5C-1,29.8-1,36.9,3.4,41.2s11.4,4.4,15.7,0l4.5-4.5
                l-1.1-1.1c-1-0.7-1.4-2-1.2-3.2c0.3-1.2,1.2-2.1,2.4-2.4c1.2-0.3,2.4,0.2,3.2,1.2l1.1,1.1l4.5-4.5c3.9-3.9,4.3-10.1,1.1-14.5
                l1.1-1.1l2.3-2.3l1.1-1.1L39,9.7z M30.2,25.5l-2.4,2.4c-2.5-1.5-5.7-1-7.7,1s-2.5,5.2-1,7.7l-2.4,2.4c-2.6,2.6-6.8,3.1-9.9,1
                l3.6-3.4l-2.3-2.3l-3.6,3.4c-2-3.1-1.6-7.3,1-9.9L8,25.4c2.5,1.5,5.7,1,7.7-1s2.5-5.2,1-7.7l2.4-2.4c2.6-2.6,6.7-3.1,9.9-1
                l-3.3,3.2l2.3,2.3l3.3-3.2C33.3,18.8,32.9,22.9,30.2,25.5z`}/>
        </g>
        <g transform="translate(71.400000, 25.932633)">
            <path strokeWidth="0.887" d={`M17.1,3.5c2.6,1.2,4.6,3.4,5.6,6c0.8-0.2,1.7-0.3,2.6-0.3c0.6,0,1.2,0,1.8,0.1
                c1.5,0.2,2.7,1.4,2.8,2.9c0,0.3,0.1,0.7,0.1,1c0,5.8-5,10.6-11.1,10.6c-0.6,0-1.2,0-1.8-0.1l-0.2,0l-0.1,19.1l-2.8,0l0-4.4
                c-0.2,0-0.4,0-0.6,0c-5,0-9.1-3.9-9.1-8.7c0-0.3,0-0.6,0-0.8c0.1-0.9,0.8-1.7,1.8-1.8c0.5-0.1,1-0.1,1.5-0.1c2.5,0,4.7,1,6.4,2.5
                l0-5.7c-0.1,0-0.1,0-0.2,0c-0.6,0.1-1.2,0.1-1.8,0.1C6,23.9,1.1,19.2,1.1,13.3c0-0.3,0-0.7,0.1-1c0.1-1.5,1.3-2.6,2.8-2.9
                c0.6-0.1,1.2-0.1,1.8-0.1c1,0,1.9,0.1,2.8,0.3c0.9-2.6,2.9-4.8,5.6-6.1C15,3.1,16.1,3.1,17.1,3.5z M7.7,29.6c-0.2,0-0.4,0-0.5,0
                l0,0.1l0,0c0,3.3,2.8,6,6.3,6c0.2,0,0.4,0,0.5,0c0,0,0-0.1,0-0.1C14,32.3,11.2,29.6,7.7,29.6z M25.2,11.7c-0.6,0-1.3,0.1-1.9,0.2
                l0,0l-2,0.9c-1.6,0.7-2.8,2-3.6,3.5l-1.1,2.3c0,0.1,0,0.1,0,0.2c0,0.4,0.1,0.7,0.1,1.1c0,0.3,0,0.7,0,1c0.1,0.3,0.4,0.5,0.7,0.5
                c0.5,0.1,0.9,0.1,1.4,0.1c4.7,0,8.6-3.7,8.6-8.2c0-0.3,0-0.5,0-0.8c0-0.4-0.3-0.7-0.7-0.7C26.1,11.7,25.7,11.7,25.2,11.7z
                M5.7,11.7c-0.5,0-0.9,0-1.4,0.1c-0.4,0.1-0.7,0.4-0.7,0.7c0,0.3,0,0.5,0,0.8c0,4.5,3.8,8.2,8.6,8.2c0.5,0,0.9,0,1.4-0.1
                c0.3,0,0.6-0.3,0.7-0.5c0-0.3,0-0.7,0-1c0-0.4,0-0.7,0.1-1.1c0-0.3-0.1-0.6-0.2-0.9l-0.8-1.7c-0.7-1.5-2-2.8-3.6-3.5l-2-0.9l0,0
                C7.1,11.8,6.4,11.7,5.7,11.7z M15.2,5.7c-2.1,1-3.6,2.7-4.3,4.8c2,1,3.6,2.5,4.6,4.4c1.1-1.9,2.7-3.5,4.8-4.4
                c-0.7-2-2.3-3.8-4.3-4.7C15.7,5.6,15.4,5.6,15.2,5.7z`}/>
        </g>
        <g transform="translate(164.586320, 8.193503)">
            <path d={`M61.4,30.7c3.6,3,5.3,7.8,5.3,14.3c0,3.7-0.3,6.7-0.8,9.1c-0.9,3.7-4.2,6.3-8,6.3h-19
                c-0.9,0-1.8-0.2-2.7-0.4c1.1-1.2,2.1-2.6,2.9-4.2l18.7,0c1.6,0,3.1-1.1,3.4-2.7c0.5-2,0.7-4.7,0.7-8.1c0-5.3-1.2-8.8-3.6-10.8
                c-0.1-0.1-0.4-0.2-0.5-0.1l-5.6,1.6c-3.5,1-7.2,0.9-10.6-0.4l-2.9-1.1c-0.8-1.5-1.9-2.8-3-3.9c1.4-0.8,3.1-1,4.6-0.4l3,1.1
                c2.5,0.9,5.1,1,7.7,0.3l5.6-1.6C58.2,29.2,60,29.6,61.4,30.7z M48.3,3.4C55.4,3.4,61,9,61,16c0,6.9-5.5,12.5-12.4,12.6l-0.3,0
                c-7,0-12.7-5.7-12.7-12.6C35.6,9,41.3,3.4,48.3,3.4z M54.7,10.7c-0.8,0.8-1.9,1.5-3.3,1.9c-1.9,0.6-3.3,0.8-5.9,0.8
                c-1.1,0-1.5,0-2.1,0.1c-1.2,0.1-2.1,0.4-3.2,0.9C40.1,14.9,40,15.5,40,16c0,4.5,3.7,8.2,8.3,8.2c4.6,0,8.3-3.7,8.3-8.2
                C56.6,14,55.9,12.2,54.7,10.7z M51.5,15.1c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6
                C49.8,15.9,50.6,15.1,51.5,15.1z M45.3,15.1c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
                C43.7,15.9,44.4,15.1,45.3,15.1z`}/>
            <path d={`M32.1,30.7c3.6,3,5.3,7.8,5.3,14.3c0,3.7-0.3,6.7-0.8,9.1c-0.9,3.7-4.2,6.3-8,6.3h-19
		c-3.6,0-6.7-2.3-7.8-5.6c-1.1-3.3-1.5-7.1-1.2-11.4c0.3-5.4,2-9.6,4.9-12.4C7,29.7,9.1,29.3,11,30l3,1.1c2.5,0.9,5.1,1,7.7,0.3
		l5.6-1.6C29,29.2,30.8,29.6,32.1,30.7z M28.6,34.2l-5.6,1.6c-3.5,1-7.2,0.9-10.6-0.4l-3-1.1c-0.2-0.1-0.4,0-0.6,0.1
		c-2,1.9-3.2,5-3.5,9.3c-0.2,3.7,0.1,6.9,1,9.6c0.5,1.4,1.8,2.4,3.3,2.4h19c1.6,0,3.1-1.1,3.4-2.7c0.5-2,0.7-4.7,0.7-8.1
		c0-5.3-1.2-8.8-3.6-10.8C29,34.2,28.8,34.1,28.6,34.2z M18.2,3c7,0,12.7,5.6,12.7,12.6c0,0.1,0,0.1,0,0.2l0,0.1c0,0,0,0.1,0,0.2
		c0,0.1,0,0.1,0,0.2c0.1,2.1,0.6,4.1,1.4,5.9c0.3,0.7,0.6,1.3,1,1.8c0.4,0.6,0,1.1-0.8,1.4c-0.6,0.2-1.4,0.3-2.4,0.4
		C28.7,26,26.9,26,25.4,26c-2.1,1.4-4.6,2.2-7.2,2.2c-2.7,0-5.1-0.8-7.2-2.2c-1.5,0-3.3,0-4.7-0.2c-1-0.1-1.9-0.2-2.4-0.4
		C3,25.1,2.6,24.7,3,24.1c0.4-0.6,0.7-1.1,1-1.9c0.9-1.9,1.3-3.8,1.4-5.9c0-0.1,0-0.2,0-0.4c0-0.2,0-0.2,0-0.2l0-0.1
		C5.5,8.6,11.2,3,18.2,3z M24.6,9.3l-0.3,0.3c-0.8,0.9-1.8,1.5-3.2,2c-1.8,0.6-3.1,0.7-5.7,0.7c-1.1,0-1.5,0-2.1,0.1
		c-1.2,0.1-2.3,0.4-3.5,1c0,0,0,0,0,0l-0.1,0l0,0l-0.1,0l-0.2,0.3c-0.1,0.7-0.2,1.3-0.2,2c0,4.9,4.1,9,9,9s9-4,9-9
		c0-2.2-0.8-4.2-2.1-5.8l-0.2-0.3L24.6,9.3z M15.2,14.8c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
		C13.5,15.5,14.3,14.8,15.2,14.8z M21.3,14.8c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6
		C19.7,15.5,20.4,14.8,21.3,14.8z`}/>
        </g>
        <g transform="translate(40, 0)">
            <path d={`M86.6,0.5c1.1,0,2.1,0.8,2.3,1.8L93.8,22c0.1,0.3,0.1,0.6,0.1,0.9L89,65.7
	c-0.1,1.2-1.2,2.1-2.4,2.1H62.2c-1.2,0-2.3-0.9-2.4-2.1L55,22.8c0-0.3,0-0.6,0.1-0.9l4.9-19.6c0.3-1.1,1.2-1.8,2.3-1.8H86.6z
	 M84.7,5.3H64.1l-4.3,17.4L64.4,63h20.1L89,22.7L84.7,5.3z`}/>
        </g>
        <g transform="translate(0.000000, 7.754586)">
            <path d={`M30.8,38.8c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5
		c0.4,0,0.8,0.2,1.1,0.4C30.7,38,30.8,38.4,30.8,38.8 M34.4,40.3c0.6,0,1.2-0.4,1.4-0.9c0.2-0.6,0.1-1.2-0.3-1.7
		c-0.4-0.4-1.1-0.6-1.7-0.3c-0.6,0.2-0.9,0.8-0.9,1.4C32.9,39.7,33.5,40.3,34.4,40.3 M54.7,29.7c-0.6,0-1.2,0.1-1.7,0.4
		c-2.2,1.1-4.5,1.6-7,1.6c-1.1,0-2.2-0.1-3.3-0.4c0.9,1.3,1.6,2.8,1.9,4.3c0.5,0,0.9,0.1,1.4,0.1c3,0,5.9-0.7,8.6-2
		c1.4,1.5,2.4,3.3,3.1,5.3c0.8,2.4,1.2,5.3,1.2,8.9c0,1.7-0.2,3.4-0.5,5c-0.3,1.7-1.9,2.9-3.8,2.9H50c-0.1,1.3-0.4,2.6-0.8,3.8
		c0,0.1-0.1,0.2-0.1,0.3h5.7c3.8,0,7-2.6,7.7-6.2C62.8,52,63,50,63,48.1c0-8.5-2.1-13.2-5.4-17C56.9,30.2,55.8,29.7,54.7,29.7
		 M14.7,59.9c0,0.1,0,0.1,0.1,0.2H8.9c-3.8,0-7-2.6-7.8-6.2C0.8,51.9,0.6,50,0.6,48c0-8.5,2.1-13.2,5.4-17c0.7-0.8,1.8-1.3,2.9-1.3
		c0.6,0,1.2,0.1,1.7,0.4c3.2,1.6,6.8,2,10.3,1.3c-0.9,1.3-1.6,2.8-1.9,4.4c-0.5,0-0.9,0.1-1.4,0.1c-3,0-5.9-0.7-8.6-2
		c-1.4,1.5-2.4,3.3-3.1,5.3c-0.8,2.4-1.2,5.3-1.2,8.9c0,1.7,0.2,3.4,0.5,5C5.5,54.8,7.1,56,9,56h4.8C13.8,57.3,14.1,58.7,14.7,59.9
		 M44.3,46.3c2.6,4,3.4,9,2,12.4c-0.3,0.8-1.1,1.3-1.9,1.4h-25c-0.8,0-1.6-0.5-1.9-1.3c-1.5-3.3-0.7-8.3,1.8-12.2
		c0.3-0.5,0.7-1,1-1.4c0.7,1.2,1.5,2.4,2.6,3.3c-0.1,0.1-0.1,0.2-0.2,0.3c-1.7,2.6-2.1,5.5-1.8,7.3h22c0.3-1.9-0.2-4.8-2-7.5
		l-0.1-0.1c1-1,1.9-2.1,2.6-3.3C43.7,45.5,44,45.9,44.3,46.3 M46,27.7c6.7,0,12.1-5.4,12.1-12.1S52.7,3.4,46,3.4S33.9,8.8,33.9,15.5
		S39.3,27.7,46,27.7 M37.7,13.7l0.1-0.1c2-1,3.6-1,5.4-1c1.6,0,3.5,0,5.7-0.7c1.3-0.4,2.4-1.1,3.3-2.1c2.6,2.8,3,7,1,10.2
		c-2,3.2-5.9,4.7-9.6,3.7c-3.7-1-6.2-4.4-6.2-8.2C37.5,14.9,37.6,14.3,37.7,13.7 M10.7,25.5c4.2,3,9.8,3,14,0c1.5,0,8.5,0,7.6-1.4
		c-0.4-0.6-0.7-1.2-1-1.9c-0.9-1.9-1.3-3.9-1.4-5.9c0-0.3,0-0.6,0-0.7c0,0,0,0,0,0c0.1-4.4-2.1-8.6-6-10.8s-8.5-2.3-12.4,0
		s-6.1,6.4-6,10.8v0.1c0,0.2,0,0.4,0,0.7c-0.1,2.1-0.6,4.1-1.4,5.9c-0.3,0.7-0.6,1.3-1,1.9C2.1,25.5,9.1,25.5,10.7,25.5 M9.4,13.6
		l0.1-0.1c2-1,3.6-1,5.4-1c1.6,0,3.5,0,5.7-0.7c1.3-0.4,2.4-1.1,3.3-2.1c2.7,3,3,7.5,0.6,10.7s-6.7,4.4-10.4,2.7S8.5,17.6,9.4,13.6
		 M21.7,38.8c0,4.1,2.5,7.8,6.2,9.4c3.8,1.6,8.1,0.7,11-2.2c2.9-2.9,3.8-7.2,2.2-11s-5.3-6.2-9.4-6.2c-2.7,0-5.3,1.1-7.2,3
		C22.8,33.5,21.7,36.1,21.7,38.8 M38.3,38.8c0,3.6-2.9,6.5-6.5,6.5c-3.6,0-6.5-2.9-6.5-6.5c0-3.6,2.9-6.5,6.5-6.5
		C35.4,32.3,38.3,35.2,38.3,38.8 M20.7,18.1c0.6,0,1.2-0.4,1.4-0.9c0.2-0.6,0.1-1.2-0.3-1.7c-0.4-0.4-1.1-0.6-1.7-0.3
		c-0.6,0.2-0.9,0.8-0.9,1.4c0,0.4,0.2,0.8,0.4,1.1C19.9,17.9,20.3,18.1,20.7,18.1 M41.4,16.6c0-0.6,0.4-1.2,0.9-1.4s1.2-0.1,1.7,0.3
		c0.4,0.4,0.6,1.1,0.3,1.7c-0.2,0.6-0.8,0.9-1.4,0.9C42.1,18.1,41.4,17.4,41.4,16.6 M13.1,16.6c0-0.6,0.4-1.2,0.9-1.4
		s1.2-0.1,1.7,0.3c0.4,0.4,0.6,1.1,0.3,1.7c-0.2,0.6-0.8,0.9-1.4,0.9c-0.4,0-0.8-0.2-1.1-0.4S13.1,17,13.1,16.6 M47.5,16.6
		c0-0.6,0.4-1.2,0.9-1.4c0.6-0.2,1.2-0.1,1.7,0.3c0.4,0.4,0.6,1.1,0.3,1.7c-0.2,0.6-0.8,0.9-1.4,0.9C48.2,18.1,47.5,17.4,47.5,16.6`}/>
        </g>
    </svg>;
};
