import { AemQuellsystem } from 'stg-common';
import { StateName } from '../routing/StateMachineTypes';
import { getMakler } from '../services/api';
import { trackPageTransition } from '../tracking/tracker';
import { getTelephone, getTiedAgentOeNumber, getTiedAgentPnrNumber, quellsystem } from './aemHelper';
import { extractOeNumberFromCookie } from './cookieExtractor';
import { validateMakler } from './maklerHelper';
import { getQueryValue } from './QueryParameterExtractor';
import { SESSION_STORAGE_APP_DATA, SESSION_STORAGE_BUSINESS_ID_KEY, Storage } from './Storage';

enum AppMode {
    TIED_AGENT = 'TIED_AGENT',
    ERGO_INTERNET = 'ERGO_INTERNET',
    MAKLER = 'MAKLER'
}

const getAPPMode  = (signature: string) => {
    // Calculate if app is makler
    if (validateMakler(signature)) {
        return AppMode.MAKLER;
    }

    // check if app tiedAgent
    if (quellsystem === AemQuellsystem.ERGO_VERMITTLERHOMEPAGE) {
        return AppMode.TIED_AGENT;
    }

    return AppMode.ERGO_INTERNET;
};

export interface ModeConfig {
    mode: AppMode;
    data: ModData;
}

interface ModData {
    email: string;
    telephone: string;
    oeNumber: string;
}

const dataMapper = (oeNumber: string = '', telephone: string = '', email: string = ''): ModData => ({
    email,
    telephone,
    oeNumber
});

export const modeConfig = async (): Promise<ModeConfig>  => {
    const signature = getQueryValue('signature');
    const businessId = Storage.readItem(SESSION_STORAGE_BUSINESS_ID_KEY) || '';
    const mode = getAPPMode(signature);
    let oeNumber;
    let data: ModData;

    switch (mode) {
        case AppMode.MAKLER:
            const urlParam = getQueryValue('adh_oenr');
            const makler =  await getMakler(businessId, signature);
            oeNumber = urlParam ||  makler.oeNr || extractOeNumberFromCookie() || '';
            data = dataMapper(oeNumber, makler.phone, makler.email);
            break;

        case AppMode.TIED_AGENT:
            oeNumber = getTiedAgentOeNumber;
            data = dataMapper(oeNumber, getTelephone);
            break;

        default:
            oeNumber = '';
            data = dataMapper(oeNumber);
            break;
    }

    trackPageTransition(
        mode === AppMode.MAKLER ? StateName.INTRODUCTION_PAGE : StateName.BIRTHDATE_PAGE,
        mode === AppMode.MAKLER,
        mode === AppMode.TIED_AGENT,
        oeNumber,
        getTiedAgentPnrNumber
    );

    return {
        mode,
        data
    };
};

const getAppStoredData = (): ModeConfig => JSON.parse(Storage.readItem(SESSION_STORAGE_APP_DATA) ||
    JSON.stringify({mode: getAPPMode(''), data: dataMapper()}));

export const getIsMakler = () => getAppStoredData().mode === AppMode.MAKLER;

export const getIsTiedAgent = () => getAppStoredData().mode === AppMode.TIED_AGENT;

export const getIsErgoInternet = () => getAppStoredData().mode === AppMode.ERGO_INTERNET;

export const getAppMode = () => getAppStoredData().mode;

export const getOeNumber = () => getAppStoredData().data.oeNumber;

export const getAgentEmail = () => getAppStoredData().data.email;

export const getAgentPhone = () => getAppStoredData().data.telephone;
