import { object, Schema, string } from 'yup';
import { createPersonalDataSchema } from './PersonalDataSchema';
// tslint:disable-next-line:no-var-requires
require('./date.ts'); // required for CustomMixedSchema to work

export function createEntitledPersonSchema(): Schema<{}> {
    return createPersonalDataSchema(false, false, false).concat(object().shape({
        relationship: string().trim().required('Bitte wählen Sie ein Verwandtschaftsverhältnis aus.'),
        relationshipFreeText: string().trim().required('Bitte geben Sie ein Verwandtschaftsverhältnis ein')
    }));
}
