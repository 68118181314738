import * as React from 'react';
import { CalculationMode } from 'stg-common';
import InsuranceBeginPage, { InsuranceBeginPageData } from '../../pages/InsuranceBeginPage';
import { getOfferPageData, updateInsuranceBeginPageData } from '../../services/api';
import { StateDefinition } from '../StateMachine';
import { CHECKOUT_PAGES, FEE_PAGES, NavigationAction, PERSONAL_INFORMATION_PAGES, StateName } from '../StateMachineTypes';

export const insuranceBeginPage: StateDefinition<InsuranceBeginPageData> = {
    name: StateName.INSURANCE_START_PAGE,

    validInboundStates: [
        ...CHECKOUT_PAGES,
        ...PERSONAL_INFORMATION_PAGES,
        ...FEE_PAGES,
        StateName.BIRTHDATE_PAGE,
        StateName.INSURANCE_SUM_PAGE,
        StateName.INSURANCE_FEE_PAGE
    ],
    transitions: [
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.NEXT && data.userInput.calculationMode === CalculationMode.INSURANCE_FEE,
            newState: StateName.INSURANCE_FEE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.NEXT,
            newState: StateName.INSURANCE_SUM_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'insuranceBegin');

        return {
            userInput: {
                insuranceBegin: response.insuranceBegin,
                birthdate: response.birthdate,
                calculationMode: response.calculationMode,
                messages: response.messages
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        return {
            payload: await updateInsuranceBeginPageData(inputData.businessId, inputData.userInput)
        };
    },
    render: (inputData, handleAction, updateApp) => {
        return <InsuranceBeginPage
            storeState={{
                businessId: inputData.businessId,
                insuranceBegin: inputData.userInput.insuranceBegin,
                birthdate: inputData.userInput.birthdate,
                calculationMode: inputData.userInput.calculationMode,
                update: (values, callback) => updateApp(values, callback)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};
