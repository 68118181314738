import { StateDefinition } from '../StateMachine';
import { bankingDetailsPage } from './bankingDetailsPage';
import { birthdatePage } from './birthdatePage';
import { burialPackagePage } from './burialPackagePage';
import { confidentialityReleasePage } from './confidentialityReleasePage';
import { divergingInsuredPersonPage } from './divergingInsuredPersonPage';
import { entitledPersonsPage } from './entitledPersonsPage';
import { feedbackPage } from './feedbackPage';
import { feeDynamicPage } from './feeDynamicPage';
import { feePage } from './feePage';
import { flexOptionPage } from './flexOptionPage';
import { insuranceBeginPage } from './insuranceBeginPage';
import { insuranceDocumentsPage } from './insuranceDocumentsPage';
import { insuranceFeePage } from './insuranceFeePage';
import { insuranceSummaryPage } from './insuranceSummaryPage';
import { insuranceSumPage } from './insuranceSumPage';
import { insuredPersonPage } from './insuredPersonPage';
import { introductionPage } from './introductionPage';
import { paymentPeriodPage } from './paymentPeriodPage';
import { policyHolderPage } from './policyHolderPage';
import { requestOfferCheckoutPage } from './RequestOfferRoutes/requestOfferCheckoutPage';
import { requestOfferDivergingInsuredPersonPage } from './RequestOfferRoutes/requestOfferDivergingInsuredPersonPage';
import { requestOfferFeedbackPage } from './RequestOfferRoutes/requestOfferFeedbackPage';
import { requestOfferInsuredPersonPage } from './RequestOfferRoutes/requestOfferInsuredPersonPage';
import { requestOfferPolicyHolderPage } from './RequestOfferRoutes/requestOfferPolicyHolderPage';

// tslint:disable-next-line:no-any
export const stateMachineStates: Array<StateDefinition<any>> = [
    bankingDetailsPage,
    birthdatePage,
    burialPackagePage,
    confidentialityReleasePage,
    divergingInsuredPersonPage,
    entitledPersonsPage,
    feedbackPage,
    feeDynamicPage,
    feePage,
    flexOptionPage,
    insuranceBeginPage,
    insuranceDocumentsPage,
    insuranceFeePage,
    insuranceSummaryPage,
    insuranceSumPage,
    insuredPersonPage,
    introductionPage,
    paymentPeriodPage,
    policyHolderPage,
    requestOfferCheckoutPage,
    requestOfferDivergingInsuredPersonPage,
    requestOfferFeedbackPage,
    requestOfferInsuredPersonPage,
    requestOfferPolicyHolderPage
];
