import Button from '@eg/elements/Button';
import Card from '@eg/elements/Card';
import { AlertIcon, EditIcon, TrashbinIcon } from '@eg/elements/components/Icons';
import * as React from 'react';
import { mapToGermanDate, Nationality } from 'stg-common';
import { mapAddressesToPrintableText } from '../../helpers/addressesHelper';
import { EntitledPersonViewModel } from '../../pages/EntitledPersonsPage';
import { trackElementClickImmediate } from '../../tracking/tracker';
import { TrackingElement } from '../../tracking/trackingConstants';

export interface ViewEntitlementProps {
    headline: string;
    entitledPerson: EntitledPersonViewModel;
    nationalities?: Nationality[];
    isEditable: boolean;
    deleteCallback: () => Promise<void>;
    editCallback: () => Promise<void>;
}

export interface ViewEntitlementState {
    deleteLoading: boolean;
    editLoading: boolean;
    errorMessage: string;
}

class ViewEntitlement extends React.Component<ViewEntitlementProps, ViewEntitlementState> {

    constructor(props: ViewEntitlementProps) {
        super(props);
        this.state = {
            deleteLoading: false,
            editLoading: false,
            errorMessage: ''
        };
    }

    private resolveCountry(country?: string) {
        const nationality = this.props.nationalities!.find(nation => nation.key === country);
        return nationality ? nationality.text : country;
    }

    public render() {
        const bezugsberechtigtePerson = this.props.entitledPerson;
        return (
            <Card padding="normal">
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-12 esc_col-m-7" style={{borderTopWidth: '10px', borderBottomWidth: '0px'}}>
                            <h4 style={{margin: '.5em 0'}}>{this.props.headline}</h4>

                            <small>
                                <div data-component-id="view-entitlement-name" style={{lineHeight: '20px'}}>
                                    {mapAddressesToPrintableText(bezugsberechtigtePerson.anrede)}{' '}
                                    {bezugsberechtigtePerson.vorname}{' '}
                                    {bezugsberechtigtePerson.nachname}{' '}
                                    <span style={{color: '#737373', marginLeft: '2em'}}>
                                    {mapToGermanDate(bezugsberechtigtePerson.birthdate)}
                                    </span>
                                </div>
                                <div style={{lineHeight: '20px'}} data-component-id="view-entitlement-relationship">
                                    {bezugsberechtigtePerson.relationshipFreeText}
                                </div>
                                <div style={{lineHeight: '20px'}} data-component-id="view-entitlement-address">
                                    {bezugsberechtigtePerson.adresse.strasse} {bezugsberechtigtePerson.adresse.hausnummer}{' '}
                                    {bezugsberechtigtePerson.adresse.plz}{' '}
                                    {bezugsberechtigtePerson.adresse.ort},{' '}
                                    {this.resolveCountry(bezugsberechtigtePerson.adresse.land)}
                                </div>
                                {(bezugsberechtigtePerson.addressValidation && bezugsberechtigtePerson.addressValidation.isCorrectedAddress) &&
                                    <div style={{
                                        textAlign: 'left',
                                        margin: 'auto',
                                        color: '#BF1528'
                                    }} data-component-id="personal-data-entitlement-address-corrected-message">
                                        Ihre Adresse wurde korrigiert.
                                    </div>
                                }
                                {(bezugsberechtigtePerson.addressValidation && bezugsberechtigtePerson.addressValidation.isInvalid) &&
                                <div style={{
                                    textAlign: 'left',
                                    margin: 'auto',
                                    color: '#BF1528'
                                }} data-component-id="personal-data-entitlement-address-invalid-message">
                                    {`Adresse ist ungültig. Bitte korrigieren Sie die ${this.getWrongAddressField(bezugsberechtigtePerson)}`}
                                    <br/>
                                    {'Online können nur Adressen innerhalb Deutschlands angegeben werden.'}
                                    <br/>
                                    {'Sie können die Adresse im Nachhinein ändern lassen.'}
                                </div>
                                }
                            </small>
                        </div>
                        <div className="esc_col esc_col-12 esc_col-m-5" style={{
                            borderTopWidth: '0px',
                            borderBottomWidth: '0px',
                            justifyContent: 'flex-end',
                            display: 'flex'
                        }}>

                            <Button variant="text-link"
                                    style={{fontSize: '13px', fontWeight: 'bold'}}
                                    loading={this.state.editLoading}
                                    data-component-id="view-entitled-person-edit"
                                    onClick={async () => {
                                        trackElementClickImmediate(TrackingElement.Button_BezugPersonBearbeiten);
                                        this.setState({editLoading: true});
                                        await this.props.editCallback();
                                        this.setState({editLoading: false});
                                    }}
                                    iconLeft={EditIcon}
                                    disabled={!this.props.isEditable}>bearbeiten</Button>
                            <Button variant="text-link"
                                    iconLeft={TrashbinIcon}
                                    style={{fontSize: '13px', marginLeft: '2em', fontWeight: 'bold'}}
                                    data-component-id="view-entitled-person-delete"
                                    loading={this.state.deleteLoading}
                                    onClick={async () => {
                                        trackElementClickImmediate(TrackingElement.Button_BezugPersonLoeschen);
                                        this.setState({deleteLoading: true});
                                        try {
                                            await this.props.deleteCallback();
                                            this.setState({
                                                deleteLoading: false
                                            });
                                        } catch (e) {
                                            this.setState({
                                                errorMessage: 'Fehler beim Löschen der Bezugsperson. Bitte wenden Sie sich an einen Kundenberater',
                                                deleteLoading: false
                                            });
                                        }
                                    }}>
                                löschen
                            </Button>
                        </div>

                        {this.state.errorMessage && <div className="esc_col esc_col-12" style={{color: '#8e0038', fontSize: '13px'}}>
                            <AlertIcon
                                // tslint:disable-next-line: no-magic-numbers
                                width={16} height={16}/> {this.state.errorMessage}
                        </div>}
                    </div>

                </div>
            </Card>
        );
    }

    private readonly getWrongAddressField = (person: EntitledPersonViewModel) => {
        if (person.addressValidation && person.addressValidation.isInvalid) {
            if (person.addressValidation.errorHousenumber) {
                return 'Hausnummer';
            }
            if (person.addressValidation.errorStreet) {
                return 'Strasse';
            }
            if (person.addressValidation.errorPostalCity) {
                return 'PLZ / Ort';
            } else {
                return '';
            }
        } else {
            return '';
        }
    };

}

export default ViewEntitlement;
