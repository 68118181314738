import { SHA256 } from 'crypto-js';
import { USER_REPLACEMENT_REGEX } from './tracking.const';

export const preparePhoneNumberForHashing = (phoneNumber: string) =>
  phoneNumber.replace(USER_REPLACEMENT_REGEX.phone, '');

export const hashData = (dataToHash: string) => {
  const preparedDataToHash = dataToHash
    .toLowerCase()
    .replace(USER_REPLACEMENT_REGEX.hash, '');

  return SHA256(preparedDataToHash).toString();
};
