import * as React from 'react';
import RequestOfferFeedbackPage, { RequestOfferFeedbackPageData } from '../../../pages/RequestOfferPages/RequestOfferFeedbackPage';
import { getOfferPageData } from '../../../services/api';
import { StateDefinition } from '../../StateMachine';
import { StateName } from '../../StateMachineTypes';

export const requestOfferFeedbackPage: StateDefinition<RequestOfferFeedbackPageData> = {
    name: StateName.REQUEST_OFFER_FEEDBACK_PAGE,
    validInboundStates: [
        StateName.REQUEST_OFFER_INSURED_PERSON_PAGE,
        StateName.REQUEST_OFFER_CHECKOUT_PAGE
    ],
    transitions: [],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'requestOfferFeedback');

        return {
            userInput: {
                vorname: response.vorname,
                nachname: response.nachname,
                anrede: response.anrede,
                messages: response.messages,
                shouldDownloadPdf: response.shouldDownloadPdf
            },
            valueRanges: response.valueRanges
        };
    },
    render: (inputData, handleAction) => {
        const userInput = inputData.userInput;
        return <RequestOfferFeedbackPage
            businessId={inputData.businessId}
            valueRanges={inputData.valueRanges}
            storeState={{
                anrede: userInput.anrede ? userInput.anrede : undefined,
                vorname: userInput.vorname ? userInput.vorname : '',
                nachname: userInput.nachname ? userInput.nachname : '',
                shouldDownloadPdf: inputData.userInput.shouldDownloadPdf,
                update: values => {return; }
            }}
            handleAction={handleAction}
        />;
    }
};
