import axios, { AxiosError } from 'axios';
import {
  AngebotResponse,
  CalculationMode,
  CreateRequest,
  CreateResponse,
  IbanServiceResponse,
  MaklerResponse,
  OfferType,
  OrderRequest,
  OrderResponse,
  Person,
  PolicyModel,
  ResponseCodes,
  UpdateRequest,
  ValidationResponse,
} from 'stg-common';
import { CONFIG_BACKEND_BASE_URL } from '../config';
import { handleMessages } from '../helpers/consoleLog';
import { getIsMakler } from '../helpers/modeConfig';
import { BankingDetailsPageData } from '../pages/BankingDetailsPage';
import { BirthdatePageData } from '../pages/BirthdatePage';
import { BurialPackagePageData } from '../pages/BurialPackagePage';
import { ConfidentialityReleasePageData } from '../pages/ConfidentialityReleasePage';
import { DivergingInsuredPersonPageData } from '../pages/DivergingInsuredPersonPage';
import { EntitledPersonsPageData } from '../pages/EntitledPersonsPage';
import { FeeDynamicPageData } from '../pages/FeeDynamicPage';
import { FeePageData } from '../pages/FeePage';
import { FlexOptionPageData } from '../pages/FlexOptionPage';
import { InsuranceBeginPageData } from '../pages/InsuranceBeginPage';
import { InsuranceFeePageData } from '../pages/InsuranceFeePage';
import { InsuranceSumPageData } from '../pages/InsuranceSumPage';
import { InsuredPersonPageData } from '../pages/InsuredPersonPage';
import { PaymentPeriodPageData } from '../pages/PaymentPeriodPage';
import { PolicyHolderPageData } from '../pages/PolicyHolderPage';
import { addTrackingData } from '../tracking/tracker';
import { TrackingData } from '../tracking/TrackingData';

const headers = {
  headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache' }
};

axios.interceptors.request.use(
  function(config) {
    const token = sessionStorage.getItem('stgJwt');

    if (token !== undefined) {
      config.headers.Authorization = token;
    }

    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

const bodyUpdateRequest = (updateParams: UpdateRequest): UpdateRequest => ({
  ...updateParams,
  isMakler: getIsMakler()
});

export const getFullOffer = async (
  businessId: string
): Promise<AngebotResponse> => {
  const endpoint = `${CONFIG_BACKEND_BASE_URL}/angebot/${businessId}`;

  try {
    const response = await axios.get(endpoint, headers);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOfferPageData = async (
  businessId: string,
  page: string
): Promise<AngebotResponse> => {
  const endpoint = `${CONFIG_BACKEND_BASE_URL}/angebot/${businessId}/${page}`;

  try {
    const response = await axios.get(endpoint, headers);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createAngebot = async (
  req: CreateRequest
): Promise<CreateResponse> => {
  const endpoint = `${CONFIG_BACKEND_BASE_URL}/angebot`;
  try {
    const response = await axios.post(endpoint, req, headers);
    sessionStorage.setItem('stgJwt', response.headers.authorization);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateBirthdatePageData = async (
  businessId: string,
  values: Partial<BirthdatePageData>
): Promise<AngebotResponse> => {
  const body: UpdateRequest = bodyUpdateRequest({
    birthdate: values.birthdate
  });

  return callEndpoint(businessId, body, 'birthdate');
};

export const updateInsuranceBeginPageData = async (
  businessId: string,
  values: Partial<InsuranceBeginPageData>
): Promise<AngebotResponse> => {
  const body: UpdateRequest = bodyUpdateRequest({
    insuranceBegin: values.insuranceBegin
  });

  return callEndpoint(businessId, body, 'insuranceBegin');
};

export const updateInsuranceSumPageData = async (
  businessId: string,
  values: Partial<InsuranceSumPageData>
): Promise<AngebotResponse> => {
  const body: UpdateRequest = bodyUpdateRequest({
    paymentMethod: values.paymentMethod,
    insuranceFee: values.insuranceFee,
    insuranceSum: values.insuranceSum,
    paymentPeriod: values.paymentPeriod
  });

  return callEndpoint(businessId, body, 'insuranceModel');
};

export const updateInsuranceFeePageData = async (
  businessId: string,
  values: Partial<InsuranceFeePageData>
): Promise<AngebotResponse> => {
  const body: UpdateRequest = bodyUpdateRequest({
    insuranceFee: values.insuranceFee,
    paymentMethod: values.paymentMethod
  });
  return callEndpoint(businessId, body, 'insuranceFee');
};

export const updateInsuranceFeeVariant = async (
  businessId: string,
  values: Partial<FeePageData>
): Promise<AngebotResponse> => {
  return updateFeePageData(businessId, values);
};

export const updateFeePageData = async (
  businessId: string,
  values: Partial<FeePageData>
): Promise<AngebotResponse> => {
  const body: UpdateRequest = bodyUpdateRequest({
    variante: values.variante,
    paymentMethod: values.paymentMethod
  });

  return callEndpoint(businessId, body, 'insuranceModel');
};

export const recalculateVariants = async (
  businessId: string,
  values: Partial<FeePageData>
): Promise<AngebotResponse> => updateInsuranceSumPageData(businessId, values);

export const updateFeePageDataSwitchPayment = async (
  businessId: string,
  values: Partial<FeePageData>
): Promise<AngebotResponse> => {
  const isCalculationModeInsuranceFee =
    values.calculationMode === CalculationMode.INSURANCE_FEE;
  const isCalculationModeInsuranceSum =
    values.calculationMode === CalculationMode.INSURANCE_SUM;
  const body: UpdateRequest = bodyUpdateRequest({
    paymentMethod: values.paymentMethod,
    paymentPeriod: values.paymentPeriod,
    ...(isCalculationModeInsuranceFee && { insuranceFee: values.insuranceFee }),
    ...(isCalculationModeInsuranceSum && { insuranceSum: values.insuranceSum })
  });

  return callEndpoint(businessId, body, 'insuranceModel');
};

export const updatePaymentPeriodPageData = async (
  businessId: string,
  values: Partial<PaymentPeriodPageData>
): Promise<AngebotResponse> => {
  const body: UpdateRequest = bodyUpdateRequest({
    paymentPeriod: values.paymentPeriod
  });

  return callEndpoint(businessId, body, 'paymentPeriod');
};

export const updateBurialPackagePageData = async (
  businessId: string,
  values: Partial<BurialPackagePageData>
): Promise<AngebotResponse> => {
  const body: UpdateRequest = bodyUpdateRequest({
    burialPackage: values.burialPackage
  });

  return callEndpoint(businessId, body, 'burialPackage');
};

export const updateFlexOptionPageData = async (
  businessId: string,
  values: Partial<FlexOptionPageData>
): Promise<AngebotResponse> => {
  const body: UpdateRequest = bodyUpdateRequest({
    flexOption: values.flexOption
  });

  return callEndpoint(businessId, body, 'flexOption');
};

export const updateFeeDynamicPageData = async (
  businessId: string,
  values: Partial<FeeDynamicPageData>
): Promise<AngebotResponse> => {
  const body: UpdateRequest = bodyUpdateRequest({
    feeDynamic: values.feeDynamic
  });

  return callEndpoint(businessId, body, 'feeDynamic');
};

export const updateDivergingInsuredPersonPageData = async (
  businessId: string,
  values: Partial<DivergingInsuredPersonPageData>
): Promise<AngebotResponse> => {
  const body: UpdateRequest = bodyUpdateRequest({
    divergingInsuredPerson: values.divergingInsuredPerson
  });

  return callEndpoint(businessId, body, 'divergingInsuredPerson');
};

export const updatePolicyHolderPageData = async (
  businessId: string,
  values: Partial<PolicyHolderPageData>
): Promise<AngebotResponse> => {
  const body = bodyUpdateRequest({
    politicallyExposedPerson: values.politicallyExposedPerson,
    custodian: values.custodian,
    accountThirdParty: values.accountThirdParty  });
  return callEndpoint(businessId, body, 'policyHolder');
};

export const updateInsuredPersonPageData = async (
  businessId: string,
  values: Partial<InsuredPersonPageData>
): Promise<AngebotResponse> => {
  const body = bodyUpdateRequest({
    politicallyExposedPerson: values.politicallyExposedPerson,
    custodian: values.custodian,
    accountThirdParty: values.accountThirdParty  });
  return callEndpoint(businessId, body, 'insuredPerson');
};

export const updateEntitledPersonsPageData = async (
  businessId: string,
  values: Partial<EntitledPersonsPageData>
): Promise<AngebotResponse> => {
  const body: UpdateRequest = bodyUpdateRequest({
    entitledPersons: values.entitledPersons
  });

  return callEndpoint(businessId, body, 'entitledPersons');
};

export const updateProcessModelToIModel = async (
  businessId: string
): Promise<AngebotResponse> => {
  const body: UpdateRequest = bodyUpdateRequest({
    policyModel: PolicyModel.INVITATIO
  });

  return callEndpoint(businessId, body, 'policyModel');
};

export const updateBankingDetailsPageData = async (
  businessId: string,
  values: Partial<BankingDetailsPageData>,
  oeNumber?: string,
  kooperationSales?: boolean
): Promise<AngebotResponse> => {
  const body: UpdateRequest = bodyUpdateRequest({
    bic: values.bic,
    iban: values.iban
  });

  if (oeNumber) {
    body.oeNumber = oeNumber;
  }

  if (kooperationSales) {
    body.kooperationSales = kooperationSales;
  }

  return callEndpoint(businessId, body, 'bankingDetails');
};

export const updateConfidentialityReleasePageData = async (
  businessId: string,
  values: Partial<ConfidentialityReleasePageData>
): Promise<AngebotResponse> => {
  const body: UpdateRequest = bodyUpdateRequest({
    confidentialityRelease: values.confidentialityRelease
  });
  return callEndpoint(businessId, body, 'confidentialityRelease');
};

export const makeOrder = async (businessId: string): Promise<OrderResponse> => {
  const serviceEndpoint = `${CONFIG_BACKEND_BASE_URL}/order/${businessId}`;
  try {
    const response = await axios.post(serviceEndpoint, {}, headers);
    return Promise.resolve({ status: response.status });
  } catch (err) {
    const error = err as AxiosError;
    if (
      error.response &&
      error.response.status &&
      error.response.status === ResponseCodes.PRECONDITION_FAILED
    ) {
      return { status: error.response.status, ...error.response.data };
    }
    return Promise.reject({ status: ResponseCodes.ERROR });
  }
};

export const checkOfferState = async (
  businessId: string
): Promise<AngebotResponse> => {
  const serviceEndpoint = `${CONFIG_BACKEND_BASE_URL}/angebot/${businessId}/offerState`;
  try {
    const response = await axios.get(serviceEndpoint, headers);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(ResponseCodes.ERROR);
  }
};

export const makeOffer = async (
  businessId: string,
  offerType: OfferType,
  oeNumber: string,
  kooperationSales?: boolean
): Promise<OrderResponse> => {
  const serviceEndpoint = `${CONFIG_BACKEND_BASE_URL}/order/${businessId}`;

  const reqBody: OrderRequest = { offerType, oeNumber };

  if (kooperationSales) {
    reqBody.kooperationSales = kooperationSales;
  }

  addTrackingData(reqBody as TrackingData);

  try {
    const response = await axios.post(serviceEndpoint, reqBody, headers);
    return Promise.resolve({ status: response.status });
  } catch (err) {
    const error = err as AxiosError;
    if (
      error.response &&
      error.response.status &&
      error.response.status === ResponseCodes.PRECONDITION_FAILED
    ) {
      return { status: error.response.status, ...error.response.data };
    }
    return Promise.reject({ status: error.response  ? error.response.status : undefined });
  }
};

export const validateIban = async (
  iban: string,
  bic?: string
): Promise<IbanServiceResponse> => {
  const endpoint = `${CONFIG_BACKEND_BASE_URL}/ibanService/${iban}/${bic}`;

  try {
    const response = await axios.get(endpoint, headers);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPerson = async (
  businessId: string,
  personId: string
): Promise<Person> => {
  const serviceEndpoint = `${CONFIG_BACKEND_BASE_URL}/person/${businessId}/${personId}`;

  try {
    const response = await axios.get(serviceEndpoint, headers);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatePerson = async (
  businessId: string,
  body: Person,
  personId: string
): Promise<ValidationResponse[]> => {
  const serviceEndpoint = `${CONFIG_BACKEND_BASE_URL}/person/${businessId}/${personId}`;

  try {
    const response = await axios.put(serviceEndpoint, body, headers);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const patchPerson = async (
  businessId: string,
  body: Partial<Person>,
  personId: string
): Promise<ValidationResponse[]> => {
  const serviceEndpoint = `${CONFIG_BACKEND_BASE_URL}/person/${businessId}/${personId}`;

  try {
    const response = await axios.patch(serviceEndpoint, body, headers);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const callEndpoint = async (
  businessId: string,
  body: UpdateRequest,
  endpoint: string
): Promise<AngebotResponse> => {
  const serviceEndpoint = `${CONFIG_BACKEND_BASE_URL}/angebot/${businessId}/${endpoint}`;

  addTrackingData(body as TrackingData);

  try {
    const response = await axios.put(serviceEndpoint, body, headers);
    if (response.data.messages) {
      handleMessages(response.data.messages);
    }
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMakler = async (
  businessId: string,
  token: string
): Promise<MaklerResponse> => {
  const endpoint = `${CONFIG_BACKEND_BASE_URL}/makler/${businessId}`;

  const reqBody = { token };
  console.log(' getMakler: ', reqBody);
  try {
    const response = await axios.post(endpoint, reqBody, headers);
    console.log(' getMakler response: ', response);
    return Promise.resolve(response.data);
  } catch (error) {
    return  Promise.resolve({});
  }
};

export const isSuccessfulResponseCode = (statusCode: number): boolean =>
  statusCode === ResponseCodes.SUCCESS || statusCode === ResponseCodes.VALIDATION_FAILURE;
