import { TextValue, ValueRanges, Variante } from 'stg-common';

export const extractSelectedVariant = (variants: Variante[]): Variante | undefined => {
    for (const variante of variants) {
        if (variante.selected) {
            return variante;
        }
    }
    return undefined;
};

export const  mapVariant = (variant: Variante, valueRanges: ValueRanges) => {
    const variantText = valueRanges.tariffType.possibleValues.find(
        (element: TextValue) => element.key.toLocaleLowerCase() === variant.variante);
    if (variantText) {
        return variantText.text;
    } else {
        return 'n./a.';
    }
};
