import * as React from 'react';
import { CalculationMode } from 'stg-common';
import { PAYMENT_METHOD_SINGLE_FEE } from '../../constants';
import FeePage, { FeePageData } from '../../pages/FeePage';
import {
    getOfferPageData,
    updateDivergingInsuredPersonPageData,
    updateFeePageData,
    updateFeePageDataSwitchPayment,
    updateInsuranceFeePageData,
    updateInsuranceSumPageData
} from '../../services/api';
import { addTrackingData } from '../../tracking/tracker';
import { StateDefinition } from '../StateMachine';
import { CHECKOUT_PAGES, NavigationAction, PERSONAL_INFORMATION_PAGES, REQUEST_OFFER_PAGES, StateName } from '../StateMachineTypes';

export const feePage: StateDefinition<FeePageData> = {
    name: StateName.FEE_PAGE,
    disableOnEnterTracking: true,
    validInboundStates: [
        ...PERSONAL_INFORMATION_PAGES,
        ...CHECKOUT_PAGES,
        ...REQUEST_OFFER_PAGES,
        StateName.PAYMENT_PERIOD_PAGE,
        StateName.BURIAL_PACKAGE_PAGE,
        StateName.FEE_DYNAMIC_PAGE,
        StateName.FLEX_OPTION_PAGE,
        StateName.INSURANCE_FEE_PAGE,
        StateName.INSURANCE_SUM_PAGE,
        StateName.FEE_PAGE,
        StateName.INSURED_PERSON_PAGE
    ],
    transitions: [
        {
            test: (action, data) => {
                return (action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK)
                    && data.userInput.paymentMethod === PAYMENT_METHOD_SINGLE_FEE && data.userInput.calculationMode === CalculationMode.INSURANCE_FEE;
            },
            newState: StateName.INSURANCE_FEE_PAGE
        },
        {
            test: (action, data) => {
                return (action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK)
                    && data.userInput.calculationMode === CalculationMode.INSURANCE_SUM;
            },
            newState: StateName.INSURANCE_SUM_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.NEXT && data.userInput.paymentMethod !== PAYMENT_METHOD_SINGLE_FEE,
            newState: StateName.FEE_DYNAMIC_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.NEXT && data.userInput.paymentMethod === PAYMENT_METHOD_SINGLE_FEE,
            newState: StateName.INSURED_PERSON_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.SWITCH_PAYMENT_PERIOD || action === NavigationAction.SWITCH_CALCULATION_MODE,
            newState: StateName.FEE_PAGE
        },
        // BreadCrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'insuranceModel');
        return {
            userInput: {
                birthdate: response.birthdate,
                insuranceBegin: response.insuranceBegin,
                insuranceSum: response.insuranceSum,
                paymentPeriod: response.paymentPeriod,
                paymentMethod: response.paymentMethod,
                calculationMode: response.calculationMode,
                variants: response.varianten,
                messages: response.messages,
                insuranceFee: response.insuranceFee,
                showCalculationDialog: transitionDetails.payload ? transitionDetails.payload.showCalculationDialog : false,
                mustRecalculate: transitionDetails.payload ? transitionDetails.payload.mustRecalculate : false
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        if (transitionDetails.action === NavigationAction.SWITCH_PAYMENT_PERIOD) {
            await updateFeePageDataSwitchPayment(inputData.businessId, {
                insuranceSum: inputData.userInput.insuranceSum,
                insuranceFee: inputData.userInput.insuranceFee,
                paymentMethod: inputData.userInput.paymentMethod,
                calculationMode: inputData.userInput.calculationMode,
                paymentPeriod: inputData.userInput.paymentPeriod
            });
        } else if (transitionDetails.action === NavigationAction.SWITCH_CALCULATION_MODE
            && inputData.userInput.calculationMode === CalculationMode.INSURANCE_SUM) {
            await updateInsuranceSumPageData(inputData.businessId, {
                paymentMethod: inputData.userInput.paymentMethod,
                insuranceSum: inputData.userInput.insuranceSum
            });
        } else if (transitionDetails.action === NavigationAction.SWITCH_CALCULATION_MODE
            && inputData.userInput.calculationMode === CalculationMode.INSURANCE_FEE) {
            await updateInsuranceFeePageData(inputData.businessId, {
                paymentMethod: inputData.userInput.paymentMethod,
                insuranceFee: inputData.userInput.insuranceFee
            });
        } else {
            const  response = await updateFeePageData(inputData.businessId, {
                paymentMethod: inputData.userInput.paymentMethod,
                variante: inputData.userInput.variante
            });
            addTrackingData({selectedVariant: response.varianten.find(v => v.selected)});
            if (transitionDetails.action === NavigationAction.NEXT && inputData.userInput.paymentMethod === PAYMENT_METHOD_SINGLE_FEE) {
                return {payload: await updateDivergingInsuredPersonPageData(inputData.businessId, {
                    divergingInsuredPerson: false
                })};
            }
        }
        return {
            payload: {
                showCalculationDialog: inputData.userInput.showCalculationDialog,
                mustRecalculate: inputData.userInput.showCalculationDialog
                    && (transitionDetails.action === NavigationAction.SWITCH_PAYMENT_PERIOD
                        || transitionDetails.action === NavigationAction.SWITCH_CALCULATION_MODE)
            }
        };
    },
    render: (inputData, handleAction, updateApp) => {
        const userInput = inputData.userInput;
        return <FeePage
            storeState={{
                businessId: inputData.businessId,
                birthdate: userInput.birthdate,
                insuranceBegin: userInput.insuranceBegin,
                insuranceSum: userInput.insuranceSum,
                paymentPeriod: userInput.paymentPeriod,
                paymentMethod: userInput.paymentMethod,
                calculationMode: userInput.calculationMode,
                showCalculationDialog: userInput.showCalculationDialog,
                mustRecalculate: userInput.mustRecalculate,
                variants: userInput.variants,
                messages: userInput.messages,
                insuranceFee: userInput.insuranceFee,
                update: (values, callback) => updateApp(values, callback)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};
