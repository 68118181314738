import { DatalayerTransaction, DatalayerTransactionAttributes } from '@eg/tracking/datalayer.models';
import { OfferType } from 'stg-common';
import { SESSION_STORAGE_BUSINESS_ID_KEY, Storage } from '../helpers/Storage';
import { StateName } from '../routing/StateMachineTypes';
import { TrackingData } from './TrackingData';

export const mapTransactionData = (trackingData: TrackingData, currentPageStateName?: StateName) => {
    const result = {
        transaction: {
            transactionID: Storage.readItem(SESSION_STORAGE_BUSINESS_ID_KEY)
        } as DatalayerTransaction
    };

    const attributes = mapAttributes(trackingData, currentPageStateName);
    const total = mapTotal(trackingData, currentPageStateName);

    if (attributes) {
        result.transaction.attributes = attributes;
    }

    if (total && (StateName.FEEDBACK_PAGE === currentPageStateName || StateName.REQUEST_OFFER_FEEDBACK_PAGE === currentPageStateName)) {
        result.transaction.total = total;
    }

    return result;
};

const mapTotal = (trackingData: TrackingData, statename?: StateName) => {
    const paymentPerYear = calculatePaymentPerYear(trackingData);

    if (paymentPerYear) {
        return {transactionTotal: paymentPerYear};
    }

    return undefined;
};

const mapAttributes = (trackingData: TrackingData, statename?: StateName) => {
    const attributes = {} as DatalayerTransactionAttributes;
    const conversionType = getConversionType(trackingData, statename);
    const paymentPerYear = calculatePaymentPerYear(trackingData);

    if (!conversionType && !paymentPerYear) {
        return undefined;
    }

    if (conversionType) {
        attributes.conversionType = conversionType;
    }

    if (paymentPerYear) {
        attributes.calculatedValue = paymentPerYear;
    }

    return attributes;
};

const calculatePaymentPerYear = (trackingData: TrackingData): string | undefined => {
    if (!trackingData || !trackingData.paymentMethod || !trackingData.selectedVariant) {
        return undefined;
    }

    const nettobeitrag = trackingData.flexOption ?
        trackingData.selectedVariant.nettobeitragMitFlexOption : trackingData.selectedVariant.nettobeitragNachZahlweise;
    if (!nettobeitrag) {
        return undefined;
    }

    // tslint:disable:no-magic-numbers
    switch (trackingData.paymentMethod) {
        case 'MONATLICH':
            return (nettobeitrag * 12).toFixed(2);
        case 'VIERTELJAEHRLICH':
            return (nettobeitrag * 4).toFixed(2);
        case 'HALBJAEHRLICH':
            return (nettobeitrag * 2).toFixed(2);
        case 'JAEHRLICH':
            return (nettobeitrag).toFixed(2);
        default:
            return undefined;
    }
    // tslint:enable:no-magic-numbers
};

const getConversionType = (trackingData: TrackingData, statename?: StateName) => {
    switch (statename) {
        case StateName.BIRTHDATE_PAGE:
            return 'SaleFunnelStart';
        case StateName.FEE_PAGE:
            return 'SaleFunnelCalculation';
        case StateName.DIVERGING_INSURED_PERSON_PAGE:
            return 'SaleFunnelPersonalData';
        case StateName.BANKING_DETAILS_PAGE:
            return 'SaleFunnelBankData';
        case StateName.FEEDBACK_PAGE:
            return 'Sale';
        case StateName.INSURANCE_SUMMARY_PAGE:
            return 'SaleFunnelFinalCheck';
        case StateName.REQUEST_OFFER_INSURED_PERSON_PAGE:
            if (!trackingData.divergingInsuredPerson) {
                return 'OfferFunnelFinalCheck';
            }
            return undefined;
        case StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE:
        case StateName.REQUEST_OFFER_CHECKOUT_PAGE:
            return 'OfferFunnelFinalCheck';
        case StateName.REQUEST_OFFER_FEEDBACK_PAGE:
            return getConversionTypeOffer(trackingData);
        default:
            return undefined;
    }
};

const getConversionTypeOffer = (trackingData: TrackingData) => {
    switch (trackingData.offerType) {
        case OfferType.DIRECT_ONLINE:
            return 'OfferPdfDownload';
        case OfferType.WRITTEN_POSTAL:
            return 'OfferPost';
        case OfferType.WRITTEN_EMAIL:
            return 'OfferEmail';
        default:
            return undefined;
    }
};
