import Button from '@eg/elements/Button';
import React, { FC } from 'react';
import { trackElementClicked } from '../../tracking/tracker';
import { TrackingElement } from '../../tracking/trackingConstants';

interface NextButtonProps {
    disableNextButton?: boolean;
    nextButtonText?: string;
    showLoadingSpinner?: boolean;
    onNextClick?: () => void;
}

export const NextButton: FC<NextButtonProps> = ({
    disableNextButton,
    nextButtonText,
    showLoadingSpinner,
    onNextClick
}) => {
    const onClick = () => {
        trackElementClicked(TrackingElement.Button_Weiter);
        if (onNextClick) {
            onNextClick();
        }
    };

    return (
        <Button
            data-component-id={'next-button'}
            disabled={disableNextButton}
            loading={showLoadingSpinner}
            onClick={onClick}
            size="large"
            style={{display: 'inline-flex', margin: '1.25em auto 0 auto'}}
            type="submit"
            variant="primary"
        >
            {nextButtonText || 'Weiter'}
        </Button>
    );
};
