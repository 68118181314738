export enum DataIdBirthdatePage {
    INPUT_BIRTHDATE = 'DataIdBirthdatePage-Input-Birthdate'
}

export enum DataIdPaymentPeriodPage {
    SLIDER_PAYMENTPERIOD = 'DataIdPaymentPeriodPage-Slider-PaymentPeriod',
    INPUT_PAYMENTPERIOD = 'DataIdPaymentPeriodPage-InputField-PaymentPeriod'
}

export enum DataIdInsuredPersonPage {
    INPUT_BIRTHDATE = 'DataIdInsuredPersonPage-Input-Birthdate',
    INPUT_SALUTATION_MALE = 'DataIdInsuredPersonPage-Input-Salutation-Male',
    INPUT_SALUTATION_FEMALE = 'DataIdInsuredPersonPage-Input-Salutation-Female',
    INPUT_FIRSTNAME = 'DataIdInsuredPersonPage-Input-Firstname',
    INPUT_SURNAME = 'DataIdInsuredPersonPage-Input-Surname',
    INPUT_BIRTHPLACE = 'DataIdInsuredPersonPage-Input-Birthplace',
    INPUT_NATIONALITY = 'DataIdInsuredPersonPage-Input-Nationality',
    INPUT_STREET = 'DataIdInsuredPersonPage-Input-Street',
    INPUT_HOUSENUMBER = 'DataIdInsuredPersonPage-Input-Housenumber',
    INPUT_CITY = 'DataIdInsuredPersonPage-Input-City',
    INPUT_POSTALCODE = 'DataIdInsuredPersonPage-Input-Postalcode',
    INPUT_EMAIL = 'DataIdInsuredPersonPage-Input-Email',
    INPUT_VKLM = 'DataIdInsuredPersonPage-Input-VKLM',
    INPUT_PREFIX = 'DataIdInsuredPersonPage-Input-Prefix',
    INPUT_PHONENUMBER = 'DataIdInsuredPersonPage-Input-Phonenumber'
}
