import * as React from 'react';
import { OfferType, OrderResponse, ResponseCodes } from 'stg-common';
import { isKooperationSales } from '../../../helpers/kooperationSales';
import { getOeNumber } from '../../../helpers/modeConfig';
import RequestOfferCheckoutPage, { RequestOfferCheckoutPageData } from '../../../pages/RequestOfferPages/RequestOfferCheckoutPage';
import { getOfferPageData, makeOffer } from '../../../services/api';
import { trackError } from '../../../tracking/tracker';
import { TrackingElement, TrackingErrorType } from '../../../tracking/trackingConstants';
import { createOfferNavigation } from '../../routeHelper';
import { StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

const validInboundStates = [
    StateName.INSURED_PERSON_PAGE,
    StateName.ENTITLED_PERSONS_PAGE,
    StateName.BANKING_DETAILS_PAGE,
    StateName.INSURANCE_SUMMARY_PAGE,
    StateName.INSURANCE_DOCUMENTS_PAGE
];

export const requestOfferCheckoutPage: StateDefinition<RequestOfferCheckoutPageData> = {
    name: StateName.REQUEST_OFFER_CHECKOUT_PAGE,
    validInboundStates,
    transitions: [
        // Navigation
        {
            test: (action, data) => action === NavigationAction.OFFER_DIRECT_ONLINE,
            newState: StateName.REQUEST_OFFER_FEEDBACK_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.OFFER_WRITTEN_POSTAL,
            newState: StateName.REQUEST_OFFER_FEEDBACK_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.OFFER_WRITTEN_EMAIL,
            newState: StateName.REQUEST_OFFER_FEEDBACK_PAGE
        },
        {
            test: (action, data) => (action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK),
            resolveNewState: createOfferNavigation(StateName.INSURED_PERSON_PAGE, validInboundStates),
            newState: StateName.INSURED_PERSON_PAGE
        },
        // Breadcrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'policyHolder');

        return {
            userInput: {
                policyHolderId: response.policyHolderId,
                showMeldung: false
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        if (transitionDetails.targetStateName === StateName.REQUEST_OFFER_FEEDBACK_PAGE) {
            let offerType;
            let trackingElement;
            switch (transitionDetails.action) {
                case NavigationAction.OFFER_WRITTEN_POSTAL:
                    offerType = OfferType.WRITTEN_POSTAL;
                    trackingElement = TrackingElement.Button_AngebotPerPost;
                    break;
                case NavigationAction.OFFER_WRITTEN_EMAIL:
                    offerType = OfferType.WRITTEN_EMAIL;
                    trackingElement = TrackingElement.Button_AngebotPerEmail;
                    break;
                default:
                    offerType = OfferType.DIRECT_ONLINE;
                    trackingElement = TrackingElement.Button_AngebotPDFHerunterladen;
                    break;
            }
            const response: OrderResponse =
                await makeOffer(inputData.businessId, offerType, getOeNumber(), isKooperationSales());

            if (response.status === ResponseCodes.PRECONDITION_FAILED && response.showMeldung) {
                    trackError(trackingElement, TrackingErrorType.Sonstige);
                    return {skipTransition: true, payload: {showMeldung: response.showMeldung}};
            }
            if (response.status !== ResponseCodes.SUCCESS) {
                throw new Error('Internal server error');
            }
        }
        return {};
    },
    render: (inputData, handleAction, updateApp, onError) => {
        return <RequestOfferCheckoutPage
            valueRanges={inputData.valueRanges}
            businessId={inputData.businessId}
            storeState={{
                policyHolderId: inputData.userInput.policyHolderId,
                showMeldung: inputData.userInput.showMeldung,
                update: values => updateApp(values)
            }}
            handleAction={handleAction}
            onError={onError}
        />;
    }
};
