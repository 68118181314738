import * as React from 'react';
import { ConfidentialityReleasePage, ConfidentialityReleasePageData } from '../../pages/ConfidentialityReleasePage';
import { getOfferPageData, updateConfidentialityReleasePageData } from '../../services/api';
import { StateDefinition } from '../StateMachine';
import { NavigationAction, StateName } from '../StateMachineTypes';

export const confidentialityReleasePage: StateDefinition<ConfidentialityReleasePageData> = {
    name: StateName.CONFIDENTIALITY_RELEASE_PAGE,
    validInboundStates: [StateName.INSURANCE_SUMMARY_PAGE, StateName.INSURANCE_DOCUMENTS_PAGE],
    transitions: [
        {
            test: (action, data) => action === NavigationAction.NEXT,
            newState: StateName.INSURANCE_DOCUMENTS_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BACK,
            newState: StateName.INSURANCE_SUMMARY_PAGE
        },
        // BreadCrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_PERSONAL_INFORMATION,
            newState: StateName.INSURED_PERSON_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'confidentialityRelease');

        return {
            valueRanges: response.valueRanges,
            userInput: {
                confidentialityRelease: response.confidentialityRelease,
                messages: response.messages
            }
        };
    },
    onExit: async (transitionDetails, inputData) => {
        return {
            payload: await updateConfidentialityReleasePageData(inputData.businessId, {
                confidentialityRelease: transitionDetails.action === NavigationAction.NEXT
            })
        };
    },
    render: (inputData, handleAction, updateApp) => {
        return <ConfidentialityReleasePage
            storeState={{
                confidentialityRelease: inputData.userInput.confidentialityRelease,
                update: values => updateApp(values)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};
