import * as React from 'react';
import './ErrorMessage.css';

export interface ErrorMessageProps {
  messages: string[];
  styles?: string;
}

const ErrorMessage = ({ styles = '', messages }: ErrorMessageProps) => <div className="errorDiv">
  <p className={`error_message ${styles}`}>
    <ul>
      {messages.map((element: string) => (
        <li key={element}>{element}</li>
      ))}
    </ul>
  </p>
</div>;

export default ErrorMessage;
